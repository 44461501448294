import { Strings } from "../../resources/i18n/i18n";

export const NOTES_COLUMNS = [
    { key: "ca", name: Strings("Date"), maxWidth: 200, minWidth: 200, },
    { key: "note", name: Strings("Notes"), maxWidth: 500, minWidth: 500 },
    { key: "noteType", name: Strings("noteType"), maxWidth: 200, minWidth: 200 },
    { key: "author", name: Strings("author"), maxWidth: 200, minWidth: 200 },
    { key: "role", name: Strings("UserRole"), maxWidth: 200, minWidth: 200 },
];

export const NOTES_REPORTS_DOWNLAOD = {
    downloadNotesUrl: "/notes/download?fileType=",
};