import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// String
import { Strings } from "../../resources/i18n/i18n";

import {
  LOAN_AMOUNT_COLUMNS,
  LOAN_AMOUNT_PAY_COLUMNS,
  CEDULAHISTORY_COLUMNS,
} from "./Constants";
import { INVOICE_DATE_FORMAT } from "../../constants/appConstants";
import Utility from "../../utils/Utility";
import "./LoanDetails.scss";

class LoanAmountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onModalClose() {
    /* This is intentional */
  }
  getStyleTableCell = () => {
    return styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d5d5d5",
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
  };
  //render
  renderTableData = () => {
    //NOSONAR
    const StyledTableCell = this.getStyleTableCell();

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    let tableHeaderList = !this.props.isLoanDetails
      ? CEDULAHISTORY_COLUMNS
      : this.props.modalTitle === "amountToPay" //NOSONAR
      ? LOAN_AMOUNT_PAY_COLUMNS
      : LOAN_AMOUNT_COLUMNS;

    return this.props.loanAmountDivisionData.map(
      (loanAmount, index) => {
        return (
          <StyledTableRow key={Math.random() + index}>
            {tableHeaderList.map((row, rowIndex) => (
              <StyledTableCell
                key={rowIndex + row.key}
                className={`text-center ${
                  row.key === "fp" || row.key === "bp"
                    ? "cursorPointer textColor"
                    : ""
                }`}
                onClick={
                  row.key === "fp" || row.key === "bp"
                    ? () => {
                        this.props.downloadPhoto(loanAmount[row.key]);
                      }
                    : () => {
                        //NOSONAR
                      }
                }
              >
                {row.key === "dt" || row.key === "aon"
                  ? moment
                      .utc(loanAmount[row.key])
                      .format(INVOICE_DATE_FORMAT)
                  : row.key === "c" || //NOSONAR
                    row.key === "i" ||
                    row.key === "ttlf"
                  ? Utility.getCurrencyRepresentationOfAmount(
                      loanAmount[row.key]
                    )
                  : row.key === "lf" ? Utility.getCurrencyRepresentationOfAmount(loanAmount[row.key] || 0)
                  : row.key === "fp" //NOSONAR
                  ? Strings("downloadFrontPhoto")
                  : row.key === "bp" //NOSONAR
                  ? Strings("downloadBackPhoto")
                  : Strings(
                      loanAmount[row.key] ? loanAmount[row.key] : "NA" //NOSONAR
                    )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        );
      }
    );
  };

  render() {
    //NOSONAR
    const StyledTableCell = this.getStyleTableCell();

    let tableHeaderList = !this.props.isLoanDetails
      ? CEDULAHISTORY_COLUMNS
      : this.props.modalTitle === "amountToPay" //NOSONAR
      ? LOAN_AMOUNT_PAY_COLUMNS
      : LOAN_AMOUNT_COLUMNS;
    let modalClass = `LoanAmountModal ${
      this.props.isLoanDetails ? "customModal" : "customCedulaModal"
    } container-fluid`;
    return (
      <div className='LoanAmountModal container-fluid'>
        <Modal
          open={this.props.open}
          classNames={{
            modal: modalClass,
          }}
          onClose={() => {
            /* This is intentional */
          }}
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          center
        >
          <Row>
            <Col md={12} className='headerModal'>
              <h2>{Strings(this.props.modalTitle)}</h2>
            </Col>
            <Col md={12} className='modalContent pt-3'>
              {this.props.loanAmountDivisionData &&
              this.props.loanAmountDivisionData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className='d-flex justify-content-center'
                  sx={{ maxHeight: 440 }}
                >
                  <Table aria-label='customized table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        {tableHeaderList.length > 0 &&
                          tableHeaderList.map((column) => (
                            <StyledTableCell
                              key={column.key}
                              className={"text-center header px-0"}
                              title={Strings(
                                this.props.isLoanDetails
                                  ? column.key
                                  : column.name
                              )}
                            >
                              <div>
                                {Strings(
                                  this.props.isLoanDetails
                                    ? column.key
                                    : column.name
                                )}
                              </div>
                            </StyledTableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.renderTableData()}
                      {this.props.isLoanDetails && (
                        <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell
                            colSpan={3}
                            align='right'
                            className='boldText'
                          >
                            {Strings("total")}
                          </TableCell>
                          <TableCell colSpan={1} align='center'>
                            {Utility.getCurrencyRepresentationOfAmount(
                              this.props.loanAmountTotalSum
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className='noRecordsFound'>
                  {Strings("NoRecordsFound")}
                </div>
              )}
              <hr className="opacity-100"/>
            </Col>
            <Col md={12}>
              <div className='FileModalButton pull-right pb-2'>
                <Button
                  className={
                    "primaryModalButton buttonMinWidth marginCancelButton buttonBorder pull-right" +
                    this.props.modalButtonCancelStyle
                  }
                  onClick={this.props.onModalClose}
                >
                  {Strings("Close")}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default LoanAmountModal;
