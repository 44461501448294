import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";
import moment from "moment";

// Component
import LoanDetails from "./LoanDetails";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import LoanDetailModal from "./LoanDetailModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import UserModal from "../CustomUIComponents/UserModal/UserModal";

// Action
import {
  getLoanDetailsByIdAction,
  getAmortizationsTableAction,
  requestForTakeOffLateFeesAction,
  getNegotiationRequestAction,
  approveOrRejectRequestForTakeOffLateFeesAction,
  requestForAssignRepaymentAction,
  approveOrRejectRequestForAssignRepaymentAction,
  viewPreviousRequestAction,
  sendUpdatedMinimartDetailsAction,
  closeLoanApplicationAction,
  setLoanDetailsIDAction,
  addEMINoteAction,
  getLoanAmountPaidDivisionAction,
  loanCategoriesDetailsAction,
  changeCollectionDaysAction,
  getAllowedFrequencyAction,
} from "./LoanDetailsAction";

import {
  getNoteAction,
  updateNoteAction,
  deleteNoteAction,
  downloadDownpaymentReceiptAction,
  getPossibleCollectionCycleAction,
  AddNoteAction,
} from "../AnalystApproval/AnalystApprovalAction";

import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";
import { getMinimartBusinessTypes } from "../SuperDashBoard/SuperDashBoardAction";
import {
  generatePaymentReceiptAction,
  setMinimartDetailsActiveTab,
} from "../MinimartDetails/MiniMartDetailsAction";

// Constant
import { Strings } from "../../resources/i18n/i18n";
import {
  REQUEST_STATUS,
  LOAN_APPLICATION_STATUS,
  USER_ROLES,
  NOTES_TYPE,
  MODAL_TYPE,
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_PAGES,
  MINIMART_LIST_FILTER,
  APPROVAL_DASHBOARD_FILTER,
  SPED_L1_FILTER,
  SPED_L2_FILTER,
  LOAN_PORTFOLIO_FILTER,
  FLR_SEARCH_FILTER,
  SLR_SEARCH_FILTER,
  DAILY_STATUS_FILTER,
  PERSON_DAILY_STATUS_FILTER,
  LOAN_DETAIL_FILTER,
  WORKLOAD_MANAGEMENT_FILTER,
  ANALYST_LOAN_DETAIL_FILTER,
  USERS_WITH_ACCESS_ON_LOAN_APPLICATION_REQUEST,
  USERS_WITH_ACCESS_NEW_PAYMENTPLAN,
  NOTE_TYPE_CONTEXT,
} from "../../constants/appConstants";
import {
  LOAN_VIEW_PREVIOUS_REQ_CONST,
  FREQUENCIES,
  LOAN_DETAILS_INFO_ACCESS,
} from "./Constants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { OPERATION_STATUS, API_ERROR_CODE } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

//UTILS
import Utility from "../../utils/Utility";
// Style
import "./LoanDetails.scss";
import { getNotesAction } from "../NoteDetails/NoteDetailsAction";

class LoanDetailsContainer extends React.Component {
  /* #region  LifeCycle Methods */
  constructor(props) {
    super(props);
    this.debounceRequestForAssignRepaymentAction = Utility.debounce(this.callRequestForAssignRepaymentAction, 90000);
    this.state = {
      applicationId: props.selectedLoanDetails.applicationId ? props.selectedLoanDetails.applicationId : props.selectedLoanDetails,
      loanId: props.selectedLoanDetails.loanId,
      ticketId: props.selectedLoanDetails.ticketId,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      numberOfPages: DEFAULT_PAGINATION_ACTIVE_PAGE,
      loanDetails: null,
      requestData: null,
      amortizations: [],
      showLoadingModal: false,
      showTakeOffModal: false,
      showTakeOffRequestModal: false,
      readOnly: false,
      isSuccess: false,
      isError: false,
      successMessage: "",
      errorMessage: "",
      note: "",
      requestNote: "",
      amount: 0,

      lateFeesAmt: 0,
      freqRequested: "",
      termRequested: 0,
      emiAmount: 0,
      interestRate: 0.0,
      lastratePercentage: 0,
      refId: 0,

      isAssignRepayment: false,
      repaymentData: [],
      editedRepaymentData: {},
      npa: false,
      tla: false,
      loanType: props.selectedLoanDetails.loanType,
      ReasonsactivePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      ReasonsrecordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      ReasonsnumberOfPages: DEFAULT_PAGINATION_ACTIVE_PAGE,
      denialReasons: [],

      disableCloseLoanButton:
        props.selectedLoanDetails.status ===
        LOAN_APPLICATION_STATUS.rejected ||
        props.selectedLoanDetails.status ===
        LOAN_APPLICATION_STATUS.dismissed,

      showConfirmModal: false,
      emi: 0,
      btd: 0,
      isNewAssignRepayment: false,
      notesModal: false,
      businessTypeList: [
        {
          key: 0,
          value: "",
          label: Strings("BusinessType"),
        },
      ],
      totalAmount: 0,
      amortizationSummary: [],
      showDaysPastDue:false,
      isCloseLoan: false,
      lateFeeTicketId: null,
      level: "",
      isExceptional: false,
      loanApprovedAmount: 0,
      frequencyList: [
        {
          label: FREQUENCIES.WEEKLY_THRICE,
          value: FREQUENCIES.WEEKLY_THRICE,
        },
      ],
      possibleCollectionDays: [],
      showAmortizationNoteModal:false,
      amortization: {
        amortizationIndex: "",
        amortizationId: ""
      },
      amortizationNotes: [],
      isAddNoteAccess: true,
    };
  }

  componentDidMount() {
    this.getLoanDetailsById(this.state.applicationId);
    this.getMinimartBusinessTypes();
  }

  componentWillUnmount() {
    //NOSONAR
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.MINIMART_LIST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_LIST,
        {
          ...MINIMART_LIST_FILTER,
        }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
        {
          ...APPROVAL_DASHBOARD_FILTER,
        }
      );
    }

    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.SPED_L2 &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L1, {
        ...SPED_L1_FILTER,
      });
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L2, {
        ...SPED_L2_FILTER,
      });
    }

    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_PORTFOLIO &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_PORTFOLIO,
        { ...LOAN_PORTFOLIO_FILTER }
      );
    }

    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT,
        { ...FLR_SEARCH_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT,
        { ...SLR_SEARCH_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.DAILY_STATUS,
        { ...DAILY_STATUS_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS,
        { ...PERSON_DAILY_STATUS_FILTER }
      );
    }

    // loan approval
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_DETAIL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setMinimartDetailsActiveTab(null);
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_DETAIL,
        { ...LOAN_DETAIL_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT,
        { ...WORKLOAD_MANAGEMENT_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL,
        { ...ANALYST_LOAN_DETAIL_FILTER }
      );
    }
  }
  /* #endregion */

  /* #region  Loan Details */

  getLoanDetailsById = (applicationId, loanId = null) => {
    this.setState({
      showLoadingModal: true,
    });
    const requestData = {
      applicationId: applicationId,
      loanId: loanId,
      callback: (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState(
            {
              errorMessage: "",
              loanDetails: Response.data.data,
              lateFeesAmt: Response.data.data.state.lfAmt,
              termRequested: Response.data.data.info.noi,
              freqRequested: Response.data.data.info.appfq,
              emiAmount: Response.data.data.info.emi,
              interestRate: Response.data.data.state.upi,
              loanId: Response.data.data.info.id,
              lastratePercentage: Response.data.data.info.rt,
              npa: Response.data.data.npa,
              tla: Response.data.data.tla,
              lateFeeTicketId: Response.data.data.lftid,
              requestData: null,
              showLoadingModal: false,
              readOnly: false,
              level: Response.data.data.minimart.lvl,
              isExceptional: Response.data.data.minimart.iex,
              loanApprovedAmount: Response.data.data.info.appAmt,
              collectionDays: Response.data.data.info.appCd,
              loanType: Response.data.data.info.ty,
            },
            () => {

              if (this.state.loanId) {
                this.getAmortizationsTable(this.state.loanId);
                this.state.requestData === null &&
                  this.getAllowedFrequencies();
                if (this.state.loanDetails.info.appfq !== FREQUENCIES.WEEKLY_SIX) {
                  (LOAN_DETAILS_INFO_ACCESS.includes(this.props.logInUserInfo.role)
                    || USERS_WITH_ACCESS_ON_LOAN_APPLICATION_REQUEST.includes(this.props.logInUserInfo.role)
                    || USERS_WITH_ACCESS_NEW_PAYMENTPLAN.includes(this.props.logInUserInfo.role))
                    && this.getPossibleCollectionCycles({
                      fr: this.state.loanDetails.info.appfq,
                    }, () => { });
                }
              }
            }
          );
        } else {
          this.setState({
            showLoadingModal: false,
            errorMessage: Response.error.message,
          });
        }
      },
    };
    getLoanDetailsByIdAction(requestData);
  };

  getAmortizationsTable = (ammortizationsLoanId = null) => {
    this.setState({ showLoadingModal: true });
    const loanId = ammortizationsLoanId
      ? ammortizationsLoanId
      : this.state.loanId;
    loanId &&
      loanId !== null &&
      getAmortizationsTableAction(
        {
          loanId: loanId,
          inl: this.state.loanDetails.info.inl,
          offset: this.state.activePage - 1,
          limit: this.state.recordsPerPage,
        },
        (Response) => {
          if (Response.status === OPERATION_STATUS.SUCCESS) {
            let amortizationSummary = [];
            amortizationSummary.push(Response.data.data.summary);
            this.setState({
              errorMessage: "",
              amortizations: Response.data.data.amortizations,
              amortizationSummary: amortizationSummary,
              showDaysPastDue: Response.data.data.showDaysPastDue,
              numberOfPages: Response.data.data.numberOfPages,
              showLoadingModal: false,
            });
          } else {
            this.setState({
              errorMessage: Strings("amortizationDataError"),
              showLoadingModal: false,
            });
          }
        }
      );
  };

  onClickViewLoanApplication = () => {
    this.props.history.push(ROUTES.VIEW_LOAN_APPLICATIONS);
  };

  onNoteDetailsClick = () => {
    this.props.history.push(ROUTES.NOTE_DETAILS, {
      refId: this.state.applicationId,
      noteTypeContext: 'LOAN',
    });
  }

  onClickCloseLoanApplication = (loanNote, loanPayOff = null) => {
    this.setState({
      showLoadingModal: true,
      showConfirmModal: false,
      isCloseLoan: false,
      isError: false,
    });
    const request = {
      lid: this.state.applicationId,
      nt: loanNote,
      po: loanPayOff === null ? undefined : loanPayOff,
    };
    closeLoanApplicationAction(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        let selectedLoanDetails = {
          ...this.props.selectedLoanDetails,
        };
        if (this.state.loanDetails?.info.st !== "DISBURSED") {
          selectedLoanDetails.status =
            LOAN_APPLICATION_STATUS.rejected;
          this.props.setLoanDetailsIDAction(selectedLoanDetails);
        }
        let id = apiResponse.data.data.id;
        this.setState(
          {
            isSuccess: true,
            successMessage: Strings(
              "Loan Application Closed Successfully"
            ),
            isError: false,
            errorMessage: "",
            showLoadingModal: false,
            disableCloseLoanButton: true,
          },
          () => {
            id && this.viewReceipt(id);
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState({
          isSuccess: false,
          successMessage: "",
          isError: true,
          errorMessage: apiResponse.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  upcoming = (
    callBack = () => {
      // This is intentional
    }
  ) => {

    let requestData =
      this.state.npa && !this.state.readOnly
        ? null
        : this.state.requestData;
    this.setState(
      {
        isAssignRepayment: true,
        showTakeOffModal: true,
        requestData,
      }, () => {
        callBack();
      }
    );
  };

  getEditedRepaymentData = () => {
    let editedRepaymentData = {
      term: this.state.requestData.request.term,
      EMIAmount: this.state.requestData.request.emi,
      frequency: this.state.requestData.request.frequency,
      collectionDays: this.state.requestData.request.collectionDays,
    }
    this.setState({ editedRepaymentData })
  }

  getRepaymentData = () => {
    let repaymentData = [
      {
        default: "term",
        existing: this.state.termRequested,
        type: "number",
        disabled: this.state.requestData?.request
      },
      {
        default: "EMIAmount",
        existing: this.state.emiAmount,
        type: "number",
        disabled: this.state.requestData?.request
      },
      {
        default: "frequency",
        existing: this.state.freqRequested,
        type: "seect",
        dropdownList: this.state.frequencyList,
        disabled: this.state.requestData?.request
      },
      {
        default: "collectionDays",
        existing: Utility.convertCollectionDaysToSpanish(this.state.collectionDays.split(",")),
        type: "select",
        dropdownList: this.state.possibleCollectionDays,
        disabled: this.state.requestData?.request
      },
    ];
    this.setState({
      repaymentData,
      totalAmount: this.state.requestData?.request
        ? this.state.requestData.request.term * this.state.requestData.request.emi
        : 0,
    });
  };

  getAllowedFrequencies = () => {
    let request = {
      level: this.state.level,
      loanApprovedAmount: this.state.loanApprovedAmount,
      amount: this.state.amount,
      loanType: this.state.loanType,
      minimartId: this.state.loanDetails.minimart.mid
    };
    getAllowedFrequencyAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let frequencyList = response.data.data.frqs
          ? response.data.data.frqs.map((frequency) => {
            return { label: frequency, value: frequency };
          })
          : [
            {
              label: FREQUENCIES.WEEKLY_THRICE,
              value: FREQUENCIES.WEEKLY_THRICE,
            },
          ];
        this.setState({ frequencyList, errorMessage: "" });
      } else {
        this.setState({ errorMessage: response.data.msg });
      }
    });
  };

  /* #region On Change Events For Late Fees  */

  onAmountChange = (event) => {
    this.setState({ amount: event.target.value }, () => {
      if (this.state.amount !== "") {
        //NOSONAR
        this.setState({ errorMessage: "" });
      }
    });
  };

  onNotesChange = (event) => {
    this.setState({ note: event.target.value, errorMessage: "" });
  };

  onRequestNotesChange = (event) => {
    this.setState({
      requestNote: event.target.value,
      errorMessage: "",
    });
  };

  /* #endregion */

  handleSuccess = (successMessage, showTakeOffModal = false) => {
    this.setState({
      showLoadingModal: false,
      showTakeOffModal,
      isSuccess: true,
      successMessage,
      errorMessage: "",
    });
  };

  handleError = (errorMessage) => {
    this.setState({
      showLoadingModal: false,
      showTakeOffModal: true,
      errorMessage,
      isSuccess: false,
      successMessage: "",
    });
  };

  successResponse = (Response) => {
    this.handleResponse(Response, "RequestCreatedSuccessfully");
  };
  requestForTakeOffLateFees = (editedRepaymentData) => {
    if (!this.state.isAssignRepayment) {
      if (this.state.amount) {
        if (
          this.state.amount <= this.state.lateFeesAmt &&
          parseInt(this.state.amount) !== 0
        ) {
          this.setState({ showLoadingModal: true, isSuccess: false });
          this.state.loanId !== null &&
            requestForTakeOffLateFeesAction(
              {
                loanId: this.state.loanId,
                amount: this.state.amount,
                note: this.state.note,
              }, this.props.logInUserInfo.role ===
              USER_ROLES.collectionsManager
              || this.props.logInUserInfo.role ===
              USER_ROLES.collectionsSupervisor
              || this.props.logInUserInfo.role ===
              USER_ROLES.administrativeManager
              || this.props.logInUserInfo.role ===
              USER_ROLES.operationsModerator
            || this.props.logInUserInfo.role ===
            USER_ROLES.operationsManager,
              (Response) => this.successResponse(Response)
            );
        } else {
          this.setState({ errorMessage: "InvalidNegotiationAmount" });
        }
      } else {
        this.setState({ errorMessage: "PleaseEnterAmount" });
      }
    } else if (
      this.checkValidationsForRepaymentPlan(editedRepaymentData)
    ) {

      if (
        parseInt(editedRepaymentData.term) *
        parseInt(editedRepaymentData.EMIAmount) >=
        this.state.loanDetails.info.uc
      ) {
        this.setState({ showLoadingModal: true, isSuccess: false });
        this.state.loanId !== null &&
          this.debounceRequestForAssignRepaymentAction(editedRepaymentData);
      } else {
        this.setState({ errorMessage: "rescheduledLoanError" });
      }
    }
  };

  callRequestForAssignRepaymentAction = (editedRepaymentData) => {
    requestForAssignRepaymentAction(
      {
        loanId: this.state.loanId,
        term: editedRepaymentData.term,
        emi: editedRepaymentData.EMIAmount,
        frequency: editedRepaymentData.frequency,
        collectionDays: editedRepaymentData.collectionDays,
        note: this.state.note,
      },
      this.props.logInUserInfo.role ===
      USER_ROLES.collectionsManager
      || this.props.logInUserInfo.role ===
      USER_ROLES.collectionsSupervisor
      || this.props.logInUserInfo.role ===
      USER_ROLES.administrativeManager
      || this.props.logInUserInfo.role ===
      USER_ROLES.operationsModerator
      || this.props.logInUserInfo.role ===
      USER_ROLES.operationsManager,
      (Response) => this.successResponse(Response),
    )
  }

  checkValidationsForRepaymentPlan = (data) => {
    if (!data.term || !data.EMIAmount || !data.frequency || !data.collectionDays) {
      this.setState({ errorMessage: "EnterRequiredField" });
      return false;
    }
    return true;
  };

  onClickViewAssignRequest = () => {
    this.setState(
      { isAssignRepayment: true },
      this.onClickViewRequest()
    );
  };

  onClickViewTakeOffRequest = () => {
    this.setState(
      { isAssignRepayment: false },
      this.onClickViewRequest()
    );
  };

  onClickViewRequest = () => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      isError: false,
    });
    (this.state.ticketId !== null ||
      this.state.lateFeeTicketId !== null) &&
      getNegotiationRequestAction(
        this.state.ticketId || this.state.lateFeeTicketId,
        (Response) => {
          if (Response.status === OPERATION_STATUS.SUCCESS) {
            this.handleNegotiationResponse(Response);
          } else {
            this.setState({
              showLoadingModal: false,
              showTakeOffModal: false,
              errorMessage: Response.error.message,
              isError:
                Response.error.code !==
                API_ERROR_CODE.NEGOTIATION_EXPIRED
              ,
              isNewAssignRepayment:
                Response.error &&
                Response.error.code ===
                API_ERROR_CODE.NEGOTIATION_EXPIRED,
              showConfirmModal:
                Response.error &&
                Response.error.code ===
                API_ERROR_CODE.NEGOTIATION_EXPIRED,
            });
          }
        }
      );
  };

  handleNegotiationResponse = (Response) => {
    //NOSONAR
    if (Response.data.data !== null) {
      if (
        Response.data.data.type ===
        LOAN_VIEW_PREVIOUS_REQ_CONST.TAKE_OFF_LATE_FEES &&
        !this.state.isAssignRepayment
      ) {
        this.setState({
          showLoadingModal: false,
          showTakeOffModal: true,
          readOnly: true,
          requestData: Response.data.data,
          refId: Response.data.data.id,
          requestNote: Response.data.data.approval
            ? Response.data.data.approval.note
            : "",
          errorMessage: Response.error.message,
        });
      } else if (
        Response.data.data.negotiation.type ===
        LOAN_VIEW_PREVIOUS_REQ_CONST.NEW_REPAYMENT_PLAN &&
        this.state.isAssignRepayment
      ) {
        this.setState(
          {
            showLoadingModal: false,
            showTakeOffModal: true,
            requestData: Response.data.data.negotiation,
            readOnly: true,

            refId: Response.data.data.negotiation.id,
            emi: this.state.loanDetails.info.emi,
            btd: Response.data.data.calculation.btd,
            requestNote: Response.data.data.negotiation.approval
              ? Response.data.data.negotiation.approval.note
              : "",
            errorMessage: Response.error.message,
          },
          () => {
            if (this.state.isAssignRepayment) {
              this.getEditedRepaymentData();
            }
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          showTakeOffModal: false,
          errorMessage: "NoRequest",
          isError: true,
        });
      }
    } else {
      this.setState({
        showLoadingModal: false,
        showTakeOffModal: false,
        errorMessage: "NoRequest",
        isError: true,
      });
    }
  };

  onApproveRequest = () => {
    this.setState({ showLoadingModal: true });
    if (!this.state.isAssignRepayment) {
      approveOrRejectRequestForTakeOffLateFeesAction(
        {
          refId: this.state.refId,
          status: REQUEST_STATUS.approved,
          note: this.state.requestNote,
        },
        (Response) =>
          this.handleResponse(Response, "RequestApprovedSuccessfully")
      );
    } else {
      approveOrRejectRequestForAssignRepaymentAction(
        {
          refId: this.state.refId,
          status: REQUEST_STATUS.approved,
          note: this.state.requestNote,
        },
        (Response) =>
          this.handleResponse(Response, "RequestApprovedSuccessfully")
      );
    }
  };

  onRejectRequest = () => {
    this.setState({ showLoadingModal: true });
    if (!this.state.isAssignRepayment) {
      approveOrRejectRequestForTakeOffLateFeesAction(
        {
          refId: this.state.refId,
          status: REQUEST_STATUS.rejected,
          note: this.state.requestNote,
        },
        (Response) =>
          this.handleResponse(Response, "RequestRejectedSuccessfully")
      );
    } else {
      approveOrRejectRequestForAssignRepaymentAction(
        {
          refId: this.state.refId,
          status: REQUEST_STATUS.rejected,
          note: this.state.requestNote,
        },
        (Response) =>
          this.handleResponse(Response, "RequestRejectedSuccessfully")
      );
    }
  };

  onClickTakeOffLateFees = () => {
    let requestData =
      this.state.tla && !this.state.readOnly
        ? null
        : this.state.requestData;
    this.setState({
      showTakeOffModal: true,
      isAssignRepayment: false,
      requestData,
    });
  };

  onModalCancelClick = () => {
    this.setState({
      showTakeOffModal: false,
      errorMessage: "",
      emi: 0,
      btd: 0,
      requestNote: "",
    });
  };

  /* #endregion */

  /* #region  Pagination */

  onPageChange = (page, pageLength) => {
    this.setState(
      {
        recordsPerPage: pageLength,
        activePage: page,
      },
      () => {
        this.getAmortizationsTable(this.state.loanId);
      }
    );
  };

  /* #endregion */

  /* #region  Back Button */

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  viewPreviousRequest = (type) => {
    this.setState({ showLoadingModal: true });
    viewPreviousRequestAction(
      { loanId: this.state.loanId, type: type },
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.setState(
            {
              requestData:
                type ===
                  LOAN_VIEW_PREVIOUS_REQ_CONST.TAKE_OFF_LATE_FEES
                  ? response.data.data
                  : response.data.data.negotiation,
              showLoadingModal: false,
              readOnly:
                type ===
                LOAN_VIEW_PREVIOUS_REQ_CONST.NEW_REPAYMENT_PLAN,
            },
            () => {
              if (
                type ===
                LOAN_VIEW_PREVIOUS_REQ_CONST.TAKE_OFF_LATE_FEES
              ) {
                this.onClickTakeOffLateFees();
              } else {
                this.upcoming(this.getRepaymentData);
              }
            }
          );
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoadingModal: false,
            isNewAssignRepayment:
              type ===
              LOAN_VIEW_PREVIOUS_REQ_CONST.NEW_REPAYMENT_PLAN &&
              (this.props.logInUserInfo.role ===
                USER_ROLES.collectionsOfficer),
            showConfirmModal:
              type ===
              LOAN_VIEW_PREVIOUS_REQ_CONST.NEW_REPAYMENT_PLAN &&
              (this.props.logInUserInfo.role ===
                USER_ROLES.collectionsOfficer),
          });
        }
      }
    );
  };

  viewReceipt = (receiptId) => {
    this.setState({ showLoadingModal: true, isSuccess: false });
    generatePaymentReceiptAction(receiptId, true, (response) => {
      if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        const filename = `${Strings(
          "PaymentReceiptFileName"
        )}${Utility.getReportFormattedDateString(
          Utility.convertDateIntoMiliSeconds(new Date())
        )}.pdf`; //or any other file extension
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          isError: false,
        });
        link.click();
      } else {
        this.setState({
          showLoadingModal: false,
          isError: true,
          errorMessage: Strings("NoRecordsFound"),
        });
      }
    });
  };

  sendUpdatedMinimartDetails = (request, callback) => {
    this.setState({
      showLoadingModal: true,
      isSuccess: false,
      isError: false,
    });
    sendUpdatedMinimartDetailsAction(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            isSuccess: true,
            isError: false,
            successMessage: Strings("DataUpdatedSuccessfully"),
          },
          () => {
            callback();
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          isError: true,
          isSuccess: false,
          errorMessage: apiResponse.error.message,
        });
      }
    });
  };

  changeCollectionDays = (request, callback) => {
    this.setState({
      showLoadingModal: true,
      isSuccess: false,
      isError: false,
    });

    changeCollectionDaysAction(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            isSuccess: true,
            isError: false,
            successMessage: Strings("DataUpdatedSuccessfully"),
          },
          () => {
            callback();
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          isError: true,
          isSuccess: false,
          errorMessage: apiResponse.error.message,
        });
      }
    });
  };

  loanCategoriesDetails = (request, callback) => {
    this.setState({
      showLoadingModal: true,
      isSuccess: false,
      isError: false,
    });
    loanCategoriesDetailsAction(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            isSuccess: true,
            isError: false,
            successMessage: Strings("DataUpdatedSuccessfully"),
          },
          () => {
            callback();
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          isError: true,
          isSuccess: false,
          errorMessage: apiResponse.error.message,
        });
      }
    });
  };

  /* #endregion */

  /**
   * Method to download loan details rerpots
   * @param {*} url
   * @param {*} requestData
   * @param {*} filetype
   */
  downloadReports = (url, requestData) => {
    this.setState({
      showLoadingModal: true,
      isError: false,
      isSuccess: false,
      successMessage: "",
    });
    dowloadedAction(
      url,
      requestData,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            isSuccess: true,
            successMessage: Strings(
              "pdfDownload"
            ),
            isError: false,
            errorMessage: "",
            showLoadingModal: false,
          })
        } else {
          this.setState({
            showLoadingModal: false,
            errorMessage: response.error.message,
          });
        }
      },
      "LoanReports"
    );
  };

  //render
  handleResponse = (Response, successString) => {
    if (Response.status === OPERATION_STATUS.SUCCESS) {
      if (successString !== "RequestCreatedSuccessfully") {
        this.setState({
          requestNote: "",
        });
      }
      this.handleSuccess(successString);
      this.getLoanDetailsById(this.state.applicationId);
      this.getAmortizationsTable();
    } else {
      this.handleError(Response.error.message);
    }
  };

  getLoanDetailsHeaderText = () => {
    if (this.state.readOnly) {
      return this.state.isAssignRepayment
        ? Strings("AssignRepayment") + " " + Strings("Request")
        : Strings("takeOffLateFees") + " " + Strings("Request");
    } else {
      return this.state.isAssignRepayment
        ? Strings("AssignRepayment")
        : Strings("takeOffLateFees");
    }
  };

  downloadDownPaymentRecepit = (receiptId, applicationId) => {
    this.setState({ showLoadingModal: true, isSuccess: false });
    downloadDownpaymentReceiptAction(receiptId, true, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
          fileDownload(
            response.data,
            `Recibo de Anticipo-${applicationId} ${Utility.getFileType(
              "pdf"
            )}`
          );

          this.setState({
            errorMessage: "",
            showLoadingModal: false,
            isError: false,
          });
        } else {
          this.setState({
            showLoadingModal: false,
            isError: true,
            errorMessage: Strings("NoRecordsFound"),
          });
        }
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  dowloadLoanCreditAgreement = (url) => {
    this.setState({ showLoadingModal: true });
    const s3rUl = Utility.getDecryptedData(url);
    window.open(s3rUl);
    this.setState({ showLoadingModal: false });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  toggleModal = (type = "") => {
    this.setState({
      noteType: type,
      notesModal: !this.state.notesModal,
      showAmortizationNoteModal: !this.state.showAmortizationNoteModal
    });
  };
  toggleAmortizationNoteModal =()=>{
    this.setState({
      showAmortizationNoteModal: !this.state.showAmortizationNoteModal,
      amortizationNotes:[]
    });
  }
  showNotesModal = (index, modalType, noteId = null) => {
    
    noteId !== null && modalType === MODAL_TYPE.EDIT
      ? this.setState(
        {
          noteId: noteId,
        },
        () => {
          noteId && this.getNote(this.state.noteId);
        }
      )
      : noteId === null &&
      modalType === MODAL_TYPE.ADD_TYPE &&
      this.setState({
        notesObject: {
          noteFromCollectionsOff: "",
          author: "",
          createdAt: "",
        },
        notesModal: true,
      });
    this.setState({
      modalType: modalType,
      errorMessage: "",
      noteType: NOTES_TYPE.COL_OFF_EMI_INPUT,
      index: index,
    });
  };
  showNotesforAmmortizations = (row, key, isAddNoteAccess = true) => {
    this.setState({
      showAmortizationNoteModal: true,
      amortization: {
        amortizationIndex: row["index"],
        amortizationId: row["id"]
      },
      isAddNoteAccess: isAddNoteAccess
    })
  }
  viewNotes = () =>{
    let requestObject = {
      notesSearchCriteria: {
        refId: this.state.amortization.amortizationId,
        noteType: NOTES_TYPE.COL_OFF_EMI_INPUT,
        noteTypeContext: NOTE_TYPE_CONTEXT.LOAN,
        contextRefId: this.state.applicationId
      },
      limit: 500,
      offset: 0
    }
    
    this.setState({ showLoadingModal: true });
    getNotesAction(requestObject, (Response) =>{
      if(Response.status === OPERATION_STATUS.SUCCESS){
        this.setState({
          showLoadingModal: false,
          amortizationNotes:Response.data.data.list
        })
      }else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    })
  }
  addEMINote = (noteRequest) => {
    
    noteRequest = {
      refId: this.state.loanId,
      ...noteRequest,
    };
    this.setState({ showLoadingModal: true });

    addEMINoteAction(noteRequest, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            noteAdditionadata: Response.data.data,
            errorMessage: "",
          },
          () => {
            this.getLoanDetailsById(this.state.applicationId);
            this.viewNotes()
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  getNote = (noteId) => {
    this.setState({ showLoadingModal: true });
    getNoteAction(noteId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          notesObject: {
            noteFromCollectionsOff: Response.data.data.note,
            author: Response.data.data.author,
            createdAt: moment(Response.data.data.ca).format(
              INVOICE_DATE_FORMAT
            ),
          },
          showLoadingModal: false,
          errorMessage: "",
          notesModal: true,
        });
      } else {
        this.setState(
          {
            notesObject: {
              noteFromCollectionsOff: "",
              author: "",
              createdAt: "",
            },
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  updateNote = (noteObject) => {
    this.setState({ showLoadingModal: true });
    updateNoteAction(noteObject, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
          },
          () => {
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  deleteNote = (noteId) => {
    this.setState({ showLoadingModal: true });
    deleteNoteAction(noteId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          { showLoadingModal: false, errorMessage: "" },
          () => {
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  getMinimartBusinessTypes = () => {
    getMinimartBusinessTypes((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let list = Utility.createBusinessTypesList(
          response.data.data.bty
        );
        this.setState({
          businessTypeList: list,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
        });
      }
    });
  };

  getLoanAmountPaidDivision = (requestParam, callback) => {
    this.setState({ showLoadingModal: true });
    getLoanAmountPaidDivisionAction(requestParam, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({ showLoadingModal: false }, () => {
          callback(response.data.data);
        });
      } else {
        this.setState({ showLoadingModal: false });
      }
    });
  };

  getPossibleCollectionCycles = (req, callBack) => {
    this.setState({ showLoadingModal: true });
    req["mid"] = this.state.loanDetails.minimart.mid
    if (this.state.isAssignRepayment) {
      req["frs"] = "LOAN_CREATION"
    }

    getPossibleCollectionCycleAction(req, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let possibleCollectionDays = Utility.convertCollectionDaysToSpanishForDropdown(response.data.data.possibleCollectionDays)
        let repaymentData = [...this.state.repaymentData];
        if (repaymentData.length !== 0) {
          repaymentData[3]["dropdownList"] = possibleCollectionDays;
        }
        this.setState({
          showLoadingModal: false,
          possibleCollectionDays: possibleCollectionDays,
          repaymentData,
          errorMessage: "",
          currentCollectionCycle: response.data.data.minimartCollectionCycle
        }, () => {
          callBack();

        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  }

  addNote = (noteRequest) => {
    this.setState({ showLoadingModal: true });
    AddNoteAction(noteRequest, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoadingModal: false,
          isSuccess: true,
          successMessage: Strings("noteAddedSuccessfully"),
          errorMessage: "",
        },
          () => {
            this.getLoanDetailsById(this.state.applicationId);
          });
      }
      else {
        this.setState({
          showLoadingModal: false,
          isSuccess: true,
          errorMessage: response.error.message,
        },
          () => {
            this.scrollToTop();
          });
      }
    })
  }

  render() {
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />

        <UserModal
          open={this.state.showConfirmModal}
          modalCloseOnEsc={false}
          modalCloseOnOverlayClick={false}
          modalShowCloseIcon={false}
          headerText={
            this.state.isNewAssignRepayment
              ? Strings("SorryAnErrorHasOccurred")
              : this.state.isCloseLoan // NOSONAR
                ? Strings("Close Loan")
                : ""
          }
          modalText1={
            this.state.isCloseLoan
              ? Strings("loanPayOffText")
              : Strings("NEGOTIATION_EXPIRED_MESSAGE")
          }
          showHr={!this.state.isCloseLoan}
          modalButtonOk={{
            text: this.state.isCloseLoan
              ? Strings("Submit")
              : Strings("Yes"),
            onClick: this.state.isCloseLoan
              ? this.onClickCloseLoanApplication
              : () => {
                this.props.history.goBack();
              },
          }}
          modalButtonCancel={
            this.state.isCloseLoan
              ? {
                text: Strings("Cancel"),
                onClick: () => {
                  this.setState({
                    showConfirmModal: false,
                    isCloseLoan: false,
                  });
                },
              }
              : null
          }
          isCloseLoan={this.state.isCloseLoan}
          loanStatus={this.state.loanDetails?.info.st}
        />

        {this.state.isSuccess && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: Strings(this.state.successMessage),
              level: "success",
            }}
          />
        )}

        {this.state.isError && (
          <UserNotification
            key='Error'
            userNotificationObj={{
              message: Strings(this.state.errorMessage),
              level: "error",
            }}
          />
        )}

        {this.state.showTakeOffModal && (
          <LoanDetailModal
            open={this.state.showTakeOffModal}
            modalCloseOnEsc={false}
            modalCloseOnOverlayClick={false}
            modalShowCloseIcon={false}
            headerText={this.getLoanDetailsHeaderText()}
            modalButtonCancel={{
              text: Strings("Cancel"),
              onClick: this.onModalCancelClick,
            }}
            modalButtonOk={{
              text: Strings("Request"),
              onClick: this.onRequestForTakeOffLateFees,
            }}
            modalButtonApprove={{
              text: Strings("Approve"),
              onClick: this.onApproveRequest,
            }}
            modalButtonReject={{
              text: Strings("Reject"),
              onClick: this.onRejectRequest,
            }}
            onAmountChange={this.onAmountChange}
            onNotesChange={this.onNotesChange}
            onRequestNotesChange={this.onRequestNotesChange}
            requestForTakeOffLateFees={this.requestForTakeOffLateFees}
            onRejectRequest={this.onRejectRequest}
            onApproveRequest={this.onApproveRequest}
            errorMessage={this.state.errorMessage}
            requestData={this.state.requestData}
            readOnly={this.state.readOnly}
            repaymentData={this.state.repaymentData}
            isAssignRepayment={this.state.isAssignRepayment}
            getRepaymentData={this.getRepaymentData}
            tla={this.state.tla}
            npa={this.state.npa}
            isCollectionOfficer={
              this.props.logInUserInfo.role ===
              USER_ROLES.collectionsOfficer
            }
            requestNote={this.state.requestNote}
            loggedInUserInfo={this.props.logInUserInfo}
            getPossibleCollectionCycles={this.getPossibleCollectionCycles}
            totalAmount={this.state.totalAmount}
            editedRepaymentData={this.state.editedRepaymentData}
          />
        )}

        <Row className='heading'>
          {Strings("loanPortFolioManagementDetails")}
        </Row>
        <LoanDetails
          onPageChange={this.onPageChange}
          applicationId={this.state.applicationId}
          loanId={this.state.loanId}
          loanDetails={this.state.loanDetails}
          amortizations={this.state.amortizations}
          showLoadingModal={this.state.showLoadingModal}
          recordsPerPage={this.state.recordsPerPage}
          activePage={this.state.activePage}
          numberOfPages={this.state.numberOfPages}
          onClickViewLoanApplication={this.onClickViewLoanApplication}
          upcoming={this.upcoming}
          logInUserInfo={this.props.logInUserInfo}
          handleBackButtonClick={this.handleBackButtonClick}
          onClickTakeOffLateFees={this.onClickTakeOffLateFees}
          onClickViewRequest={this.onClickViewRequest}
          onClickViewTakeOffRequest={this.onClickViewTakeOffRequest}
          onClickViewAssignRequest={this.onClickViewAssignRequest}
          ticketId={this.state.ticketId}
          npa={this.state.npa}
          tla={this.state.tla}
          viewPreviousRequest={this.viewPreviousRequest}
          loanType={this.state.loanType}
          viewReceipt={this.viewReceipt}
          sendUpdatedMinimartDetails={this.sendUpdatedMinimartDetails}
          changeCollectionDays={this.changeCollectionDays}
          loanCategoriesDetails={this.loanCategoriesDetails}
          onClickCloseLoanApplication={
            this.onClickCloseLoanApplication
          }
          getLoanAmountPaidDivision={this.getLoanAmountPaidDivision}
          disableCloseLoanButton={this.state.disableCloseLoanButton}
          showConfirmPopUp={() => {
            this.setState({
              showConfirmModal: true,
              isCloseLoan: true,
            });
          }}
          downloadReports={this.downloadReports}
          getLoanDetailsById={this.getLoanDetailsById}
          downloadDownPaymentRecepit={this.downloadDownPaymentRecepit}
          dowloadLoanCreditAgreement={this.dowloadLoanCreditAgreement}
          addEMINote={this.addEMINote}
          getNote={this.getNote}
          updateNote={this.updateNote}
          deleteNote={this.deleteNote}
          getAmortizationsTable={this.getAmortizationsTable}
          noteId={this.state.noteId}
          noteType={this.state.noteType}
          notesModal={this.state.notesModal}
          modalType={this.state.modalType}
          index={this.state.index}
          showNotesModal={this.showNotesModal}
          toggleModal={this.toggleModal}
          notesObject={this.state.notesObject}
          businessTypeList={this.state.businessTypeList}
          amortizationSummary={this.state.amortizationSummary}
          showDaysPastDue={this.state.showDaysPastDue}
          searchPage={this.props.searchPage}
          lateFeeTicketId={this.state.lateFeeTicketId}
          activeTab={this.props.activeTab}
          getPossibleCollectionCycles={this.getPossibleCollectionCycles}
          possibleCollectionDays={this.state.possibleCollectionDays}
          currentCollectionCycle={this.state.currentCollectionCycle}
          onNoteDetailsClick={this.onNoteDetailsClick}
          addNote={this.addNote}
          showNotesforAmmortizations={this.showNotesforAmmortizations}
          viewNotes={this.viewNotes}
          amortizationNotes={this.state.amortizationNotes}
          showAmortizationNoteModal={this.state.showAmortizationNoteModal}
          amortization={this.state.amortization}
          toggleAmortizationNoteModal={this.toggleAmortizationNoteModal}
          isAddNoteAccess={this.state.isAddNoteAccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLoanDetails: state.loanDetailsReducer.loanDetails,
  logInUserInfo: state.loginReducer.loggedInUserInfo,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
  activeTab: state.minimartListReducer.activeTab,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoanDetailsIDAction,
      setSearchFilterAction,
      getMinimartBusinessTypes,
      setMinimartDetailsActiveTab,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanDetailsContainer);
