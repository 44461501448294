import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//components
import ClientPortal from './ClientPortal'
import CommonPanel from '../CommonPanel/CommonPanel';
import UserNotification from '../CustomUIComponents/UserNotification/UserNotification';
import LoadingModal from '../CustomUIComponents/LoadingModal/LoadingModal';

//constants
import {
    SEARCH_FILTER_TYPE,
    SEARCH_FILTER_FIELDS,
    DEFAULT_VALUES,
    SEARCH_FILTER_PAGES,
    CLIENT_PORTAL_FILTER,
    NOTES_TYPE
} from "../../constants/appConstants";
import {
    DEFAULT_PAGINATION_RECORDS_COUNT,
    DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from '../../constants/routeConstants';
import { CLIENT_STATE } from './Constants';

//actions
import {
    getClientStatesAction,
    getIdentificationTypesAction,
    getClientListAction,
    getActiveLoansAction,
    changeClientStatusAction,
    updateCedulaAction
} from "./ClientPortalAction"
import {
    setMiniMartDetailsAction,
} from "../CiInterface/CIInterfaceActions";
import { setSearchFilterAction } from "../../constants/Action";

//Strings
import { Strings } from "../../resources/i18n/i18n";
import { getNotes } from '../ViewLoanApplication/ViewLoanApplicationAction';

class ClientPortalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.isMinimartClick = false;
        this.state = {
            clientStates: [Strings("selectClientState")],
            identificationTypes: [Strings("selectIdentificationType")],
            minimartInfo: [],
            filters: {
                clientId: props.searchFilter?.clientId,
                identificationNumber: props.searchFilter?.idNumber,
                matricesId: props.searchFilter?.mid,
                identificationType: props.searchFilter?.idType,
                clientState: props.searchFilter?.cs
            },
            data: [],

            //Spinner and Toast messages
            showLoader: false,
            showToast: false,
            message: "",
            level: "",

            //Pagination
            numberOfPages: 0,
            activePage: props.searchFilter?.offset,
            recordsPerPage: props.searchFilter?.limit,
            clientNotes: [],
            showClientNotesModal: false,
            refId: "",
        }
    }

    componentWillUnmount() {
        if (!this.isMinimartClick) {
            this.props.setSearchFilterAction(
                SEARCH_FILTER_PAGES.CLIENT_PORTAL,
                { ...CLIENT_PORTAL_FILTER }
            );
        }
    }

    componentDidMount() {
        this.getClientStates();
        this.getIdentificationTypes();
        this.handleOnClickSearchFilter();
    }

    handleChange = (event) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [event.target.name]: DEFAULT_VALUES.includes(event.target.value)
                    ? ""
                    : event.target.value
            }
        })
    }

    getClientStates = () => {
        this.setState({
            showLoader: true,
        });
        getClientStatesAction((response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    clientStates: [...this.state.clientStates, ...response.data.data.clientState],
                });
            } else {
                this.setState({
                    errorMessage: response.error.message,
                });
            }
        });
    }

    getIdentificationTypes = () => {
        this.setState({
            showLoader: true,
        });
        getIdentificationTypesAction((response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    identificationTypes: [...this.state.identificationTypes, ...response.data.data.identificationTypes],
                });
            } else {
                this.setState({
                    errorMessage: response.error.message,
                });
            }
        });
    }

    getActiveLoans = (identificationNumber, callBack) => {
        this.setState({
            showLoader: true,
        });
        getActiveLoansAction(identificationNumber, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                let minimartInfo = response.data.data.minimartInfo
                this.setState({
                    showLoader: false,
                    minimartInfo: minimartInfo,
                }, () => {
                    callBack()
                });
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                });
            }
        });
    }

    getSearchFilterParameters = () => {
        return ([
            {
                type: SEARCH_FILTER_TYPE.INPUT,
                searchPlaceHolder: Strings("clientId"),
                handleOnChange: this.handleChange,
                Value: this.state.filters.clientId,
                fieldname: SEARCH_FILTER_FIELDS.CLIENT_ID,
                inputType: SEARCH_FILTER_TYPE.NUMBER,
                name: "clientId",
            },
            {
                type: SEARCH_FILTER_TYPE.INPUT,
                searchPlaceHolder: Strings("identificationNumber"),
                handleOnChange: this.handleChange,
                Value: this.state.filters.identificationNumber,
                fieldname: SEARCH_FILTER_FIELDS.IDENTIFICATION_NUMBER,
                inputType: SEARCH_FILTER_TYPE.NUMBER,
                name: "identificationNumber",
            },
            {
                type: SEARCH_FILTER_TYPE.INPUT,
                searchPlaceHolder: Strings("MatricesID"),
                handleOnChange: this.handleChange,
                Value: this.state.filters.matricesId,
                fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
                inputType: SEARCH_FILTER_TYPE.NUMBER,
                name: "matricesId",
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleChange,
                Value: this.state.filters.clientState,
                fieldname: SEARCH_FILTER_FIELDS.CLIENT_STATE,
                filterDropdownList: this.state.clientStates,
                name: "clientState",
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleChange,
                Value: this.state.filters.identificationType,
                fieldname: SEARCH_FILTER_FIELDS.IDENTIFICATION_TYPE,
                filterDropdownList: this.state.identificationTypes,
                name: "identificationType",
            },
        ])
    }

    handleOnClickSearchFilter = () => {
        this.setState({
            showLoader: true,
            searchFilter: {
                clientId: this.state.filters.clientId,
                idNumber: this.state.filters.identificationNumber,
                mid: this.state.filters.matricesId,
                idType: this.state.filters.identificationType,
                cs: this.state.filters.clientState,
                limit: this.state.recordsPerPage,
                offset: this.state.activePage
            },
        })
        let searchFilterObject = {
            search: {
                clientId: this.state.filters.clientId || undefined,
                identificationNumber: this.state.filters.identificationNumber || undefined,
                matricesId: this.state.filters.matricesId || undefined,
                identificationType: this.state.filters.identificationType || undefined,
                clientState: this.state.filters.clientState || undefined
            },
            limit: this.state.recordsPerPage,
            offset: this.state.activePage - 1
        }

        getClientListAction(searchFilterObject, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                let data = response.data.data.clientMinimarts;
                this.setState({
                    showLoader: false,
                    numberOfPages: response.data.data.numberOfPages,
                    data,
                });
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                });
            }
        });
    }
    updateCedula = (request, callBack) => {
        this.setState({
            showLoader: true,
            showToast: false,
            message: "",
            level: ""
        })
        updateCedulaAction(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings("DataUpdatedSuccessfully"),
                    level: "success",
                }, () => {
                    callBack();
                    this.handleOnClickSearchFilter();
                });
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                    showToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                }, () => {
                    callBack();
                    this.handleOnClickSearchFilter();
                });
            }
        });
    }


    changeClientStatus = (identificationNumber, clientState, notesData, callBack) => {
        let searchFilterObject = {
            idn: identificationNumber,
            cs: clientState ? CLIENT_STATE.blacklisted : CLIENT_STATE.other,
            note: notesData
        }
        this.setState({
            showLoader: true,
            showToast: false,
            message: "",
            level: "",
        });
        changeClientStatusAction(searchFilterObject, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings("statusChangedSuccessfully"),
                    level: "success",
                    errorMessage: "",
                }, () => {
                    this.handleOnClickSearchFilter();
                    callBack()
                })

            } else {
                this.setState({
                    showLoader: false,
                    showToast: false,
                    message: response.error.message,
                    level: "error",
                    errorMessage: response.error.message,
                });
            }
        });
    }
    toggleClientNotesModal = () =>{
        this.setState({
            showClientNotesModal: !this.state.showClientNotesModal,
        })
    }
    showClientNoteModal = (row, col) =>{
        this.setState({
            showClientNotesModal: true,
            refId: row.clientId,
            clientNotes: []
        })
    }

    viewNotes = () => {
        const requestData = {
            notesSearchCriteria: {
                refId: this.state.refId,
                type: NOTES_TYPE.CLIENT_STATE_CHANGE
            },
            limit: 500,
            offset: 0
        }
        this.setState({ showLoader: true });
        getNotes(requestData, (Response) => {
            if (Response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    clientNotes: Response.data.data.list,
                    errorMessage: "",
                    showLoader: false
                })
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: Response.error.message
                })
            }
        })
    }

    handleOnClickResetFilter = () => {
        this.setState({
            filters: {
                clientId: "",
                identificationNumber: "",
                matricesId: "",
                identificationType: "",
                clientState: ""
            },

            //Pagination
            recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
            activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        }, this.handleOnClickSearchFilter)
    }

    handleMinimartClick = (minimart) => {
        this.isMinimartClick = true;
        this.props.setSearchFilterAction(
            SEARCH_FILTER_PAGES.CLIENT_PORTAL,
            this.state.searchFilter,
            SEARCH_FILTER_PAGES.CLIENT_PORTAL
        );
        this.props.setMiniMartDetailsAction({ id: minimart });
        this.props.history.push(ROUTES.MINIMART_DETAILS);
    }

    onPageChange = ({ page, pageLength }) => {
        this.setState(
            {
                activePage: page,
                recordsPerPage: pageLength,
            },
            () => this.handleOnClickSearchFilter()
        );
    };


    render() {
        let searchFilters = this.getSearchFilterParameters();
        return (
            <>
                <LoadingModal
                    showLoadingModal={this.state.showLoader}
                />
                {this.state.showToast && (
                    <UserNotification
                        key={this.state.message}
                        userNotificationObj={{
                            message: this.state.message,
                            level: this.state.level,
                        }}
                    />
                )}
                <CommonPanel
                    //variables
                    header={Strings("CLIENT_PORTAL")}
                    searchFilters={searchFilters}
                    isPagination={true}
                    data={this.state.data}
                    numberOfPages={this.state.numberOfPages}
                    activePage={this.state.activePage}
                    recordsPerPage={this.state.recordsPerPage}

                    //methods
                    handleOnClickSearchFilter={this.handleOnClickSearchFilter}
                    handleOnClickResetFilter={this.handleOnClickResetFilter}
                    onPageChange={this.onPageChange}
                >
                    <ClientPortal
                        data={this.state.data}
                        minimartInfo={this.state.minimartInfo}
                        handleChangeClientStatus={this.handleChangeClientStatus}
                        getActiveLoans={this.getActiveLoans}
                        changeClientStatus={this.changeClientStatus}
                        handleMinimartClick={this.handleMinimartClick}
                        updateCedula={this.updateCedula}
                        viewNotes={this.viewNotes}
                        clientNotes={this.state.clientNotes}
                        showClientNotes={this.showClientNoteModal}
                        showClientNotesModal={this.state.showClientNotesModal}
                        toggleClientNotesModal={this.toggleClientNotesModal}
                        refId={this.state.refId}
                    />
                </CommonPanel>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    searchFilter: state.searchFilterReducer.clientPortalSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getClientStatesAction,
            getIdentificationTypesAction,
            getClientListAction,
            getActiveLoansAction,
            changeClientStatusAction,
            setMiniMartDetailsAction,
            setSearchFilterAction
        }, dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientPortalContainer);
