import { colors } from "../resources/styles/themes";
import { Strings } from "../resources/i18n/i18n";
import moment from "moment";
import React from "react";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import { DEFAULT_PAGINATION_RECORDS_COUNT } from "../components/CustomUIComponents/Pagination/PaginationConstants";
import { DEFAULT_STATUS } from "../components/ConfigurationList/ConfigurationListConstants";
import { DEFAULT_ZONE } from "../components/BranchList/BranchListConstants";

//Assets
import InvoiceFoodImage from "../resources/images/ic_food.svg";
import InvoiceDetergentImage from "../resources/images/ic_detergent.svg";


export const APP_LANGUAGES = {
  ENGLISH: "en",
  SPANISH: "es",
};

export const CURRENCY = " $ ";

export const DEFAULT_ROLE_SELECTION = "selectRole";
export const GET_DEFAULT_ROLE_SELECTION = () => Strings("selectRole");

export const DEFAULT_ASSIGNMENT_TYPE_SELECTION =
  "selectAssignmentType";
export const GET_DEFAULT_ASSIGNMENT_TYPE_SELECTION = () => ({
  id: "selectAssignmentType",
  name: Strings("AssignmentType"),
});

export const DEFAULT_BRANCH_SELECTION = "selectBranch";
export const GET_DEFAULT_BRANCH_SELECTION = () => ({
  id: "selectBranch",
  name: Strings("selectBranch"),
});

export const DEFAULT_USER_SELECTION = "UserRole";
export const GET_DEFAULT_DROPDOWN_SELECTION = () => ({
  name: Strings("SelectAnalyst"),
});

export const DEFAULT_SALES_PERSON_SELECTION = Strings("SALES_PERSON");

export const DEFAULT_SALES_PERSON = () => ({
  userId: "",
  name: Strings("SALES_PERSON"),
});

export const LOAN_APPLICATION_STATUS = {
  approved: "APPROVED",
  incomplete: "INCOMPLETE",
  inProcess: "INPROCESS",
  pending: "PENDING",
  submitted: "SUBMITTED",
  rejected: "REJECTED_BY_SYSTEM",
  created: "CREATED",
  updated: "UPDATED",
  disbursed: "DISBURSED",
  inactive: "INACTIVE",
  dismissed: "DISMISSED",
  closed: "CLOSED",
};

export const MESSENGER_TAB = [
  {
    key: 0,
    value: Strings("Messenger"),
  },
];

export const USER_ROLES = {
  // Matrices
  generalManager: "GENERAL_MANAGER",
  admin: "ADMIN",
  configurator: "CONFIGURATOR",
  collectionsOfficer: "COLLECTIONS_OFFICER",
  loanPortfolioManager: "LOAN_PORTFOLIO_MANAGER",
  accountsOfficer: "ACCOUNTING_OFFICER",
  analyst: "ANALYST",
  salesModerator: "SALES_MODERATOR",
  messengerModerator: "MESSENGER_MODERATOR",
  operationsOfficer: "OPERATIONS_OFFICER",
  operationsModerator: "OPERATIONS_MODERATOR",
  salesPerson: "SALES_PERSON",
  messenger: "MESSENGER",
  collectionOfficer: "COLLECTIONS_OFFICER",
  accountingOfficer: "ACCOUNTING_OFFICER",
  analystModerator: "ANALYST_MODERATOR",
  auditor: "AUDITOR",
  collectionsManager: "COLLECTIONS_MANAGER",
  collectionsSupervisor: "COLLECTIONS_SUPERVISOR",
  administrativeManager: "ADMINISTRATIVE_MANAGER",
  customerService: "CUSTOMER_SERVICE",
  operationsManager: "OPERATIONS_MANAGER",
  salesManager: "SALES_MANAGER",
  technology: "TECHNOLOGY"
};

export const VERSION_TYPE = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  drafted: "DRAFTED",
};

export const COLORS = {
  red: "#DE2929",
  green: "#63A813",
  orange: "#EC9D0E",
  lightGreen: "#D1C605",
  blue: "#2CA5EA",
  lightBlue: "#52CAAD",
  violet: "#9852DE",
  pink: "#d457ed",
};

export const GRAPH_COLORS = [colors.textColor7, colors.textColor8];

export const PIE_CHART_COLORS_CONST = {
  MINIMART_CATEGORIES: "minimartCategories",
  MINIMART_TYPES: "minimartTypes",
  INVOICE_STATUS: "invoiceStatus",
  LOAN_CATEGORIES: "loanCategories",
  LOAN_APPLICATION_STATUS: "loanApplicationStatus",
  PROVISION_AMOUNT: "provisionAmount",
  SALES_PERSON_DAILY_STATUS: "salesPersonDailyStatus",
  SALES_PERSON_DAILY_STATUS_FOR_LOANS:
    "salesPersonDailyStatusForLoans",
  MESSENGER_DAILY_STATUS: "messengerDailyStatus",
  MESSENGER_DAILY_STATUS_LOANS: "messengerDailyStatusLoans",
  MESSENGER_PIECHART_STATS: "messengerPiechartStats",
  DENIAL_REASONS: "denialReasons",
  AMOUNT_COLLECTION: "amountCollection",
  MINIMART_BUSINESS_TYPE: "businessType",
  PROMOTION_STATS_COUNT: "promotionStatsCount",
  PROMOTION_STATS_AMOUNT: "promotionStatsAmount",
};

export const PIE_CHART_COLORS = {
  minimartCategories: [
    colors.piechartColor1,
    colors.piechartColor2,
    colors.piechartColor3,
    colors.piechartColor4,
  ],
  minimartTypes: [
    colors.piechartColor1,
    colors.piechartColor6,
    colors.piechartColor7,
  ],
  invoiceStatus: [colors.piechartColor1, colors.piechartColor5],
  loanCategories: [
    colors.piechartColor10,
    colors.piechartColor11,
    colors.piechartColor12,
    colors.piechartColor13,
    colors.piechartColor54,
    colors.piechartColor55,
  ],
  loanApplicationStatus: [
    colors.piechartColor14, //#3078db
    colors.piechartColor20, //#c74ed5
    colors.piechartColor15, //#838bd5
    colors.piechartColor18, //#dc1087
    colors.piechartColor16, //#9361f5
    colors.piechartColor19, //#f90600
    colors.piechartColor17, //#9e36d6
    colors.piechartColor21, //#f27300
  ],
  provisionAmount: [
    colors.barChartColor1,
    colors.barChartColor2,
    colors.barChartColor3,
    colors.piechartColor23,
    colors.barChartColor4,
    colors.barChartColor5,
    colors.barChartColor6,
  ],

  salesPersonDailyStatus: [
    colors.piechartColor23,
    colors.piechartColor24,
  ],
  salesPersonDailyStatusForLoans: [
    colors.piechartColor23,
    colors.piechartColor24,
    colors.piechartColor25,
    colors.piechartColor32,
  ],
  messengerDailyStatus: [
    colors.piechartColor23,
    colors.piechartColor24,
    colors.piechartColor25,
    colors.piechartColor26,
  ],
  messengerDailyStatusLoans: [
    colors.piechartColor23,
    colors.piechartColor25,
    colors.piechartColor24,
  ],
  messengerPiechartStats: [
    colors.piechartColor25,
    colors.piechartColor24,
    colors.piechartColor23,
    colors.piechartColor32,
  ],
  denialReasons: [
    colors.barChartColor7,
    colors.barChartColor8,
    colors.barChartColor9,
  ],
  amountCollection: [
    colors.piechartColor23,
    colors.piechartColor25,
    colors.piechartColor24,
  ],
  businessType: [
    colors.piechartColor33,
    colors.piechartColor34,
    colors.piechartColor35,
    colors.piechartColor36,
    colors.piechartColor37,
    colors.piechartColor38,
    colors.piechartColor39,
    colors.piechartColor40,
    colors.piechartColor41,
    colors.piechartColor42,
    colors.piechartColor43,
    colors.piechartColor44,
    colors.piechartColor45,
    colors.piechartColor46,
    colors.piechartColor47,
    colors.piechartColor48,
    colors.piechartColor49,
  ],
  promotionStatsCount: [
    colors.piechartColor43,
    colors.piechartColor53,
  ],
  promotionStatsAmount: [
    colors.barChartColor1,
    colors.barChartColor2,
  ],
};

export const DIVIDED_BAR_CHART_COLORS = {
  provisionClasses: [
    colors.barChartColor1,
    colors.barChartColor2,
    colors.barChartColor3,
    colors.piechartColor23,
    colors.barChartColor4,
    colors.barChartColor5,
    colors.barChartColor6,
  ],
  denialReasonsClasses: [
    colors.barChartColor7,
    colors.barChartColor8,
    colors.barChartColor9,
  ],
  financeProfitClasses: [
    colors.barChartColor4,
    colors.barChartColor8,
  ],
};

// Date format
export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_FORMAT_MONTH = "D MMM YYYY";
export const NEW_DATE_FORMAT = "YYYY-MM-DD";
export const INVOICE_DATE_FORMAT = "DD MMM YYYY";

// Time format
export const TIME_FORMAT = "hh:mm A";

export const FILE_STATUS = {
  PROCESSED: "PROCESSED",
  PROCESSING: "PROCESSING",
  FAILED: "FAILED",
};

// Date-Time format
export const DATE_TIME_FORMAT = "dddd DD MMM YYYY | h:mm a";
export const CONFIGURATION_DATE_TIME_FORMAT = "DD MMM YYYY | h:mm a";
export const REPORT_DATE_TIME_FORMAT = "DD MMM YYYY | HH:mm ";
export const FILE_UPLOAD_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSZ";
export const EMPTY_STRING = "";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZZ";

export const DEFAULT_DURATION = {
  fromDate: moment().subtract(7, "days").format(DEFAULT_DATE_FORMAT),
  toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
};

export const PROFILE_PHOTO_FILE_SIZE = 2048000;
export const MOTORCYCLE_FILE_SIZE = 5120000;
export const PROMO_IMAGE_FILE_SIZE = 5120000;
export const TRANSFER_RECEIPT_FILE_SIZE = 5120000;

export const CONFIGURATION_MAPPING_FIELDS = {
  BIRTH_DATE: "BIRTHDATE",
  COUNTRY: "NATIONALITY",
  STAY_IN_SPAIN: "SPAIN_STAY",
  EMPLOYMENT_SITUATION: "INCOME_SOURCE",
  JOB_TIME: "JOB_TIME",
  TOTAL_INCOME: "INCOME_LEVEL",
  PAYMENT_TOTAL_INCOME: "PAYMENT_TOTAL_INCOME",
  PAYMENT_INCOME_MINUS_EXPENSES: "PAYMENT_AVAILABLE_INCOME",
  PAYMENT_OTHER_LOAN: "PAYMENT_OTHER_LOAN_PAYMENTS",
  ADDRESS_VALIDATION: "ADDRESS_VALIDATION",
  VALIDATION_BY_REFERENCE: "VALIDATION_BY_REFERENCE",
  REFERENCE1_VALIDATION: "REFERENCE_1",
  REFERENCE2_VALIDATION: "REFERENCE_2",
};

// File configurations for upload documents
export const FILE_CONFIGURATIONS = {
  size: 52428800,
  allowedExtensions: ["jpg", "jpeg", "png", "pdf"],
  allowedImageExtensions: ["jpg", "jpeg", "png"],
};

export const CONFIGURATION_STATUS = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  drafted: "DRAFTED",
};

export const CONFIG_ACTIONS = {
  activate: "activate",
  getActive: "get active",
  getDraft: "get draft",
  deleteDraft: "delete draft",
  reset: "reset",
  saveAsDraft: "saveAsDraft",
  goback: "goback",
};

export const PAYMENT_STATUS = {
  PAID: "PAID",
  PARTIALLY_PAID: "PARTIALY_PAID",
  DUE: "DUE",
  PAST_DUE: "PAST_DUE",
};

export const USER_ROLES_WITH_RATINGS = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
  USER_ROLES.collectionsOfficer,
];

export const USER_ROLES_WITH_BRANCH = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
  USER_ROLES.collectionsOfficer,
];

export const USER_ROLES_WITH_SINGLE_BRANCH = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
];

export const USER_ROLES_TO_SHOW_BRANCH = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
  USER_ROLES.collectionsOfficer,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.salesModerator,
  USER_ROLES.messengerModerator,
  USER_ROLES.admin,
  USER_ROLES.configurator,
  USER_ROLES.loanPortfolioManager,
  USER_ROLES.generalManager,
  USER_ROLES.accountingOfficer,
  USER_ROLES.analyst,
  USER_ROLES.operationsOfficer,
  USER_ROLES.operationsModerator,
  USER_ROLES.analystModerator,
  USER_ROLES.auditor,
  USER_ROLES.collectionsManager,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.administrativeManager,
  USER_ROLES.customerService,
  USER_ROLES.operationsManager,
  USER_ROLES.salesManager,
  USER_ROLES.technology
];

export const USER_ROLES_NOT_ALLOWED_TO_CHANGE_BRANCHES = [
  USER_ROLES.salesModerator,
  USER_ROLES.messengerModerator,
  USER_ROLES.admin,
  USER_ROLES.configurator,
  USER_ROLES.loanPortfolioManager,
  USER_ROLES.generalManager,
  USER_ROLES.accountingOfficer,
  USER_ROLES.accountsOfficer,
  USER_ROLES.analyst,
  USER_ROLES.operationsOfficer,
  USER_ROLES.operationsModerator,
  USER_ROLES.analystModerator,
  USER_ROLES.auditor,
  USER_ROLES.collectionsManager,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.administrativeManager,
  USER_ROLES.customerService,
  USER_ROLES.operationsManager,
  USER_ROLES.salesManager,
  USER_ROLES.technology
];

export const USER_ROLES_WITH_REPORTING = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
  USER_ROLES.collectionsOfficer,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.collectionsManager
];
export const SPECIAL_USER_ROLES = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
];

export const USER_ROLES_WITH_COLLECTION_OFFICER = [
  USER_ROLES.messenger,
];

export const USER_ROLES_WITH_POSITION = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
];

export const USER_ROLES_FOR_WORKLOAD = {
  admin: [
    USER_ROLES.messenger,
    USER_ROLES.salesPerson,
    USER_ROLES.collectionsOfficer,
  ],
  messengerModerator: [USER_ROLES.messenger],
  salesModerator: [USER_ROLES.salesPerson],
  collectionsSupervisor: [USER_ROLES.collectionsOfficer],
};

export const USER_ROLES_TO_ENABLE_CLOSE_LOAN = [
  USER_ROLES.generalManager,
  USER_ROLES.admin,
  USER_ROLES.operationsModerator,
  USER_ROLES.operationsManager
];

export const DEFAULT_TYPE_OF_CUSTOMER_SELECTION = {
  key: "",
  label: Strings("selectTypeOfCustomer"),
  value: "",
};

export const TYPE_OF_CUSTOMER = [
  {
    key: 0,
    label: Strings("Prospecto"),
    value: "NEW",
  },
  {
    key: 1,
    label: Strings("Active"),
    value: "ACTIVE",
  },
  {
    key: 2,
    label: Strings("UpForRenewal"),
    value: "UP_FOR_RENEWAL",
  },
  {
    key: 3,
    label: Strings("Historical"),
    value: "HISTORICAL",
  },
];

export const MINIMART_MANAGEMENT_TYPE_OF_CUSTOMER = [
  {
    key: 1,
    label: Strings("Active"),
    value: "ACTIVE",
  },
  {
    key: 2,
    label: Strings("UpForRenewal"),
    value: "UP_FOR_RENEWAL",
  },
  {
    key: 3,
    label: Strings("Historical"),
    value: "HISTORICAL",
  },
];

export const DEFAULT_GRADE_SELECTION = {
  key: "",
  label: Strings("selectMiniMartGrade"),
  value: Strings("selectMiniMartGrade"),
};

export const MINIMART_GRADE = [
  DEFAULT_GRADE_SELECTION,
  {
    key: 0,
    label: "A",
    value: "A",
  },
  {
    key: 1,
    label: "B",
    value: "B",
  },
  {
    key: 2,
    label: "C",
    value: "C",
  },
  {
    key: 3,
    label: "D",
    value: "D",
  },
];

export const DEFAULT_RATING_SELECTION = {
  key: "",
  label: Strings("userRating"),
  value: "",
};

export const USER_RATING = [
  DEFAULT_RATING_SELECTION,
  {
    key: 1,
    value: "1",
    label: "1",
  },
  {
    key: 2,
    value: "2",
    label: "2",
  },
  {
    key: 3,
    value: "3",
    label: "3",
  },
  {
    key: 4,
    value: "4",
    label: "4",
  },
  {
    key: 5,
    value: "5",
    label: "5",
  },
];

export const MINIMART_TABS = {
  ADMIN: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("collectPayment") },
    // { key: 4, value: Strings("rewardHistory") },
    // { key: 5, value: Strings("Promotions") },
  ],
  GENERAL_MANAGER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    { key: 4, value: Strings("collectPayment") },
    // { key: 4, value: Strings("rewardHistory") },
    // { key: 5, value: Strings("Promotions") },
  ],
  SALES_MODERATOR: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
  ],
  MESSENGER_MODERATOR: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
  ],
  OPERATIONS_OFFICER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
  ],
  OPERATIONS_MODERATOR: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
  ],
  COLLECTIONS_OFFICER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("PaymentReceipts") },
  ],
  ACCOUNTING_OFFICER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
  ],
  AUDITOR: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
  ],
  ANALYST: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },

  ],
  ALL: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    { key: 4, value: Strings("collectPayment") },
    // { key: 4, value: Strings("rewardHistory") },
  ],
  COLLECTIONS_MANAGER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
    { key: 4, value: Strings("collectPayment") },
  ],
  COLLECTIONS_SUPERVISOR: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
    { key: 4, value: Strings("collectPayment") },
  ],
  ADMINISTRATIVE_MANAGER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
  ],
  CUSTOMER_SERVICE: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
  ],
  OPERATIONS_MANAGER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
  ],
  SALES_MANAGER: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
  ],
  TECHNOLOGY: [
    {
      key: 0,
      value: Strings("Invoices"),
    },
    {
      key: 1,
      value: Strings("Loans"),
    },
    { key: 2, value: Strings("creditLimit") },
    { key: 3, value: Strings("PaymentReceipts") },
    // { key: 4, value: Strings("rewardHistory") },
  ],

};

export const DAILY_STATUS_TABS = [
  {
    key: 0,
    value: Strings("SALES_PERSON"),
  },
  {
    key: 1,
    value: Strings("Messenger"),
  },
];

export const LOAN_CHART_TABS = [
  {
    key: 0,
    value: Strings("Loans"),
    index: 0,
  },
  {
    key: 1,
    value: Strings("applications"),
    index: 1,
  },
  {
    key: 2,
    value: Strings("Provision"),
    index: 2,
  },
  {
    key: 3,
    value: Strings("denialReasons"),
    index: 3,
  },
  {
    key: 4,
    value: Strings("Finance"),
    index: 4,
  },
];

export const WORKLOAD_MANAGEMENT_TABS = [
  {
    key: 0,
    value: Strings("FromPerson"),
  },
  {
    key: 1,
    value: Strings("ToPerson"),
  },
];

export const ASSIGNMENT_TYPE = [
  { name: Strings("Daily"), id: 1 },
  { name: Strings("Permanent"), id: 2 },
  { name: Strings("All"), id: 3 },
];

export const G_API_URL = "AIzaSyBtxEAblTPmm4qG67YMJvk_2VUrtsMaFfQ";

export const DEFAULT_INVOICE_STATUS = "selectInvoiceStatus";

export const DEFAULT_INVOICE_STATUS_SELECTION = {
  key: 0,
  value: "selectInvoiceStatus",
  label: Strings(DEFAULT_INVOICE_STATUS),
};

export const STATUS_FOR_INVOICES = [
  {
    key: 0,
    value: Strings(DEFAULT_INVOICE_STATUS_SELECTION.value),
    label: Strings(DEFAULT_INVOICE_STATUS_SELECTION.value),
  },
  {
    key: 1,
    value: "NEW",
    label: Strings("New"),
  },
  {
    key: 2,
    value: "ASSIGNED",
    label: Strings("Assigned"),
  },
  {
    key: 3,
    value: "ACTIVE",
    label: Strings("Active"),
  },
  {
    key: 4,
    value: "IN_PROGRESS",
    label: Strings("InProgress"),
  },
  {
    key: 5,
    value: "REJECTED",
    label: Strings("Rejected"),
  },
  {
    key: 6,
    value: "EXPIRED",
    label: Strings("Expired"),
  },
  {
    key: 7,
    label: Strings("NO_ACTION"),
    value: "NO_ACTION",
  },
];
export const INVOICE_STATUSES = [
  {
    key: 0,
    value: DEFAULT_INVOICE_STATUS_SELECTION.value,
    label: Strings(DEFAULT_INVOICE_STATUS_SELECTION.value),
  },
  {
    key: 1,
    value: "NEW",
    label: Strings("New"),
  },
  {
    key: 2,
    value: "ASSIGNED",
    label: Strings("Assigned"),
  },
  {
    key: 3,
    value: "ACTIVE",
    label: Strings("Active"),
  },
  {
    key: 4,
    value: "IN_PROGRESS",
    label: Strings("InProgress"),
  },
  {
    key: 5,
    value: "REJECTED",
    label: Strings("Rejected"),
  },
  {
    key: 6,
    value: "READY_TO_DISBURSE",
    label: Strings("READY_TO_DISBURSE"),
  },
  {
    key: 7,
    label: Strings("NO_ACTION"),
    value: "NO_ACTION",
  },
];

export const MINIMART_GRADE_LEGEND = ["A", "B", "C", "D"];

export const MINIMART_TYPE_LEGEND = [
  {
    key: "Prospecto",
    color: colors.piechartColor5,
    value: "NEW",
  },
  {
    key: "Activo",
    color: colors.piechartColor1,
    value: "ACTIVE",
  },
  {
    key: "HISTORICAL",
    color: colors.piechartColor6,
    value: "HISTORICAL",
  },
  {
    key: "UP_FOR_RENEWAL",
    color: colors.piechartColor7,
    value: "UP_FOR_RENEWAL",
  },
];

export const DASHBOARD_MINIMART_TYPE_LEGEND = [
  {
    key: "Activo",
    color: colors.piechartColor1,
    value: "ACTIVE",
  },
  {
    key: "HISTORICAL",
    color: colors.piechartColor6,
    value: "HISTORICAL",
  },
  {
    key: "UP_FOR_RENEWAL",
    color: colors.piechartColor7,
    value: "UP_FOR_RENEWAL",
  },
];

export const MINIMART_MANAGEMENT_TYPE_LEGEND = [
  {
    key: "Activo",
    color: colors.piechartColor1,
    value: "ACTIVE",
  },
  {
    key: "HISTORICAL",
    color: colors.piechartColor6,
    value: "HISTORICAL",
  },
  {
    key: "UP_FOR_RENEWAL",
    color: colors.piechartColor7,
    value: "UP_FOR_RENEWAL",
  },
];

export const PROMOTION_REQUEST_TYPE_LEGEND = [
  {
    key: "APPROVED",
    color: colors.legendColor2,
    value: "APPROVED",
  },
  {
    key: "REJECTED",
    color: colors.legendColor1,
    value: "REJECTED",
  },
  {
    key: "PENDING",
    color: colors.legendColor3,
    value: "PENDING",
  },
];

export const REWARD_TYPE_LEGEND = [
  {
    key: "SPENT",
    value: "SPENT",
    color: colors.legendColor3,
  },
  {
    key: "EARNED",
    value: "EARNED",
    color: colors.legendColor2,
  },
];

export const TYPE_OF_MINIMART = [
  "NEW",
  "ACTIVE",
  "UP_FOR_RENEWAL",
  "HISTORICAL",
];

export const INVOICE_LEGENDS = ["LoanTaken", "Rejected"];

export const PROMOTION_LEGENDS = ["DISCOUNT", "INSTALLMENT"];

export const LOAN_APPLICATION_LEGENDS = [
  "CREATED",
  "INCOMPLETE",
  "APPROVED",
  "DISMISSED",
  "ANALYSIS",
  "REJECTED_BY_SYSTEM",
  "DISBURSED",
];

export const LOAN_STATUS_LEGENDS = [
  "Active",
  "LEGAL",
  "W_OFF",
  "CLOSED",
  "DISAPPEARED",
  "PAYMENT_AGREEMENT",
];

export const AMOUNT_COLLECTION_STATUS = [
  // "amountToBeCollected",
  "amountCollected",
  "PastDues",
  "Dues",
];

export const DAILY_STATUS_SALES_PERSON_LEGENDS = [
  { key: "visited", value: "visitedMinimarts" },
  { key: "tobeVisited", value: "tobeVisited" },
];

export const DAILY_STATUS_MESSENGER_LEGENDS_CONST = {
  ARREARS_AMOUNT: "arrearsAmount",
  PENDING_AMOUNT: "pendingAmount",
  PAST_DUE_COLLECTION: "pastDueCollection",
  DUE_COLLECTION: "dueCollection",
};

export const DAILY_STATUS_MESSENGER_LEGENDS = [
  DAILY_STATUS_MESSENGER_LEGENDS_CONST.ARREARS_AMOUNT,
  DAILY_STATUS_MESSENGER_LEGENDS_CONST.PENDING_AMOUNT,
  DAILY_STATUS_MESSENGER_LEGENDS_CONST.PAST_DUE_COLLECTION,
  DAILY_STATUS_MESSENGER_LEGENDS_CONST.DUE_COLLECTION,
];

export const MESSENGER_LOAN_LEGENDS_CONST = {
  COLLECTED_LOANS: "collectedLoans",
  DENIED_LOANS: "deniedLoans",
  PENDING_LOANS: "pendingLoans",
};

export const MESSENGER_LOAN_LEGENDS = [
  MESSENGER_LOAN_LEGENDS_CONST.COLLECTED_LOANS,
  MESSENGER_LOAN_LEGENDS_CONST.DENIED_LOANS,
  MESSENGER_LOAN_LEGENDS_CONST.PENDING_LOANS,
];
export const DAILY_STATUS_SALES_PERSON_LOAN_LEGEND = [
  "createdLoanCount",
  "disbursedLoanCount",
  "deniedLoans",
  "pendingLoans",
];

export const RATING_CONSTANTS = [
  {
    key: "",
    value: Strings("selectRatings"),
    label: Strings("selectRatings"),
  },
  {
    key: 0,
    value: 0,
    label: "0",
  },
  {
    key: 1,
    value: 1,
    label: 1,
  },
  {
    key: 2,
    value: 2,
    label: 2,
  },
  {
    key: 3,
    value: 3,
    label: 3,
  },
  {
    key: 4,
    value: 4,
    label: 4,
  },
  {
    key: 5,
    value: 5,
    label: 5,
  },
];

export const VISIT_STATUS = [
  { key: 0, value: Strings("selectVisitStatus"), label: Strings("selectVisitStatus") },
  { key: 1, value: "true", label: Strings("visited") },
  { key: 2, value: "false", label: Strings("tobeVisited") },
];

export const DAILY_STATUS_MINIMART_CARD = {
  miniMartImage: "profileUrl",
  category: "category",
  drawnAmount: "drawnAmount",
  drawnLimit: "drawnLimit",
  matricesId: "id",
  visitStatus: "visitStatus",
  id: "code",
  miniMartName: "name",
  loan: "isLoanRequired",
  type: "type",
  loanRequested: "generatedLoansCount",
  visitedAt: "visitedAt",
};

export const PIE_CHART_MESSENGER_KEYS = {
  amountArray: [
    DAILY_STATUS_MESSENGER_LEGENDS_CONST.DUE_COLLECTION,
    DAILY_STATUS_MESSENGER_LEGENDS_CONST.PAST_DUE_COLLECTION,
    DAILY_STATUS_MESSENGER_LEGENDS_CONST.ARREARS_AMOUNT,
    DAILY_STATUS_MESSENGER_LEGENDS_CONST.PENDING_AMOUNT,
  ],
  loanArray: [
    MESSENGER_LOAN_LEGENDS_CONST.COLLECTED_LOANS,
    MESSENGER_LOAN_LEGENDS_CONST.DENIED_LOANS,
    MESSENGER_LOAN_LEGENDS_CONST.PENDING_LOANS,
  ],
};

export const OTHER_DATA_MESSENGER_KEYS = [
  "amountToBeCollected",
  "depositedAmount",
  "amountInstransit",
  "totalAmount",
  "extraAmount",
  "totalAssignedMinimarts",
  "visitedMinimarts",
];

export const DEFAULT_LOAN_STATUS = "selectLoanStatus";
export const DEFAULT_REMITTANCE_STATUS = "selectRemittanceStatus";

export const DEFAULT_LOAN_STATUS_SELECTION = {
  key: 0,
  value: "selectLoanStatus",
  label: Strings(DEFAULT_LOAN_STATUS),
};

export const DEFAULT_REMITTANCE_LOAN_STATUS_SELECTION = {
  key: 0,
  value: DEFAULT_REMITTANCE_STATUS,
  label: Strings(DEFAULT_REMITTANCE_STATUS),
};

export const STATUS_FOR_LOANS = [
  {
    key: 0,
    value: DEFAULT_LOAN_STATUS_SELECTION.value,
    label: Strings(DEFAULT_LOAN_STATUS_SELECTION.value),
  },
  {
    key: 1,
    value: "CREATED",
    label: Strings("Created"),
  },
  {
    key: 2,
    value: "INCOMPLETE",
    label: Strings("Incomplete"),
  },
  {
    key: 3,
    value: "APPROVED",
    label: Strings("Approved"),
  },
  {
    key: 4,
    value: "DISMISSED",
    label: Strings("Dismissed"),
  },
  {
    key: 5,
    value: "ANALYSIS",
    label: Strings("Analysis"),
  },
  {
    key: 6,
    value: "DISBURSED",
    label: Strings("Disbursed"),
  },
  {
    key: 7,
    value: "REJECTED_BY_SYSTEM",
    label: Strings("Rejected"),
  },
];

export const REMITTANCE_STATUS_FOR_LOANS = [
  {
    key: 0,
    value: DEFAULT_REMITTANCE_LOAN_STATUS_SELECTION.value,
    label: Strings(DEFAULT_REMITTANCE_LOAN_STATUS_SELECTION.value),
  },
  {
    key: 1,
    value: "CREATED",
    label: Strings("Created"),
  },
  {
    key: 2,
    value: "INCOMPLETE",
    label: Strings("Incomplete"),
  },
  {
    key: 3,
    value: "APPROVED",
    label: Strings("Approved"),
  },
  {
    key: 4,
    value: "DISMISSED",
    label: Strings("Dismissed"),
  },
  {
    key: 5,
    value: "ANALYSIS",
    label: Strings("Analysis"),
  },
  {
    key: 6,
    value: "DISBURSED",
    label: Strings("Disbursed"),
  },
  {
    key: 7,
    value: "REJECTED_BY_SYSTEM",
    label: Strings("Rejected"),
  },
];

export const SPED_STATUS_FOR_LOANS = [
  {
    key: 0,
    value: DEFAULT_LOAN_STATUS_SELECTION.value,
    label: Strings(DEFAULT_LOAN_STATUS_SELECTION.value),
  },
  {
    key: 1,
    value: "INCOMPLETE",
    label: Strings("Incomplete"),
  },
  {
    key: 2,
    value: "DISMISSED",
    label: Strings("Dismissed"),
  },
  {
    key: 3,
    value: "ANALYSIS",
    label: Strings("Analysis"),
  },
  {
    key: 4,
    value: "DISBURSED",
    label: Strings("Disbursed"),
  },
  {
    key: 5,
    value: "REJECTED_BY_SYSTEM",
    label: Strings("Rejected"),
  },
];

export const DEFAULT_HISTORY_MINIMART_STATUS = "selectMinimartStatus";

export const DEFAULT_HISTORY_MINIMART_STATUS_SELECTION = {
  key: 0,
  value: "selectMinimartStatus",
  label: Strings(DEFAULT_HISTORY_MINIMART_STATUS),
};

export const STATUS_FOR_HISTORY_MINIMART = [
  DEFAULT_HISTORY_MINIMART_STATUS_SELECTION,
  {
    key: 1,
    value: "APPROVED",
    label: Strings("Approved"),
  },
  {
    key: 2,
    value: "REJECTED",
    label: Strings("Rejected"),
  },
];

export const DEFAULT_PROVISION_GRADE = "selectProvisionGrade";

export const DEFAULT_PROVISION_GRADE_SELECTION = {
  key: 0,
  value: "selectProvisionGrade",
  label: Strings(DEFAULT_PROVISION_GRADE),
};

export const PROVISION_GRADES = [
  {
    key: 0,
    value: DEFAULT_PROVISION_GRADE_SELECTION.value,
    label: Strings(DEFAULT_PROVISION_GRADE_SELECTION.value),
  },
  {
    key: 1,
    value: "A",
    label: Strings("A"),
  },
  {
    key: 2,
    value: "B",
    label: Strings("B"),
  },
  {
    key: 3,
    value: "C",
    label: Strings("C"),
  },
  {
    key: 4,
    value: "D1",
    label: Strings("D1"),
  },
  {
    key: 5,
    value: "D2",
    label: Strings("D2"),
  },
  {
    key: 6,
    value: "E",
    label: Strings("E"),
  },
];

export const LOAN_CARD = {
  LEFT: {
    loanImage: "loanImage",
    status: "status",
    category: "category",
    loanId: "loanId",
    id: "applicationId",
  },
  RIGHT: {
    id: "applicationId",
    name: "minimartName",
    amount: "loanAmount",
    lateFeesAmount: "lateFeesAmount",
    DisbursementDate: "disbursementDate",
    typeOfLoan: "loanType",
    ticketId: "ticketId",
    approvedAmount: "approvedAmount",
    analystName: "analystName",
  },
};

export const LOAN_STATUS_LEGEND = [
  {
    key: "CREATED",
    color: colors.piechartColor14,
  },
  {
    key: "APPROVED",
    color: colors.piechartColor16,
  },
  {
    key: "ANALYSIS",
    color: colors.piechartColor17,
  },
  {
    key: "DISBURSED",
    color: colors.piechartColor18,
  },
  {
    key: "INCOMPLETE",
    color: colors.piechartColor19,
  },
  {
    key: "DISMISSED",
    color: colors.piechartColor20,
  },
  {
    key: "REJECTED_BY_SYSTEM",
    color: colors.piechartColor21,
  },
  {
    key: "INPROCESS_ANALYSIS",
    color: colors.piechartColor17,
  },
  {
    key: "INPROCESS_OPERATIONS",
    color: colors.piechartColor29,
  },
  {
    key: "READY_TO_DISBURSE",
    color: colors.piechartColor18,
  },
  {
    key: "INPROCESS_ACCOUNTS",
    color: colors.piechartColor28,
  },
  { key: "REJECTED_BY_ANALYST", color: colors.piechartColor30 },
  {
    key: "REJECTED_BY_OPERATIONS_OFFICER",
    color: colors.piechartColor30,
  },
  { key: "READY_TO_SIGN", color: colors.piechartColor1 },
  { key: "READY_FOR_COLLECTION", color: colors.piechartColor1 },

  {
    key: "READY_TO_APPROVE_CREDIT_LIMIT",
    color: colors.piechartColor31,
  },
];

export const LOAN_HISTORY_STATUS_LEGEND = [
  {
    key: "ANALYSIS",
    color: colors.piechartColor17,
  },
  {
    key: "INPROCESS_OPERATIONS",
    color: colors.piechartColor17,
  },
  {
    key: "INPROCESS_ANALYSIS",
    color: colors.piechartColor17,
  },
  {
    key: "READY_TO_DISBURSE",
    color: colors.piechartColor17,
  },
  {
    key: "READY_TO_SIGN",
    color: colors.piechartColor17,
  },
  {
    key: "READY_FOR_COLLECTION",
    color: colors.piechartColor17,
  },
  {
    key: "READY_TO_APPROVE_CREDIT_LIMIT",
    color: colors.piechartColor17,
  },
  {
    key: "INPROCESS_ACCOUNTS",
    color: colors.piechartColor17,
  },
  {
    key: "DISBURSED",
    color: colors.piechartColor18,
  },
  {
    key: "INCOMPLETE",
    color: colors.piechartColor19,
  },
  {
    key: "DISMISSED",
    color: colors.piechartColor20,
  },
  {
    key: "REJECTED_BY_SYSTEM",
    color: colors.piechartColor21,
  },
  {
    key: "REJECTED_BY_ANALYST",
    color: colors.piechartColor21,
  },
  {
    key: "REJECTED_BY_OPERATIONS_OFFICER",
    color: colors.piechartColor21,
  },
];

export const GENERAL_LOAN_STATUS_LEGEND = [
  {
    key: "CREATED",
    color: colors.piechartColor14,
  },
  {
    key: "APPROVED",
    color: colors.piechartColor16,
  },
  {
    key: "ANALYSIS",
    color: colors.piechartColor17,
  },
  {
    key: "DISBURSED",
    color: colors.piechartColor18,
  },
  {
    key: "DISMISSED",
    color: colors.piechartColor20,
  },
  {
    key: "REJECTED_BY_SYSTEM",
    color: colors.piechartColor21,
  },
  {
    key: "INCOMPLETE",
    color: colors.piechartColor19,
  },
];
export const LOAN_HISTORY_LEGENDS = [
  {
    key: "ANALYSIS",
    color: colors.piechartColor17,
  },
  {
    key: "DISBURSED",
    color: colors.piechartColor18,
  },
  {
    key: "DISMISSED",
    color: colors.piechartColor20,
  },
  {
    key: "REJECTED_BY_SYSTEM",
    color: colors.piechartColor21,
  },
  {
    key: "INCOMPLETE",
    color: colors.piechartColor19,
  },
];
export const LOAN_CATEGORY_LEGEND = [
  {
    key: "Active",
    color: colors.piechartColor10,
    value: "ACTIVE",
  },
  {
    key: "LEGAL",
    color: colors.piechartColor11,
    value: "LEGAL",
  },
  {
    key: "W_OFF",
    color: colors.piechartColor12,
    value: "W_OFF",
  },
  {
    key: "CLOSED",
    color: colors.piechartColor13,
    value: "CLOSED",
  },
  {
    key: "DISAPPEARED",
    color: colors.piechartColor54,
    value: "DISAPPEARED",
  },
  {
    key: "PAYMENT_AGREEMENT",
    color: colors.piechartColor55,
    value: "PAYMENT_AGREEMENT",
  },
];
export const HISTORY_MINIMART_STATUS_ON_CARD = [
  {
    key: "APPROVED",
    color: colors.piechartColor12,
  },
  {
    key: "INPROCESS_ANALYSIS",
    color: colors.piechartColor17,
  },
  {
    key: "REJECTED",
    color: colors.piechartColor21,
  },
  {
    key: "CONFIRMED",
    color: colors.piechartColor18,
  },
  {
    key: "CI_CREATED",
    color: colors.piechartColor6,
  },
];

export const HISTORY_MINIMART_LEGEND = [
  {
    key: "APPROVED",
    color: colors.piechartColor12,
  },
  {
    key: "REJECTED",
    color: colors.piechartColor21,
  },
];

export const DEFAULT_LOAN_TYPE = Strings("SelectLoanType");

export const LOAN_TYPE = [
  {
    key: 0,
    value: Strings("SelectLoanType"),
    label: Strings("SelectLoanType"),
  },
  {
    key: 1,
    value: "FACTORING",
    label: Strings("Factoring"),
  },
  {
    key: 2,
    value: "CASH_TOPUP",
    label: Strings("cashTopUp"),
  },
  {
    key: 3,
    value: "CONSUMER",
    label: Strings("Consumer"),
  },
  {
    key: 4,
    value: "EXTERNAL_FACTORING",
    label: Strings("externalFactoring"),
  },
  {
    key: 5,
    value: "TU_PRESTAMO",
    label: Strings("tuPrestamo"),
  },
  {
    key: 6,
    value: "MOTORCYCLE",
    label: Strings("motorcycleLoan"),
  },
  {
    key: 7,
    value: "FAST_CASH",
    label: Strings("fastCash"),
  },
];

export const MINIMART_TYPE = [
  {
    key: 0,
    value: "ALL",
    label: Strings("allMinimarts"),
  },
  {
    key: 1,
    value: "CI",
    label: Strings("CIMinimarts"),
  },
  {
    key: 2,
    value: "NON_CI",
    label: Strings("otherMinimarts"),
  },
];

export const MINIMART_STATUSES = [
  {
    key: 0,
    value: Strings("selectStatus"),
    label: Strings("selectStatus"),
  },
  {
    key: 1,
    value: "ACTIVE",
    label: Strings("ACTIVE"),
  },
  {
    key: 2,
    value: "BLOCKED",
    label: Strings("BLOCKED"),
  },
  {
    key: 3,
    value: "BARRED",
    label: Strings("BARRED"),
  },
];

export const DEFAULT_CATEGORY_TYPE = Strings("SelectCategory");

export const CATEGORY = [
  {
    key: 0,
    value: Strings("SelectCategory"),
    label: Strings("SelectCategory"),
  },
  {
    key: 1,
    value: "ACTIVE",
    label: Strings("Active"),
  },
  {
    key: 2,
    value: "LEGAL",
    label: Strings("Legal"),
  },
  {
    key: 3,
    value: "W_OFF",
    label: Strings("W_OFF"),
  },
  {
    key: 4,
    value: "CLOSED",
    label: Strings("Closed"),
  },
  {
    key: 5,
    value: "DISAPPEARED",
    label: Strings("DISAPPEARED"),
  },
  {
    key: 6,
    value: "PAYMENT_AGREEMENT",
    label: Strings("PAYMENT_AGREEMENT"),
  },
];

export const LOAN_DETAILS_KEYS = {
  FACTORING: [
    { key: "ty", label: "type" },
    { key: "inv", label: "InvoiceNumber" },
    { key: "st", label: "status" },
    { key: "amt", label: "amountRequested" },
    { key: "noi", label: "termRequested" },
    { key: "emi", label: "emi" },
    { key: "rt", label: "interestRate" },
    { key: "disTs", label: "disbursedDate" },
    { key: "lca", label: "loanAgreement" },
    { key: "rfq", label: "requestedFrequency" },
    { key: "appfq", label: "approvedFrequency" },
    { key: "rcd", label: "requestedCollectionDays" },
    { key: "appCd", label: "approvedCollectionDays" },
  ],
  CASH_TOPUP: [
    { key: "ty", label: "type" },
    { key: "st", label: "status" },
    { key: "amt", label: "amountRequested" },
    { key: "noi", label: "termRequested" },
    { key: "appAmt", label: "approvedAmount" },
    { key: "appTerm", label: "approvedTerm" },
    { key: "emi", label: "emi" },
    { key: "rt", label: "interestRate" },
    { key: "disTs", label: "disbursedDate" },
    { key: "lca", label: "loanAgreement" },
    { key: "rfq", label: "requestedFrequency" },
    { key: "appfq", label: "approvedFrequency" },
    { key: "rcd", label: "requestedCollectionDays" },
    { key: "appCd", label: "approvedCollectionDays" },
  ],
  CONSUMER: [
    { key: "ty", label: "type" },
    { key: "pnm", label: "ProductName" },
    { key: "amt", label: "amountRequested" },
    { key: "st", label: "status" },
    { key: "emi", label: "emi" },
    { key: "noi", label: "termRequested" },
    { key: "rt", label: "interestRate" },
    { key: "disTs", label: "disbursedDate" },
    { key: "lca", label: "loanAgreement" },
    { key: "oid", label: "orderId" },
    { key: "rfq", label: "requestedFrequency" },
    { key: "appfq", label: "approvedFrequency" },
    { key: "rcd", label: "requestedCollectionDays" },
    { key: "appCd", label: "approvedCollectionDays" },
  ],
  EXTERNAL_FACTORING: [
    { key: "ty", label: "type" },
    { key: "st", label: "status" },
    { key: "amt", label: "amountRequested" },
    { key: "appAmt", label: "approvedAmount" },
    { key: "noi", label: "termRequested" },
    { key: "appTerm", label: "approvedTerm" },
    { key: "rt", label: "interestRate" },
    { key: "emi", label: "emi" },
    { key: "disTs", label: "disbursedDate" },
    { key: "lca", label: "loanAgreement" },
    { key: "rfq", label: "requestedFrequency" },
    { key: "appfq", label: "approvedFrequency" },
    { key: "tr", label: "transferReceipt" },
    { key: "ae", label: "administrativeExpense" },
    { key: "rcd", label: "requestedCollectionDays" },
    { key: "appCd", label: "approvedCollectionDays" },
  ],
  TU_PRESTAMO: [
    { key: "ty", label: "type" },
    { key: "st", label: "status" },
    { key: "amt", label: "amountRequested" },
    { key: "appAmt", label: "approvedAmount" },
    { key: "noi", label: "termRequested" },
    { key: "appTerm", label: "approvedTerm" },
    { key: "emi", label: "emi" },
    { key: "rt", label: "interestRate" },
    { key: "disTs", label: "disbursedDate" },
    { key: "lca", label: "loanAgreement" },
    { key: "rfq", label: "requestedFrequency" },
    { key: "appfq", label: "approvedFrequency" },
    { key: "rcd", label: "requestedCollectionDays" },
    { key: "appCd", label: "approvedCollectionDays" },
  ],
  MOTORCYCLE: [
    { key: "ty", label: "type" },
    { key: "pnm", label: "ProductName" },
    { key: "noi", label: "termRequested" },
    { key: "co", label: "productCost" },
    { key: "rt", label: "interestRate" },
    { key: "amt", label: "amountRequested" },
    { key: "emi", label: "emi" },
    { key: "st", label: "status" },
    { key: "disTs", label: "disbursedDate" },
    { key: "lca", label: "loanAgreement" },
    { key: "oid", label: "orderId" },
    { key: "rfq", label: "requestedFrequency" },
    { key: "appfq", label: "approvedFrequency" },
    { key: "rcd", label: "requestedCollectionDays" },
    { key: "appCd", label: "approvedCollectionDays" },
  ],
  FAST_CASH: [
    { key: "ty", label: "type" },
    { key: "amt", label: "amountRequested" },
    { key: "st", label: "status" },
    { key: "emi", label: "emi" },
    { key: "noi", label: "termRequested" },
    { key: "rt", label: "interestRate" },
    { key: "disTs", label: "disbursedDate" },
    { key: "lca", label: "loanAgreement" },
    { key: "rfq", label: "requestedFrequency" },
    { key: "appfq", label: "approvedFrequency" },
    { key: "rcd", label: "requestedCollectionDays" },
    { key: "appCd", label: "approvedCollectionDays" },
    { key: "tr", label: "transferReceipt" },
    { key: "ae", label: "administrativeExpense" },
  ],
};

export const DOWNPAYMENT_COLLECTION_KEYS = [
  { key: "dAmt", label: "collectedDownpayment" },
  { key: "dpr", label: "downpaymentReceipt" },
  { key: "dptri", label: "downpaymentTransferReceipt" },
  { key: "dppm", label: "paymentMode" },
  { key: "dpps", label: "paymentSource" },
]
export const LOAN_STATE_KEYS_CONST = {
  CT: "ct",
  LF_AMT: "lfAmt",
  LF_RT: "lfRt",
  BTD: "btd",
  PDB: "pdb",
  DIA: "dia",
  UPI: "upi",
  diao: "diao",
};
export const LOAN_STATE_KEYS = [
  { key: "ct", label: "category" },
  { key: "lfAmt", label: "lateFeesAmt" },
  { key: "lfRt", label: "lateFees" },
  { key: "alfcd", label: "appliedDaysInDelays" },
  { key: "amlfp", label: "appliedMaxPercentage" },
  { key: "btd", label: "balanceToDate" },
  { key: "pdb", label: "pastDueBalance" },
  { key: "dia", label: "arrearInstallments" },
  { key: "upi", label: "unPaidInterest" },
  { key: "diao", label: "daysInArrears" },
];

export const PROVISION_KEYS = [
  { key: "ct", label: "category" },
  { key: "amt", label: "amt" },
  { key: "rt", label: "percent" },
];

export const ADMINISTRATIVE_EXPENSE_KEYS = [
  { key: "amountCharged", label: "amountCharged" },
  { key: "amountPaid", label: "amountPaid" },
  { key: "receiptIds", label: "receiptIds" },
  { key: "transferReceiptIds", label: "transferReceipt" },
  { key: "paymentModes", label: "paymentModes" },
  { key: "paymentSources", label: "paymentSources" },
];

export const MINIMART_DETAILS_KEYS = [
  { key: "mid", label: "MatricesID", className: "" },
  { key: "cd", label: "code", className: "" },
  { key: "bty", label: "BusinessType", className: "" },
  { key: "rs", label: "registrationStatus", className: "" },
  { key: "mn", label: "name", className: "" },
  { key: "on", label: "ownerName", className: "address" },
  { key: "scr", label: "Sector", className: "" },
  { key: "srt", label: "Street", className: "" },
  { key: "no", label: "number", className: "" },
  { key: "ref", label: "Reference", className: "address" },
  { key: "ct", label: "City", className: "" },
  { key: "tp", label: "PhoneNumber", className: "" },
  { key: "otp", label: "MobileNumberOfOwner", className: "" },
];

export const NEW_MINIMART_DETAILS_KEYS = [
  { key: "cd", label: "code", className: "" },
  { key: "bty", label: "BusinessType", className: "" },
  { key: "rs", label: "registrationStatus", className: "" },
  { key: "mn", label: "name", className: "" },
  { key: "on", label: "ownerName", className: "address" },
  { key: "add", label: "address", className: "address" },
  { key: "tp", label: "PhoneNumber", className: "" },
  { key: "otp", label: "MobileNumberOfOwner", className: "" },
];

export const USERS_WITH_ACCESS_ON_LOAN_APPLICATION_ACTIONS = [
  USER_ROLES.collectionOfficer,
  USER_ROLES.collectionsManager,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.administrativeManager,
];
export const USERS_WITH_ACCESS_ON_LOAN_APPLICATION_REQUEST = [
  USER_ROLES.operationsModerator,
  USER_ROLES.generalManager,
  USER_ROLES.admin,
  USER_ROLES.collectionsManager,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.administrativeManager,
  USER_ROLES.technology,
];

export const USERS_WITH_ACCESS_NEW_PAYMENTPLAN = [
  USER_ROLES.collectionOfficer,
  USER_ROLES.operationsModerator,
  USER_ROLES.collectionsManager,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.administrativeManager,
  USER_ROLES.operationsManager
];

export const USERS_WITH_ACCESS_NEW_PAYMENTPLAN_REQUEST = [
  USER_ROLES.operationsModerator,
  USER_ROLES.generalManager,
  USER_ROLES.admin,
  USER_ROLES.collectionsManager,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.administrativeManager,
  USER_ROLES.technology,
  USER_ROLES.operationsManager
];
//ConfigurationConstants

export const DEFAULT_GRADE_CONFIGURATION = [
  { grade: "A", daysArrears: { lower: 0, upper: 0 } },
  { grade: "B", daysArrears: { lower: 0, upper: 0 } },
  { grade: "C", daysArrears: { lower: 0, upper: 0 } },
  { grade: "D", daysArrears: { lower: 0, upper: 0 } },
  { grade: "E", daysArrears: { lower: 0, upper: 0 } },
];

export const DEFAULT_ROW_DATA = {
  type: "",
  daysArrears_lower: 0,
  daysArrears_upper: 0,
  loanStatus: "",
  percentWithCollateral: 0.0,
  percentWithoutCollateral: 0.0,
};

export const DEFAULT_ROW_DATA2 = {
  provision: "",
  percentWithCollateral: 0.0,
  percentWithoutCollateral: 0.0,
};

export const DEFAULT_EMI_DATA = {
  duration: 0,
  rate: 0,
};

export const DEFAULT_EMI_AMOUNT_ADD_ROW = {
  lower: 0,
  upper: 0,
  rate: 0,
  terms: [],
};

export const DEFAULT_LOAN_STATUS_ADD_ROW = {
  lower: 0,
  upper: 0,
  rate: 0,
  terms: [],
};

export const DEFAULT_PROVISION_ROW = {
  A: 0,
  B: 0,
  C: 0,
  D1: 0,
  D2: 0,
  E: 0,
  term: 0,
};

export const DEFAULT_COMMISSION_SCHEME_DATA = {
  Type: "",
  Percentage: 0,
};

export const DEFAULT_ADMINISTRATIVE_EXPENSE_DATA = {
  lower: 0,
  upper: 0,
  amount: 0,
};

export const SINGLE_VALUED_CONFIGURATION = [
  "GRACE_PERIOD",
  "LOAN_PICKUP",
  "EARLY_CANCELLATION",
  "EARLY_PAYMENT",
  "META_ENABLED_STATUS",
  "CREDO_CONFIG"
];

export const MULTI_VALUED_CONFIGURATION = {
  LOAN_STATUS: "dayRange",
  PROVISION: "dayRange",
  GRADING_SCALE: "dayRange",
};

export const COLUMNS_FOR_CONFIGURATION = {
  EMI_AMOUNT: [
    {
      key: "lower",
      title: Strings("Amount(Lower)"),
      editable: true,
      dataType: "amount",
    },
    {
      key: "upper",
      title: Strings("Amount(Upper)"),
      editable: true,
      dataType: "amount",
    },
    {
      key: "rate",
      title: Strings("rate"),
      editable: true,
      dataType: "float",
    },
    {
      key: "terms",
      title: Strings("duration"),
      editable: true,
      dataType: "int",
    },
  ],
  EARLY_PAYMENT: [
    {
      key: "discountPerEarlyEMIPercent",
      title: Strings("discountPerEarlyEMIPercent"),
      editable: true,
      dataType: "float",
    },
  ],
  isMetaEnabled: [
    {
      key: "isMetaEnabled",
      title: Strings("metaFlag"),
      editable: true,
      dataType: "boolean",
    },
  ],
  EARLY_CANCELLATION: [
    {
      key: "earlyCancellationCharges",
      title: Strings("EarlyCancellationCharges"),
      editable: true,
      dataType: "float",
    },
  ],
  LOAN_PICKUP: [
    {
      key: "pickupFrequency",
      title: Strings("PickupFrequency"),
      editable: true,
      dataType: "int",
    },
  ],
  GRACE_PERIOD: [
    {
      key: "graceDays",
      title: Strings("graceDays"),
      editable: true,
      dataType: "int",
    },
  ],
  // LATE_FEES: [
  //   {
  //     key: "lateFeesPercent",
  //     title: Strings("lateFeesPercent"),
  //     editable: true,
  //     dataType: "float",
  //   },
  // ],
  LOAN_STATUS: [
    {
      key: "status",
      title: Strings("Status"),
      editable: false,
      dataType: "text",
    },
    {
      key: "lower",
      title: Strings("InstallmentArrearsFrom"),
      editable: true,
      dataType: "number",
    },
    {
      key: "upper",
      title: Strings("InstallmentArrearsTo"),
      editable: true,
      dataType: "number",
    },
  ],
  GRADING_SCALE: [
    {
      key: "grade",
      title: Strings("Grade"),
      editable: false,
      dataType: "text",
    },
    {
      key: "daysArrears_lower",
      title: Strings("ArrearsFrom"),
      editable: true,
      dataType: "number",
    },
    {
      key: "daysArrears_upper",
      title: Strings("ArrearsTo"),
      editable: true,
      dataType: "number",
    },
  ],
  COMMISSION_SCHEME: [
    {
      key: "grade",
      title: Strings("Grade"),
      editable: false,
      dataType: "text",
    },
    {
      key: "daysArrears_lower",
      title: Strings("ArrearsFrom"),
      editable: true,
      dataType: "number",
    },
    {
      key: "daysArrears_upper",
      title: Strings("ArrearsTo"),
      editable: true,
      dataType: "number",
    },
  ],
  USER_COMMISSION: [
    {
      key: "type",
      title: Strings("Type"),
      editable: false,
      dataType: "text",
    },
    {
      key: "percentage",
      title: Strings("Commission"),
      editable: true,
      dataType: "float",
    },
  ],
  PROVISION: [
    {
      key: "term",
      title: Strings("provision/terms"),
      editable: true,
      dataType: "text",
    },
    {
      key: "A",
      title: Strings("A"),
      editable: true,
      dataType: "number",
    },
    {
      key: "B",
      title: Strings("B"),
      editable: true,
      dataType: "number",
    },
    {
      key: "C",
      title: Strings("C"),
      editable: true,
      dataType: "number",
    },
    {
      key: "D1",
      title: Strings("D1"),
      editable: true,
      dataType: "number",
    },
    {
      key: "D2",
      title: Strings("D2"),
      editable: true,
      dataType: "number",
    },
    {
      key: "E",
      title: Strings("E"),
      editable: true,
      dataType: "float",
    },
  ],
  CREDO_CONFIG: [
    {
      key: "credoCallDays",
      title: Strings("credoCallDays"),
      editable: true,
      dataType: "int",
    },
  ],

  SECOND_PROVISION: [
    {
      key: "provisionType",
      title: Strings("provisionType"),
      editable: false,
      dataType: "text",
    },
    {
      key: "percentWithCollateral",
      title: Strings("PercentWithCollateral"),
      editable: true,
      dataType: "float",
    },
    {
      key: "percentWithoutCollateral",
      title: Strings("PercentWithoutCollateral"),
      editable: true,
      dataType: "float",
    },
  ],
  CIDATA_SCHEDULER: [
    {
      key: "hours",
      title: Strings("hours"),
      editable: true,
      dataType: "hours",
    },
    {
      key: "minutes",
      title: Strings("minutes"),
      editable: true,
      dataType: "minutes",
    },
    {
      key: "period",
      title: Strings("period"),
      editable: true,
      dataType: "text",
    },
  ],
  PAYMENT_DISCOUNT: [
    {
      key: "loanType",
      title: Strings("loanType"),
      editable: false,
      dataType: "text",
    },
    {
      key: "discount",
      title: Strings("discount"),
      editable: true,
      dataType: "float",
    },
  ],
  META_ENABLED_STATUS: [
    {
      key: "isMetaEnabled",
      title: Strings("isMetaEnabled"),
      editable: false,
      dataType: "boolean",
    }
  ],
  ADMINISTRATIVE_EXPENSE_DATA: [
    {
      key: "lower",
      title: Strings("Amount(Lower)"),
      editable: true,
      dataType: "amount",
    },
    {
      key: "upper",
      title: Strings("Amount(Upper)"),
      editable: true,
      dataType: "amount",
    },
    {
      key: "amount",
      title: Strings("administrativeExpense"),
      editable: true,
      dataType: "amount",
    }
  ],
};

export const translateStatusConstantToString = (status) => {
  let StatusConstant = {
    ACTIVE: Strings("Active"),
    LEGAL: Strings("Legal"),
    W_OFF: Strings("W_Off"),
    CLOSED: Strings("Closed"),
    DISAPPEARED: Strings("DISAPPEARED"),
    PAYMENT_AGREEMENT: Strings("PAYMENT_AGREEMENT"),
  };
  return StatusConstant[status];
};

export const translateTypeConstantToString = (type) => {
  let TypeConstant = {
    COLLECTION: Strings("COLLECTION"),
    LOAN: Strings("LOAN"),
    TPRESTA_LOAN: Strings("TPRESTA_LOAN"),
    TPRESTA_COLLECTION: Strings("TPRESTA_COLLECTION"),
  };
  return TypeConstant[type];
};

export const translateStringToStatusConstant = (statusString) => {
  switch (statusString) {
    case Strings("Active"):
      return "ACTIVE";
    case Strings("Legal"):
      return "LEGAL";
    case Strings("W_Off"):
      return "W_OFF";
    case Strings("Closed"):
      return "CLOSED";
    case Strings("DISAPPEARED"):
      return "DISAPPEARED";
    case Strings("PAYMENT_AGREEMENT"):
      return "PAYMENT_AGREEMENT";
    default:
      return statusString;
  }
};

export const LOAN_TYPE_FOR_EMI = [
  Strings("Factoring"),
  Strings("cashTopUp"),
  Strings("Consumer"),
  Strings("externalFactoring"),
  Strings("tuPrestamo"),
  Strings("motorcycleLoan"),
  Strings("fastCash"),
];

export const USER_COMMISSION_TYPES = [
  Strings("Messengers"),
  Strings("SalesPersons"),
  Strings("MESSENGER_MODERATOR"),
  Strings("SALES_MODERATOR"),
];

export const DAY_RANGE_VALIDATION = [
  // "LOAN_STATUS",
  "GRADING_SCALE",
  "PROVISION",
];

export const WORK_HISTORY_TABS = {
  GENERAL_MANAGER: [
    {
      key: 0,
      label: Strings("SALES_PERSON"),
      value: "SALES_PERSON",
    },
    {
      key: 1,
      label: Strings("Messenger"),
      value: "MESSENGER",
    }
  ],
  ADMIN: [
    {
      key: 0,
      label: Strings("SALES_PERSON"),
      value: "SALES_PERSON",
    },
    {
      key: 1,
      label: Strings("Messenger"),
      value: "MESSENGER",
    }
  ],
  SALES_MODERATOR: [
    {
      key: 0,
      label: Strings("SALES_PERSON"),
      value: "SALES_PERSON",
    },
  ],
  MESSENGER_MODERATOR: [
    {
      key: 0,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
  COLLECTIONS_OFFICER: [
    {
      key: 0,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
  AUDITOR: [
    {
      key: 0,
      label: Strings("SALES_PERSON"),
      value: "SALES_PERSON",
    },
    {
      key: 1,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
  COLLECTIONS_MANAGER: [
    {
      key: 0,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
  COLLECTIONS_SUPERVISOR: [
    {
      key: 0,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
  SALES_MANAGER: [
    {
      key: 0,
      label: Strings("SALES_PERSON"),
      value: "SALES_PERSON",
    }
  ],
  ADMINISTRATIVE_MANAGER: [
    {
      key: 0,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
  TECHNOLOGY: [
    {
      key: 0,
      label: Strings("SALES_PERSON"),
      value: "SALES_PERSON",
    },
    {
      key: 1,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
  OPERATIONS_MANAGER: [
    {
      key: 0,
      label: Strings("SALES_PERSON"),
      value: "SALES_PERSON",
    },
    {
      key: 1,
      label: Strings("Messenger"),
      value: "MESSENGER",
    },
  ],
};

export const MINIMART_RATING = {
  0: "F",
  1: "E",
  2: "D",
  3: "C",
  4: "B",
  5: "A",
};

const renderRater = ({ value }) => {
  return value !== "-" ? (
    <div>
      <Rater
        id={"SALES_PERSON"}
        title={value}
        rating={Number(value)}
        total={5}
        interactive={false}
        style={{
          fontSize: 15 + "px",
          color: "#3ea853",
        }}
      />
    </div>
  ) : (
    "-"
  );
};

export const TABLE_COLUMNS = {
  SALES_PERSON: [
    {
      key: "ts",
      name: Strings("Date"),

      minWidth: 100,
    },
    {
      key: "un",
      name: Strings("username"),
      minWidth: 100,
    },
    {
      key: "vc",
      name:
        Strings("visitedMinimart") +
        " / " +
        Strings("AssingedMinimart"),
      minWidth: 100,
    },
    {
      key: "lc",
      name:
        Strings("generatedLoans") +
        " / " +
        Strings("AssignedInvoices"),
      minWidth: 100,
    },
    {
      key: "lAmt",
      name:
        Strings("generatedLoanAmount") +
        " / " +
        Strings("AssignedInvoicesAmount") +
        " ($)",
      minWidth: 100,
    },

    {
      key: "mRat",
      name: Strings("minimartUserRating"),
      formatter: ({ value }) => {
        return value !== "-" ? (
          <div
            style={{ textAlign: "center" }}
            title={MINIMART_RATING[value]}
          >
            {MINIMART_RATING[value]}
          </div>
        ) : (
          "-"
        );
      },
      minWidth: 100,
    },

    {
      key: "ple",
      name: Strings("disbursementEfficiency"),
      minWidth: 100,
    },
    {
      key: "amtd",
      name: Strings("activeMinimart"),
      minWidth: 100,
    },
    {
      key: "crat",
      name: Strings("conversionRate"),
      minWidth: 100,
    },
    {
      key: "pRat",
      name: Strings("userRating"),
      formatter: renderRater,
      minWidth: 100,
    },
  ],
  MESSENGER: [
    {
      key: "ts",
      name: Strings("Date"),
      formatter: ({ value }) => {
        return moment(value).format(INVOICE_DATE_FORMAT);
      },
      minWidth: 100,
    },
    {
      key: "un",
      name: Strings("username"),
      // formatter: ({ value }) => {
      //   return value + " " + SPECIAL_MESSENGER_PRESENTATION;
      // }
      minWidth: 100,
    },
    {
      key: "vc",
      name:
        Strings("visitedMinimart") +
        " / " +
        Strings("AssingedMinimart"),
      minWidth: 150,
    },
    {
      key: "amtC",
      name:
        Strings("amountCollected") +
        " / " +
        Strings("amountToBeCollected") +
        " ($)",
      minWidth: 100,
    },
    {
      key: "amtT",
      name:
        Strings("InTransitAmount") +
        " / " +
        Strings("depositedAmount") +
        " ($)",
      minWidth: 100,
    },
    {
      key: "pRat",
      name: Strings("userRating"),
      formatter: renderRater,
      minWidth: 100,
    },
  ],
  COLLECTIONS_OFFICER: [
    {
      key: "date",
      name: Strings("Date"),
      formatter: ({ value }) => {
        return moment(value).format(INVOICE_DATE_FORMAT);
      },
      width: 140,
    },
    {
      key: "name",
      name: Strings("username"),
    },
    {
      key: "plansCount",
      name:
        Strings("noOfRepaymentPlansGenerated") +
        " / " +
        Strings("noOfLoansAssigned"),
    },
    {
      key: "communicationsCount",
      name: Strings("noOfMinimartContacted"),
    },
  ],
};

export const LIST_WORK_HISTORY = {
  SALES_PERSON: {
    url: "/salesPersons",
    keysArray: [
      "un",
      "ts",
      ["vc", "mc", { isAmount: true }],
      ["lc", "ic", { isAmount: true }],
      ["lAmt", "iAmt", { isAmount: true }],
      "pRat",
      "mRat",
      "userPreservedData",
      "ple",
      "amtd",
      "pa",
      "crat",
    ],
  },
  MESSENGER: {
    url: "/messengers",
    keysArray: [
      ["un", "ty", { isAmount: false }],
      "ts",
      ["vc", "mc", { isAmount: true }],
      ["amtC", "amtTbC", { isAmount: true }],
      ["amtT", "amtD", { isAmount: true }],
      "pRat",
      "userPreservedData",
    ],
  },
  COLLECTIONS_OFFICER: {
    url: "/collectionsOfficers",
    keysArray: [
      "date",
      "name",
      ["plansCount", "loansCount", { isAmount: true }],
      "communicationsCount",
      "userPreservedData",
    ],
  },
};

export const TABLE_COLUMNS_FOR_PERSON = {
  SALES_PERSON: [
    {
      key: "ts",
      name: Strings("Date"),
      minWidth: 100,
    },
    {
      key: "mn",
      name: Strings("minimartName"),
      minWidth: 150,
    },
    {
      key: "in",
      name: Strings("InvoiceNumber"),
      minWidth: 100,
    },
    {
      key: "appId",
      name: Strings("loanId"),
      minWidth: 100,
    },

    {
      key: "st",
      name: Strings("LoanStatus"),
      minWidth: 100,
    },
  ],
  MESSENGER: [
    {
      key: "ts",
      name: Strings("Date"),
      minWidth: 100,
    },
    {
      key: "mn",
      name: Strings("minimartName"),
      minWidth: 150,
    },
    {
      key: "amtC",
      name:
        Strings("amountCollected") +
        " / " +
        Strings("amountToBeCollected") +
        " ( $ )",
      minWidth: 100,
    },
    {
      key: "amtT",
      name:
        Strings("InTransitAmount") +
        " / " +
        Strings("depositedAmount") +
        " ( $ )",
      minWidth: 100,
    },
  ],
  COLLECTIONS_OFFICER: [
    {
      key: "date",
      name: Strings("Date"),
      minWidth: 100,
    },
    {
      key: "minimartId",
      name: Strings("minimartId"),
      minWidth: 100,
    },
    {
      key: "loanId",
      name: Strings("loanId"),
      minWidth: 100,
    },
    {
      key: "repaymentPlanGenerated",
      name: Strings("noOfRepaymentPlansGenerated"),
      minWidth: 100,
    },
    {
      key: "noOfMinimartContacted",
      name: Strings("noOfMinimartContacted"),
      minWidth: 100,
    },
  ],
};

export const SINGLE_USER_WORK_HISTORY = {
  SALES_PERSON: {
    url: "/salesPerson",
    keysArray: ["ts", "mn", "in", "appId", "st"],
  },
  MESSENGER: {
    url: "/messenger",
    keysArray: [
      "ts",
      "mn",
      ["amtC", "amtTbC", { isAmount: true }],
      ["amtT", "amtD", { isAmount: true }],
    ],
  },
  COLLECTIONS_OFFICER: {
    url: "/collectionsOfficer",
    keysArray: ["date", "minimart", "loanId", "isPlanGenerated"],
  },
};

export const APPROVAL_DASHBOARD_TABS = {
  ADMIN: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
    // { key: 2, value: Strings("HigherCreditLimit") },
  ],
  GENERAL_MANAGER: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
    // { key: 2, value: Strings("HigherCreditLimit") },
  ],
  AUDITOR: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
  ],
  OPERATIONS_MODERATOR: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
  ],
  COLLECTIONS_MANAGER: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
  ],
  COLLECTIONS_SUPERVISOR: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
  ],
  ADMINISTRATIVE_MANAGER: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
  ],
  OPERATIONS_MANAGER: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
  ],
  TECHNOLOGY: [
    {
      key: 0,
      value: Strings("takeOffLateFees"),
    },
    {
      key: 1,
      value: Strings("RepaymentPlan"),
    },
  ],
};

export const REQUEST_STATUS = {
  approved: "APPROVED",
  rejected: "REJECTED",
};
export const ALL = ["A", "B", "C", "D", "D1", "D2", "E",];

export const ZONE_ACCESS_USER = [
  USER_ROLES.admin,
  USER_ROLES.generalManager,
  USER_ROLES.loanPortfolioManager,
  USER_ROLES.accountingOfficer,
  USER_ROLES.accountsOfficer,
  USER_ROLES.collectionsOfficer,
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.auditor,
];

export const USER_RATING_TABS = [
  {
    key: 0,
    value: Strings("Minimart"),
  },
  {
    key: 1,
    value: Strings("User"),
  },
  // {
  //   key: 2,
  //   value: Strings("CollectionsOfficer")
  // }
];

export const SPECIAL = "SPECIAL";
export const SPECIAL_MESSENGER_PRESENTATION =
  "(" + Strings("Special") + ")";
export const SHORT_SPECIAL_MESSENGER_PRESENTATION = "#";

export const SCHEDULAR_CONSTANT = {
  CI_DATA: "CIDATA_SCHEDULER",
};

export const OPERATION_OFFICER_LOANS_STATUS_LEGENDS = [
  {
    key: "INPROCESS_OPERATIONS",
    color: colors.piechartColor29,
  },
  {
    key: "READY_TO_DISBURSE",
    color: colors.piechartColor18,
  },
];

export const LOAN_REMINDER = [
  {
    key: "fastCash",
    color: colors.cardBorderColor,
  },
  {
    key: "Factoring",
    color: colors.factoringCardBorderColor,
  },
  {
    key: "loanReminder",
    color: colors.cardReminderBorderColor,
  },
];

export const CIWIRE_TRANSFER_MANAGEMENT_TABS = [
  {
    key: 0,
    value: "UNPAID",
    label: Strings("UnPaidInvoiceLabel"),
  },
  {
    key: 1,
    value: "PAID",
    label: Strings("PaidInvoiceLabel"),
  },
];

export const REPORTING_USER_ROLES = {
  [USER_ROLES.salesPerson]: USER_ROLES.salesModerator,
  [USER_ROLES.messenger]: USER_ROLES.messengerModerator,
  [USER_ROLES.collectionOfficer]: USER_ROLES.collectionsSupervisor,
  [USER_ROLES.collectionsSupervisor]: USER_ROLES.collectionsManager,
  [USER_ROLES.collectionsManager]: USER_ROLES.administrativeManager,
};

export const POSITIONS_USER_ROLES = {
  [USER_ROLES.salesPerson]: USER_ROLES.salesPerson,
  [USER_ROLES.messenger]: USER_ROLES.messenger,
};

export const BAR_ARRAY = {
  PROVISION: [
    { keyName: "A", color: colors.barChartColor1 },
    { keyName: "B", color: colors.barChartColor2 },
    { keyName: "C", color: colors.barChartColor3 },
    { keyName: "D", color: colors.piechartColor23 },
    { keyName: "D1", color: colors.barChartColor4 },
    { keyName: "D2", color: colors.barChartColor5 },
    { keyName: "E", color: colors.barChartColor6 },
  ],
  DENIAL_REASON: [
    {
      keyName: "El cliente no tenia el dinero",
      color: colors.barChartColor7,
    },

    {
      keyName: "Colmado estaba cerrado",
      color: colors.barChartColor8,
    },
    {
      keyName: "El administrador estaba fuera, la persona no sabía",
      color: colors.barChartColor9,
    },
  ],
  FINANCE_PROFIT: [
    {
      keyName: "totalInterestPaid",
      color: colors.barChartColor4,
    },
    {
      keyName: "totalLateFeesPaid",
      color: colors.barChartColor1,
    },
    {
      keyName: "conciliationInterest",
      color: colors.barChartColor8,
    },
    {
      keyName: "conciliationLateFees",
      color: colors.barChartColor9,
    },
  ],
  COL_SUP_FINANCE_PROFIT: [
    {
      keyName: "totalLateFeesPaid",
      color: colors.barChartColor1,
    },

    {
      keyName: "conciliationLateFees",
      color: colors.barChartColor9,
    },
  ],
};

export const DENIAL_REASONS_LEGENDS = [
  "El cliente no tenia el dinero",
  "Colmado estaba cerrado",
  "El administrador estaba fuera, la persona no sabía",
];

export const DENIAL_REASON_TYPE = [
  "El cliente no tenia el dinero",
  "Colmado estaba cerrado",
  "El administrador estaba fuera, la persona no sabía",
];

export const LOAN_CARD_KEYS = [
  "applicationId",
  "loanAmount",
  "status",
  "loanType",
  "minimartName",
  "loanId",
  "invoiceNumber",
  "disbursementDate",
  "category",
  "provision",
  "ticketId",
  "approvedAmount",
  "userName",
  "loanImage",
];

export const MINIMART_CARD_KEYS = [
  "id",
  "code",
  "name",
  "address",
  "city",
  "zone",
  "category",
  "type",
  "branch",
  "status",
  "latitude",
  "longitude",
  "ticketId",
  "visitStatus",
  "visitedAt",
];

export const INVOICE_CARD_KEYS = [
  "minimartCode",
  "number",
  "date",
  "expiryDate",
  "amount",
  "balance",
  "status",
];

export const LOAN_STATUS_PIE_CHART_KEYS = [
  "ACTIVE",
  "LEGAL",
  "W_OFF",
  "CLOSED",
  "DISAPPEARED",
  "PAYMENT_AGREEMENT",
];

export const USER_ROLES_TO_SHOW_ZONES = [
  USER_ROLES.messenger,
  USER_ROLES.salesPerson,
];

export const ENCRYTION_DECRYPTION_KEY = "JSRAQGIjI2FuQ29zUDA3Nw==";

export const LOAN_TYPES = {
  CASH_TOPUP: "CASH_TOPUP",
  CONSUMER: "CONSUMER",
  FACTORING: "FACTORING",
  EXTERNAL_FACTORING: "EXTERNAL_FACTORING",
  TU_PRESTAMO: "TU_PRESTAMO",
  MOTORCYCLE_LOAN: "MOTORCYCLE",
  FAST_CASH: "FAST_CASH",
};

export const approvedAmount = "approvedAmount";

export const DISBURSED = "DISBURSED";

export const SEARCH_FILTER_TYPE = {
  SELECTION: "selection",
  DATE: "Date",
  INPUT: "input",
  DOUBLE_INPUT: "doubleinput",
  SELECT: "select",
  SINGLE_DATE: "date",
  NUMBER: "number",
  MONTH_YEAR_PICKER: "MonthYearPicker",
  AMOUNT: "amount",
};
export const SEARCH_FILTER_FIELDS = {
  LOAN_TYPE: "loanType",
  DATE: "date",
  CATEGORY: "category",
  LOAN_ID: "loanId",
  REQUESTER: "requester",
  BRANCH: "branch",
  ZONE: "zone",
  MZONE: "mZone",
  MINIMART_NAME: "MiniMartname",
  BRANCH_NAME: "Branchname",
  STATUS: "Status",
  STATE: "State",
  VERSION: "Version",
  SALES_PERSON_NAME: "salesPersonName",
  VISIT_STATUS: "visitStatus",
  GRADE: "grade",
  TYPEOFCUSTOMER: "typeOfCustomer",
  TYPE: "type",
  INVOICE_NUMBER: "invoiceNumber",
  CI_ID: "ciId",
  CI_CODE: "CICode",
  MATRICES_ID: "MatricesID",
  PROVISION: "provision",
  CODE: "code",
  ROLE: "Role",
  USER_NAME: "User name",
  APPLICATION_ID: "applicationId",
  RATING: "rating",
  ANALYST_NAME: "AnalystName",
  SALES_PERSON: "SALES_PERSON",
  MINIMART_TYPES: "minimartTypes",
  USER_RATING: "userRating",
  BUSINESS_TYPE: "businessType",
  MINIMART_STATUSES: "minimartStatuses",
  REGISTRATION_STATUS: "registrationStatus",
  REMITTANCE_STATUS: "remittanceStatus",
  LEVEL: "level",
  INVOICE_TYPE: "invoiceType",
  NAME: "name",
  PAYMENT_STATUS: "paymentStatus",
  REMITTANCE_AMOUNT: "remittanceAmount",
  CONCILIATION_TYPE: "conciliationType",
  OPERATION_OFFICER_NAME: "operationOfficerName",
  CEDULA_ID: "cedulaId",
  PRODUCT_NAME: "productName",
  MODEL: "model",
  REWARD_ENTRY: "rewardEntry",
  APPROVAL_TYPE: "approvalType",
  IS_SCALA_REGISTERED: "isScalaRegistered",
  IDENTIFICATION_NUMBER: "identificationNumber",
  IDENTIFICATION_TYPE: "identificationType",
  CLIENT_STATE: "clientState",
  BANK_NAME: "bankName",
  ACCOUNT_TYPE: "accountType",
  ACTIVE: "active",
  AVAILABLE: "available",
  CLIENT_ID: "clientId",
  RNC_VERIFICATION: "RNCVerification",
  LOAN_FROM: "loanFrom",
  MINIMART_CODE: "minimartCode",
  DOUBLE_INPUT: "doubleInput",
  DAYS_IN_ARREARS: "daysInArrears",
  MESSENGER: "Messenger",
  NOTE_TYPE: "noteType",
};

export const SEARCH_FILTER_PLACE_HOLDER = {
  SELECT_DATE: "selectDate",
};

export const LOAN_CARD_TYPE = {
  ASSIGN_PLAN_LOANS: "assignPlanLoans",
  LATE_FEES_LOANS: "lateFeesLoans",
};

export const DATE_TYPE = {
  FROM_DATE: "fromDate",
  TO_DATE: "toDate",
};

export const DOWNLOAD_FILE_TYPE = {
  PDF: "PDF",
  CSV: "CSV",
  XLS: "XLS",
};

export const CI_BANK_TRANSFER_INVOICE_TYPE = {
  PAID: "PAID",
  UNPAID: "UNPAID",
};

export const COMMISSION_USER_ROLE = {
  SALES: "SALES",
  MESSENGER: "MESSENGER",
  SALES_MODERATOR: "SALES_MODERATOR",
  MESSENGER_MODERATOR: "MESSENGER_MODERATOR",
};

export const ADD = "add";

export const WEIGHT = "weight";

export const MODAL_TYPE = {
  EDIT: "edit",
  ADD_TYPE: "addType",
  VERSION: "version",
  INTEREST: "interest",
  SOURCE_OF_FINANCE: "sourceOfFinance",
};

export const CONFIGURATION_TYPE = {
  EARLY_PAYMENT: "EARLY_PAYMENT",
  EMI: "EMI",
  LOAN_STATUS: "LOAN_STATUS",
  EARLY_CANCELLATION: "EARLY_CANCELLATION",
  GRADING_SCALE: "GRADING_SCALE",
  LATE_FEES: "LATE_FEES",
  LOAN_PICKUP: "LOAN_PICKUP",
  GRACE_PERIOD: "GRACE_PERIOD",
  PROVISION: "PROVISION",
  CIDATA_SCHEDULER: "CIDATA_SCHEDULER",
  COMMISSION_SCHEME: "COMMISSION_SCHEME",
  CREDIT_SCORE: "CREDIT_SCORE",
  HOLIDAY: "HOLIDAY",
  PAYMENT_DISCOUNT: "PAYMENT_DISCOUNT",
  ELIGIBILITY_CRITERIA: "ELIGIBILITY_CRITERIA",
  AUTO_ANALYSIS: "AUTO_ANALYSIS",
  PROSPECT_CREDIT_SCORE: "PROSPECT_CREDIT_SCORE",
  REWARDS: "REWARDS",
  META_ENABLED_STATUS: "META_ENABLED_STATUS",
  ADMINISTRATIVE_EXPENSE_DATA: "ADMINISTRATIVE_EXPENSE_DATA",

  CREDO_CONFIG: "CREDO_CONFIG",
  PAYMENT_FREQUENCY: "PAYMENT_FREQUENCY",
  LOAN_STATUS_AND_GRADE:"LOAN_STATUS_AND_GRADE"
};

export const SORT_TYPE = {
  ASC: "ASC",
  DESC: "DESC",
};

export const CONFIGURATION_DATA_TYPE = {
  PERIOD: "period",
  DAY_RANGE: "dayRange",
  EMI: "emi",
  COMMISSION_SCHEME: "commissionScheme",
  PAYMENT_DISCOUNT: "paymentDiscount",
  LOAN_STATUS: "loanStatus",
  LEVEL: "LEVEL",
  REWARDS: "REWARDS",
  META_ENABLED_STATUS: "isMetaEnabled",
  ADMINISTRATIVE_EXPENSE_DATA: "administrativeExpenseData",
  LATE_FFES: "lateFees",
};

export const CONFIGURATION_SINGLE_KEY_TYPE = {
  DAYS_ARREARS_LOWER: "daysArrears_lower",
  DAYS_ARREARS_UPPER: "daysArrears_upper",
  STATUS: "status",
};

export const LINE_OF_CREDIT_MODAL_KEYS = {
  SOURCE_OF_FINANCE_ID: "sourceOfFinanceId",
  AMOUNT: "amount",
  RATE: "rate",
};

export const TYPE = "type";

export const REPORTS_TAB_KEY_CONST = {
  LOAN_PORTFOLIO: "LOAN_PORTFOLIO",
  SALES: "SALES",
  SPECIAL_SALES: "SPECIAL_SALES",
  COLLECTIONS: "COLLECTIONS",
  WEB_COLLECTIONS: "WEB_COLLECTIONS",
  WALLET: "WALLET",
};

export const REPORTS_INTERNAL_TABS = {
  LOAN_PORTFOLIO: ["loanCount", "loanList"],
  WALLET: ["walletReports", "disbursementReport"],
}

export const LOAN_PORTFOLIO_INVOICE_DATE_TYPE = {
  FROM_INVOICE_DATE: "fromInvoiceDate",
  TO_INVOICE_DATE: "toInvoiceDate",
};

export const LOAN_PORTFOLIO_LOAN_DATE_TYPE = {
  FROM_LOANINVOICE_DATE: "fromLoanDate",
  TO_LOAN_DATE: "toLoanDate",
};

export const USER_TYPE = {
  SALES_PERSON: "SALES_PERSON",
  MESSENGER: "MESSENGER",
};

export const ZONE = "zone";
export const EVIDENCE = [
  {
    title: Strings("LOCAL_PHOTO").concat(" 1"),
    key: "documentId",
    contentType: "imageURLS",
    type: "LOCAL_PHOTO1",
  },
  {
    title: Strings("LOCAL_PHOTO").concat(" 2"),
    key: "documentId",
    contentType: "imageURLS",
    type: "LOCAL_PHOTO2",
  },
  {
    title: Strings("LOCAL_PHOTO").concat(" 3"),
    key: "documentId",
    contentType: "imageURLS",
    type: "LOCAL_PHOTO3",
  },
  {
    title: Strings("LOCAL_PHOTO").concat(" 4"),
    key: "documentId",
    contentType: "imageURLS",
    type: "LOCAL_PHOTO4",
  },
  {
    title: Strings("LOCAL_PHOTO").concat(" 5"),
    key: "documentId",
    contentType: "imageURLS",
    type: "LOCAL_PHOTO5",
  },
  {
    title: Strings("Invoice").concat(" 1"),
    key: "documentId",
    contentType: "imageURLS",
    type: "INVOICE1",
  },
  {
    title: Strings("Invoice").concat(" 2"),
    key: "documentId",
    contentType: "imageURLS",
    type: "INVOICE2",
  },
  {
    title: Strings("Invoice").concat(" 3"),
    key: "documentId",
    contentType: "imageURLS",
    type: "INVOICE3",
  },
  {
    title: Strings("PhotoID_FRONT"),
    key: "documentId",
    contentType: "imageURLS",
    type: "PHOTOID_FRONT",
  },
  {
    title: Strings("PhotoID_BACK"),
    key: "documentId",
    contentType: "imageURLS",
    type: "PHOTOID_BACK",
  },
  {
    title: Strings("externalInvoice"),
    key: "documentId",
    contentType: "imageURLS",
    type: "EXTERNAL_INVOICE1",
  },
  {
    title: Strings("externalInvoice"),
    key: "documentId",
    contentType: "imageURLS",
    type: "EXTERNAL_INVOICE2",
  },
  {
    title: Strings("APPLICATION_SIGNATURE"),
    key: "documentId",
    contentType: "imageURLS",
    type: "APPLICATION_SIGNATURE",
  },
  {
    title: Strings("CREDIT_AGREEMENT_SIGNATURE"),
    key: "documentId",
    contentType: "imageURLS",
    type: "AGREEMENT_SIGNATURE",
  },
  {
    title: Strings("LOAN_AGREEMENT_SIGNATURE"),
    key: "documentId",
    contentType: "imageURLS",
    type: "LOAN_AGREEMENT_SIGNATURE",
  },

];

export const CARD_TYPE = {
  MINIMART: "minimart",
  INVOICE: "invoice",
  INVOICES: "invoices",
  LOAN: "loan",
  PERSON: "Person",
  MINIMART_HISTORY: "minimartHistory",
  MOTORCYCLE_CATALOGUE: "motorcycleCatalogue",
  CI_APPROVAL_MINIMART: "CIApprovalMinimart",
  NEW_MINIMART: "newMinimart",
  REQUEST: "REQUEST",
  PROMOTION: "promotion",
  REWARD: "reward",
  REWARD_APPROVAL: "rewardApproval",
  REWARD_HISTORY: "rewardHistory",
  SUPPLIER: "supplier"
};

export const WORKLOAD_MANAGMENT_STATE_KEY = {
  ROLE: "selectedRole",
  BRANCH: "selectedBranch",
  ASSIGNED_DAILY: "assignedDaily",
  ASSIGNMENT_TYPE: "selectedAssignmentType",
  SELECTED_DAILY_OPTIONS: "selectedDailyOptions",
  ASSIGNED_PERMANENT: "assignedPermanent",
  SELECTED_PERMANENT_OPTIONS: "selectedPermanentOptions",
};

export const MINIMART_APPROVAL_REQ_TABS = {
  ANALYST: [
    {
      key: 0,
      label: Strings("ApprovalReminders"),
      value: "Reminders",
    },
    {
      key: 1,
      label: Strings("ApprovalRequests"),
      value: "Requests",
    },
  ],
  ANALYST_MODERATOR: [
    {
      key: 0,
      label: Strings("ApprovalReminders"),
      value: "Reminders",
    },
    {
      key: 1,
      label: Strings("ApprovalRequests"),
      value: "Requests",
    },
  ],
  GENERAL_MANAGER: [
    {
      key: 0,
      label: Strings("ApprovalReminders"),
      value: "Reminders",
    },
    {
      key: 1,
      label: Strings("ApprovalRequests"),
      value: "Requests",
    },
    {
      key: 2,
      label: Strings("CIMinimarts"),
      value: "CIMinimarts",
    },
  ],
  ADMIN: [
    {
      key: 0,
      label: Strings("ApprovalReminders"),
      value: "Reminders",
    },
    {
      key: 1,
      label: Strings("ApprovalRequests"),
      value: "Requests",
    },
    {
      key: 2,
      label: Strings("CIMinimarts"),
      value: "CIMinimarts",
    },
  ],
  OPERATIONS_OFFICER: [
    {
      key: 0,
      label: Strings("ApprovalReminders"),
      value: "Reminders",
    },
    {
      key: 1,
      label: Strings("ApprovalRequests"),
      value: "Requests",
    },
    {
      key: 2,
      label: Strings("CIMinimarts"),
      value: "CIMinimarts",
    },
  ],
};
export const VISITED_DAYS = [
  {
    key: 0,
    label: Strings("selectDay"),
    value: Strings("selectDay"),
  },
  {
    key: 1,
    label: Strings("LUN"),
    value: "LUN",
  },
  {
    key: 2,
    label: Strings("MAR"),
    value: "MAR",
  },
  {
    key: 3,
    label: Strings("MIE"),
    value: "MIE",
  },
  {
    key: 4,
    label: Strings("JUE"),
    value: "JUE",
  },
  {
    key: 5,
    label: Strings("VIE"),
    value: "VIE",
  },
];

export const APPROVED_MINIMART_ACTION = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const LOAN_TYPE_KEYS = {
  AMT: "amt",
  NOI: "noi",
  APP_AMT: "appAmt",
  APP_TERM: "appTerm",
  RT: "rt",
  APP_RT: "appRate",
  EMI: "emi",
  TY: "ty",
  ST: "st",
  PNM: "pnm",
  USER_NAME: "userName",
  DISBURSE_DATE: "disTs",
  SN: "sn",
  CP: "cp",
  CD: "cd",
  DPR: "dpr",
  PC: "co",
  DAMT: "dAmt",
  EDAMT: "edAmt",
  LCA: "lca",
  SCA: "sca",
  RST: "rs", // Remittance status
  DR: "dr", // Delivery receipt
  INV: "inv",
  IT: "it",
  IF: "if", // initiated from (CORE/TPRESTA)
  BAL: "bal",
  ED: "ed",
  DT: "dt",
  APP_FQ: "appfq",
  RFQ: "rfq",
  TR: "tr",
  AE: "ae",
  amountCharged: "amountCharged",
  amountPaid: "amountPaid",
  receiptIds: "receiptIds",
  requestedCollectionDays: "rcd",
  approvedCollectionDays: "appCd",
  DPTR: "dptr",
  DPTRI: "dptri",
  transferReceiptIds: "transferReceiptIds",
  transferReceiptUrl: "transferReceiptUrl",
  paymentModes: "paymentModes",
  paymentSources: "paymentSources",
};

export const USERS = {
  MESSENGER: "Messenger",
  ANALYST: "Analyst",
  OPERATIONS_OFFICER: "OperationsOfficer",
};

export const MINIMART_BUSINESS_TYPE_LEGEND = [
  {
    key: "Colmados",
    color: colors.piechartColor33,
    value: "Colmados",
  },
  {
    key: "Salon",
    color: colors.piechartColor34,
    value: "Salon",
  },
  {
    key: "Barberia",
    color: colors.piechartColor35,
    value: "Barberia",
  },

  {
    key: "Gomeras",
    color: colors.piechartColor36,
    value: "Gomeras",
  },
  {
    key: "Comedor O Cafetería",
    color: colors.piechartColor37,
    value: "Comedor O Cafetería",
  },
  {
    key: "Pequeno Supermercado",
    color: colors.piechartColor38,
    value: "Pequeno Supermercado",
  },
  {
    key: "Minimarket",
    color: colors.piechartColor39,
    value: "Minimarket",
  },
  {
    key: "Sm Independientes",
    color: colors.piechartColor40,
    value: "Sm Independientes",
  },
  {
    key: "Panaderias",
    color: colors.piechartColor41,
    value: "Panaderias",
  },
  {
    key: "Almacenes",
    color: colors.piechartColor42,
    value: "Almacenes",
  },
  {
    key: "Surtidora",
    color: colors.piechartColor43,
    value: "Surtidora",
  },
  {
    key: "Asociaciones",
    color: colors.piechartColor44,
    value: "Asociaciones",
  },
  {
    key: "Tienda De Embutidos",
    color: colors.piechartColor45,
    value: "Tienda De Embutidos",
  },
  {
    key: "Economato",
    color: colors.piechartColor46,
    value: "Economato",
  },
  {
    key: "Farmacia",
    color: colors.piechartColor47,
    value: "Farmacia",
  },
  {
    key: "Carniceria",
    color: colors.piechartColor48,
    value: "Carniceria",
  },
  {
    key: "Agroveterinaria",
    color: colors.piechartColor49,
    value: "Agroveterinaria",
  },
];
export const MINIMART_STATUS = {
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
  BARRED: "BARRED",
};
export const NOTES_TYPE = {
  COL_OFF_EMI_INPUT: "COL_OFF_EMI_INPUT",
  CI_MINIMART_APPROVE: "CI_MINIMART_APPROVE",
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  FOLLOW_UP: "FOLLOW_UP",
  CLIENT_STATE_CHANGE: "CLIENT_STATE_CHANGE"
};

export const DEFAULT_APPROVAL_TYPE = Strings("selectApprovalType");
export const DEFAULT_LOAN_FROM = Strings("loanFrom");

export const SEARCH_FILTER_PAGES = {
  LOAN_APPROVAL: "LOAN_APPROVAL",
  OPERATIONS_LOAN_APPROVAL: "OPERATIONS_LOAN_APPROVAL",
  MINIMART_LIST: "MINIMART_LIST",
  APPROVAL_DASHBOARD: "APPROVAL_DASHBOARD",
  LOAN_HISTORY: "LOAN_HISTORY",
  SPED_L1: "SPED_L1",
  SPED_L2: "SPED_L2",
  LOAN_PORTFOLIO: "LOAN_PORTFOLIO",
  COMMISSION: "COMMISSION",
  COMMISSION_REPORT: "COMMISSION_REPORT",
  USER_LIST: "USER_LIST",
  BRANCH_LIST: "BRANCH_LIST",
  CI_INTERFACE: "CI_INTERFACE",
  CI_LOAN_REQUEST_REPORT: "CI_LOAN_REQUEST_REPORT",
  FIRST_LEVEL_REPORT: "FIRST_LEVEL_REPORT",
  SECOND_LEVEL_REPORT: "SECOND_LEVEL_REPORT",
  DAILY_STATUS: "DAILY_STATUS",
  PERSON_DAILY_STATUS: "PERSON_DAILY_STATUS",
  COLLECTION_VERIFICATION: "COLLECTION_VERIFICATION",
  WORK_HISTORY: "WORK_HISTORY",
  CONFIGURATION: "CONFIGURATION",
  LOAN_DETAIL: "LOAN_DETAIL",
  MINIMART_HISTORY: "MINIMART_HISTORY",
  NEW_MINIMART_LIST: "NEW_MINIMART_LIST",
  WORKLOAD_MANAGEMENT: "WORKLOAD_MANAGEMENT",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  ANALYST_LOAN_DETAIL: "ANALYST_LOAN_DETAIL",
  PROMOTION_APPROVAL: "PROMOTION_APPROVAL",
  PROMOTION_LOAN_DETAIL: "PROMOTION_LOAN_DETAIL",
  REWARD_APPROVAL: "REWARD_APPROVAL",
  CI_MINIMART: "CI_MINIMART",
  CLIENT_PORTAL: "CLIENT_PORTAL"
};

export const LOAN_APPROVAL_FILTER = {
  mid: "",
  code: "",
  appid: "",
  mn: "",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  ty: DEFAULT_LOAN_TYPE,
  an: "",
  st: STATUS_FOR_LOANS[0].value,
  rst: REMITTANCE_STATUS_FOR_LOANS[0].value,
  cct: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const OPERATIONS_LOAN_APPROVAL_FILTER = {
  mid: "",
  code: "",
  appid: "",
  mn: "",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  ty: DEFAULT_LOAN_TYPE,
  an: "",
  st: STATUS_FOR_LOANS[0].value,
  rst: REMITTANCE_STATUS_FOR_LOANS[0].value,
  cct: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const MINIMART_LIST_FILTER = {
  mid: "",
  code: "",
  mn: "",
  lvl: "",
  gr: "",
  toc: "",
  zn: "",
  mst: "ALL",
  st: "",
  bt: "",
  rs: "",
  oc: "",
  isr: null,
  zones: [],
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const APPROVAL_DASHBOARD_FILTER = {
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  ty: DEFAULT_LOAN_TYPE,
  ct: DEFAULT_CATEGORY_TYPE,
  lid: "",
  un: DEFAULT_USER_SELECTION,
  br: DEFAULT_BRANCH_SELECTION,
  zn: "",
  mn: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const SPED_L1_FILTER = {
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  un: "",
  br: "",
  gr: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const SPED_L2_FILTER = {
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  mid: "",
  code: "",
  appid: "",
  mn: "",
  st: SPED_STATUS_FOR_LOANS[0].value,
  ty: DEFAULT_LOAN_TYPE,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const LOAN_HISTORY_FILTER = {
  mid: "",
  code: "",
  appid: "",
  mn: "",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  ty: DEFAULT_LOAN_TYPE,
  an: "",
  st: STATUS_FOR_LOANS[0].value,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const LOAN_PORTFOLIO_FILTER = {
  mid: "",
  code: "",
  appid: "",
  lid: "",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  mn: "",
  ty: DEFAULT_LOAN_TYPE,
  st: STATUS_FOR_LOANS[0].value,
  ct: DEFAULT_CATEGORY_TYPE,
  br: DEFAULT_BRANCH_SELECTION,
  sun: "",
  cct: "",
  at: DEFAULT_APPROVAL_TYPE,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  lf: DEFAULT_LOAN_FROM,
  offset: 1,
};

export const USER_LIST_FILTER = {
  un: "",
  rl: "",
  br: "",
  zn: "",
  mzn: "",
  active: "",
  available: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const NOTES_FILTER = {
  rl: "",
  ntc:"",
  ty:"",
  ur:"",
  offset: 1,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  fromDate: DEFAULT_DURATION.fromDate,
  toDate: DEFAULT_DURATION.toDate,
}
export const BRANCH_LIST_FILTER = {
  brnm: "",
  br: DEFAULT_BRANCH_SELECTION,
  zn: DEFAULT_ZONE,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const COMMISSION_FILTER = {
  br: "",
  zn: "",
  un: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const COMMISSION_REPORT_FILTER = {
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const CI_INTERFACE_FILTER = {
  name: "",
  status: "",
  processedAt: undefined,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const CI_LOAN_REQUEST_REPORT_FILTER = {
  offset: 1,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  ri: "",
  in: "",
  cd: "",
  st: ""
};

export const FLR_SEARCH_FILTER = {
  ifd: undefined,
  itd: undefined,
  fic: "",
  tic: "",
  flc: "",
  tlc: "",
  br: "",
  zn: "",
  ty: "",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
  un: "",
  la: "",
  ua: "",
  mid: "",
  ciid: "",
  mn: "",
  gr: "",
};

export const SLR_SEARCH_FILTER = {
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  br: "",
  zn: "",
  ty: "",
  mid: "",
  code: "",
  pr: "",
  st: "",
  lid: "",
  fad: "",
  tad: "",
  uid: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
  appId: "",
  dal: "",
  rs: "",
};

export const DAILY_STATUS_FILTER = {
  td: new Date(),
  un: "",
  br: "",
  zn: "",
  rat: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const PERSON_DAILY_STATUS_FILTER = {
  mn: "",
  vs: "",
  gr: "",
  toc: "",
  lvl: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const COLLECTION_VERIFICATION_FILTER = {
  dt: new Date(),
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};
export const WORK_HISTORY_FILTER = {
  userName: "",
  selectedUserName: "",
  fromDate: new Date(),
  toDate: new Date(),
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const CONFIGURATION_FILTER = {
  feature: "",
  version: "",
  status: DEFAULT_STATUS,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const LOAN_DETAIL_FILTER = {
  st: "",
  ct: "",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const MINIMART_HISTORY_FILTER = {
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  mn: "",
  cd: "",
  st: "",
  limit: 10,
  offset: 1,
};

export const NEW_MINIMART_LIST_FILTER = {
  mid: "",
  code: "",
  mn: "",
  lvl: "",
  gr: "",
  toc: "",
  zn: "",
  mst: "ALL",
  st: "",
  bt: "",
  rs: "",
  zones: [],
  oc: "",
  isr: null,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const WORKLOAD_MANAGEMENT_FILTER = {
  br: "",
  pid: "",
  zn: "",
  rl: "",
  uid: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const SIGNUP_REQUEST_FILTER = {
  st: "PENDING",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const ANALYST_LOAN_DETAIL_FILTER = {
  st: "",
  ct: "",
  fd: moment(new Date("Jan 01 2019")).format(DEFAULT_DATE_FORMAT),
  td: DEFAULT_DURATION.toDate,
  lid: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const PROMOTION_APPROVAL_FILTER = {
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  mid: "",
  mn: "",
  code: "",
  prty: "",
  prbty: "",
  pst: "PENDING",
  prlt: "",
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const PROMOTION_LOAN_DETAIL_FILTER = {
  pst: "",
  ct: "",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
  offset: 1,
};

export const REWARD_APPROVAL_FILTER = {
  mid: "",
  mn: "",
  rpn: "",
  rpm: "",
  rs: "PENDING",
  fd: DEFAULT_DURATION.fromDate,
  td: DEFAULT_DURATION.toDate,
  oo: "",
  oft: 1,
  lmt: DEFAULT_PAGINATION_RECORDS_COUNT,
};

export const CI_MINIMART_FILTER = {
  mn: "",
  cd: "",
  oft: 1,
  lmt: DEFAULT_PAGINATION_RECORDS_COUNT,
};
export const CLIENT_PORTAL_FILTER = {
  clientId: "",
  idNumber: "",
  idType: "",
  mid: "",
  cs: "",
  offset: 1,
  limit: DEFAULT_PAGINATION_RECORDS_COUNT,
};

export const URL_FILTER = {
  [Strings("loanApproval")]: {
    key: "LOAN_APPROVAL",
    filter: LOAN_APPROVAL_FILTER,
  },
  [Strings("operationLoanApproval")]: {
    key: "OPERATIONS_LOAN_APPROVAL",
    filter: OPERATIONS_LOAN_APPROVAL_FILTER,
  },
  [Strings("minimart")]: {
    key: "MINIMART_LIST",
    filter: MINIMART_LIST_FILTER,
  },
  [Strings("approvalDashboard")]: {
    key: "APPROVAL_DASHBOARD",
    filter: APPROVAL_DASHBOARD_FILTER,
  },
  [Strings("repaymentPlan")]: {
    key: "APPROVAL_DASHBOARD",
    filter: APPROVAL_DASHBOARD_FILTER,
  },
  [Strings("higherCreditLimit")]: {
    key: "APPROVAL_DASHBOARD",
    filter: APPROVAL_DASHBOARD_FILTER,
  },
  [Strings("salespersonEfficiencyDashboard")]: {
    key: "SPED_L1",
    filter: SPED_L1_FILTER,
  },
  [Strings("singleSalesPerson")]: {
    key: "SPED_L2",
    filter: SPED_L2_FILTER,
  },
  [Strings("loanHistory")]: {
    key: "LOAN_HISTORY",
    filter: LOAN_HISTORY_FILTER,
  },
  [Strings("loanPortFolio")]: {
    key: "LOAN_PORTFOLIO",
    filter: LOAN_PORTFOLIO_FILTER,
  },
  [Strings("CommissionSchemeRoute")]: {
    key: "COMMISSION",
    filter: COMMISSION_FILTER,
  },
  [Strings("salesPersonComission")]: {
    key: "COMMISSION",
    filter: COMMISSION_FILTER,
  },
  [Strings("messengerComission")]: {
    key: "COMMISSION",
    filter: COMMISSION_FILTER,
  },
  [Strings("salesModeratorComssion")]: {
    key: "COMMISSION",
    filter: COMMISSION_FILTER,
  },
  [Strings("messengerModeratorComssion")]: {
    key: "COMMISSION",
    filter: COMMISSION_FILTER,
  },
  [Strings("commissionReportURL")]: {
    key: "COMMISSION",
    filter: COMMISSION_REPORT_FILTER,
  },
  [Strings("userList")]: {
    key: "USER_LIST",
    filter: USER_LIST_FILTER,
  },
  [Strings("notesDetails")]: {
    key: "NOTES",
    filter: NOTES_FILTER,
  },
  [Strings("branchList")]: {
    key: "BRANCH_LIST",
    filter: BRANCH_LIST_FILTER,
  },
  [Strings("CIInterface")]: {
    key: "CI_INTERFACE",
    filter: CI_INTERFACE_FILTER,
  },
  [Strings("reports")]: {
    key: "FIRST_LEVEL_REPORT",
    filter: FLR_SEARCH_FILTER,
  },
  [Strings("LoanportfolioReports")]: {
    key: "FIRST_LEVEL_REPORT",
    filter: FLR_SEARCH_FILTER,
  },
  [Strings("singleLoanPortFolioReports")]: {
    key: "SECOND_LEVEL_REPORT",
    filter: SLR_SEARCH_FILTER,
  },
  [Strings("salesReports")]: {
    key: "FIRST_LEVEL_REPORT",
    filter: FLR_SEARCH_FILTER,
  },
  [Strings("collectionsReports")]: {
    key: "FIRST_LEVEL_REPORT",
    filter: FLR_SEARCH_FILTER,
  },
  [Strings("dailyStatus")]: {
    key: "DAILY_STATUS",
    filter: DAILY_STATUS_FILTER,
  },
  [Strings("dailyStatusMessenger")]: {
    key: "DAILY_STATUS",
    filter: DAILY_STATUS_FILTER,
  },
  [Strings("dailyStatusSalesPersonDetails")]: {
    key: "PERSON_DAILY_STATUS",
    filter: PERSON_DAILY_STATUS_FILTER,
  },
  [Strings("dailyStatusMessengerDetails")]: {
    key: "PERSON_DAILY_STATUS",
    filter: PERSON_DAILY_STATUS_FILTER,
  },
  [Strings("collectionVerification")]: {
    key: "COLLECTION_VERIFICATION",
    filter: COLLECTION_VERIFICATION_FILTER,
  },
  [Strings("userWorkHistory")]: {
    key: "WORK_HISTORY",
    filter: WORK_HISTORY_FILTER,
  },
  [Strings("userWorkHistoryMessenger")]: {
    key: "WORK_HISTORY",
    filter: WORK_HISTORY_FILTER,
  },
  [Strings("analystLoanView")]: {
    key: "ANALYST_LOAN_DETAIL",
    filter: ANALYST_LOAN_DETAIL_FILTER,
  },
  [Strings("minimartDetails")]: {
    key: "LOAN_DETAIL",
    filter: LOAN_DETAIL_FILTER,
  },
  [Strings("ApprovalMinimartHistory")]: {
    key: "MINIMART_HISTORY",
    filter: MINIMART_HISTORY_FILTER,
  },
  [Strings("newMinimart")]: {
    key: "NEW_MINIMART_LIST",
    filter: NEW_MINIMART_LIST_FILTER,
  },
  [Strings("workLoad")]: {
    key: "WORKLOAD_MANAGEMENT",
    filter: WORKLOAD_MANAGEMENT_FILTER,
  },
  [Strings("mtpSignupRequest")]: {
    key: "SIGNUP_REQUEST",
    filter: SIGNUP_REQUEST_FILTER,
  },
  [Strings("mtpPromotionApproval")]: {
    key: "PROMOTION_APPROVAL",
    filter: PROMOTION_APPROVAL_FILTER,
  },
  [Strings("mtpPromotionDetails")]: {
    key: "PROMOTION_LOAN_DETAIL",
    filter: PROMOTION_LOAN_DETAIL_FILTER,
  },
  [Strings("rewardApproval")]: {
    key: "REWARD_APPROVAL",
    filter: REWARD_APPROVAL_FILTER,
  },
  [Strings("minimartApprovalRequests")]: {
    key: "CI_MINIMART",
    filter: CI_MINIMART_FILTER,
  },
};

export const MINIMART_REGISTRATION_STATUS = [
  {
    key: 0,
    value: Strings("registrationStatus"),
    label: Strings("registrationStatus"),
  },
  {
    key: 1,
    value: "PENDING",
    label: Strings("PENDING"),
  },
  {
    key: 2,
    value: "APPROVED",
    label: Strings("APPROVED"),
  },
  {
    key: 3,
    value: "REJECTED",
    label: Strings("REJECTED"),
  },
];

export const MINIMART_LEVELS = [
  {
    key: 0,
    value: Strings("minimartLevel"),
    label: Strings("minimartLevel"),
  },
  {
    key: 1,
    value: "LEVEL1",
    label: Strings("LEVEL1"),
  },
  {
    key: 2,
    value: "LEVEL2",
    label: Strings("LEVEL2"),
  },
  {
    key: 3,
    value: "LEVEL3",
    label: Strings("LEVEL3"),
  },
  {
    key: 4,
    value: "LEVEL4",
    label: Strings("LEVEL4"),
  },
  {
    key: 5,
    value: "LEVEL5",
    label: Strings("LEVEL5"),
  },
  {
    key: 6,
    value: "LEVEL6",
    label: Strings("LEVEL6"),
  },
  {
    key: 7,
    value: "LEVEL7",
    label: Strings("LEVEL7"),
  },
];
export const INVOICE_TYPES = [
  {
    key: 0,
    value: Strings("InvoiceType"),
    label: Strings("InvoiceType"),
  },
  {
    key: 1,
    value: "FOOD",
    label: Strings("FOOD"),
  },
  {
    key: 2,
    value: "DETERGENT",
    label: Strings("DETERGENT"),
  },
];

export const INVOICE_TYPE_IMAGES = {
  FOOD: InvoiceFoodImage,
  DETERGENT: InvoiceDetergentImage,
};

export const ALLOWED_LOAN_TYPES_NEGOTIATION = [
  "CASH_TOPUP",
  "TU_PRESTAMO",
];

export const LOAN_INITIATED_FROM = {
  CORE: "CORE",
  TPRESTA: "TPRESTA",
};
export const DEFAULT_CONCILIATION_TYPE = Strings(
  "selectConciliation"
);

export const CONCILIATION_TYPE_LIST = [
  {
    key: 0,
    value: DEFAULT_CONCILIATION_TYPE,
    label: DEFAULT_CONCILIATION_TYPE,
  },
  {
    key: 1,
    value: "CONCILIATED",
    label: Strings("CONCILIATED"),
  },
  {
    key: 2,
    value: "NON_CONCILIATED",
    label: Strings("NON_CONCILIATED"),
  },
];

export const APPROVAL_TYPE_LIST = [
  {
    key: 0,
    value: DEFAULT_APPROVAL_TYPE,
    label: DEFAULT_APPROVAL_TYPE,
  },
  {
    key: 1,
    value: "false",
    label: Strings("MANUAL_APPROVED"),
  },
  {
    key: 2,
    value: "true",
    label: Strings("AUTO_APPROVED"),
  },
];

export const LOAN_STATUS_CATEGORIES = [
  {
    key: 0,
    value: "ACTIVE",
    label: Strings("Active"),
  },
  {
    key: 1,
    value: "LEGAL",
    label: Strings("Legal"),
  },
  {
    key: 2,
    value: "W_OFF",
    label: Strings("W_OFF"),
  },
  {
    key: 3,
    value: "CLOSED",
    label: Strings("CLOSED"),
  },
  {
    key: 4,
    value: "DISAPPEARED",
    label: Strings("DISAPPEARED"),
  },
  {
    key: 5,
    value: "PAYMENT_AGREEMENT",
    label: Strings("PAYMENT_AGREEMENT"),
  },
];

export const CI_MINIMART_STATUS = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};
export const DAYS_ARREARS = {
  LOWER: "lower",
  UPPER: "upper",
};

export const DEFAULT_VALUES = [
  "selectBranch",
  "Zona del vendedor",
  "Zona de Mensajería",
  Strings("username") + " " + Strings("type"),
  Strings("username"),
  "",
  Strings("SelectPosition"),
  Strings("BusinessType"),
  Strings("selectDay"),
  Strings("Status"),
  Strings("BusinessType"),
  Strings("selectIdentificationType"),
  Strings("selectClientState"),
  Strings("accountTypes"),
  "selectBankStatus",
  Strings("selectBankStatus"),
  DEFAULT_USER_SELECTION,
  Strings("Availability"),
  Strings("minimartLevel"),
  "Business level",
  Strings("bankNames"),
  Strings("accountTypes"),
  Strings("RNCVerification"),
  Strings("loanFrom"),
  Strings("selectStatus"),
  Strings("noteType"),
];

export const LOAN_STATUS_AFTER_DISBURSEMENT = {
  ACTIVE: "ACTIVE",
  LEGAL: "LEGAL",
  W_OFF: "W_OFF",
  CLOSED: "CLOSED",
  DISAPPEARED: "DISAPPEARED",
};

export const CLIENT_STATUS = [
  {
    key: 0,
    value: "",
    label: Strings("SelectClientStatus"),
  },
  {
    key: 1,
    value: "Blacklisted",
    label: Strings("Blacklisted"),
  },
  {
    key: 2,
    value: "Non_Blacklisted",
    label: Strings("Non_Blacklisted"),
  },
];

export const IDENTIFICATION_TYPE = [
  {
    key: 0,
    value: "",
    label: Strings("selectIdentificationType"),
  },
  {
    key: 1,
    value: "Cedula",
    label: Strings("Cedula"),
  },
  {
    key: 2,
    value: "Passport",
    label: Strings("Passport"),
  },
];

export const FILE_TYPE = {
  pdf: "PDF",
  jpg: "JPG",
  jpeg: "JPEG",
  png: "PNG"
};

export const NOTE_TYPE_CONTEXT = {
  LOAN: "LOAN",
  MINIMART: "MINIMART"
}

export const AMMORTIZATIONS_NOTE_COLUMNS = [
  { key: "ca", name: Strings("Date"), maxWidth: 200, minWidth: 50, },
  { key: "note", name: Strings("Notes"), maxWidth: 500, minWidth: 300 },
  { key: "author", name: Strings("author"), maxWidth: 200, minWidth: 50 },
  { key: "role", name: Strings("UserRole"), maxWidth: 50, minWidth: 50 },
]