const en = {
  //Header
  "ProfileName": "Profile Name",
  "UserRole": "User Role",
  "ChangePassword": "Change Password",
  "Profile": "Profile",
  "Logout": "Logout",
  "UnauthorizedUser": "Unauthorized User",

  //Sidebar
  "LoanPortfolio": "Loan Portfolio",
  "Operations": "Operations",
  "Collections": "Collections",
  "Accounting": "Accounting",

  //Profile
  "UserDetails": "User Details",
  "Edit": "Edit",
  "Deactivate": "Deactivate",
  "fullName": "Full Name",
  "firstName": "First Name",
  "lastName": "Last Name",
  "Role": "Role",
  "email": "email",
  "PhoneNumber": "Phone Number",
  "Save": "Save",
  "Back": "Back",
  "SelectUser": "Select User",
  "Image": "Image",
  "User": "User",
  "USER_NOT_FOUND": "No any user available to handle workload of original user.",
  "NO_REPORTING_USER_FOUND": "No reporting user found.",
  "OwnerMobileNumber": "Owner Mobile Number",


  //ProfileMessages
  "UserCreatedSuccessfully": "User has been successfully created.",
  "UserCreatedSuccessfullyAssignmentsNotDone": "User has been successfully created.  Changes will reflect in few minutes.",
  "UserUpdatedSuccessfully": "User has been successfully updated.",

  // Login
  "Sanerret": "Sanerret",
  "LoginCopyrightText": "Copyright © $ Matrices",
  "username": "Username",
  "Password": "Password",
  "ForgotPassword": "Forgot Password",
  "Login": "Login",

  // Errors
  "Error": "Error",
  "SorryAnErrorHasOccurred":
    "Sorry an error has occurred. Please try again.",
  "NetworkNotReachable": "Failed to connect to the server.",
  "ConnectionError": "Connection Error",
  "EmptyFieldsError": "Please fill all the required fields.",
  "InvalidLoginCredentials": "Invalid credentials.",
  "InvalidMatricesCode": "Please Enter a Valid Matrices code.",
  "EmptyUsernameError": "Please enter username.",
  "EmailHasBeenSent": "email has been sent to the user.",
  "EmailNotSent": "Unable to send an email to the user.",
  "InvalidOldPassword": "Invalid old password.",
  "UserNotActive": "User is not active.",
  "UsernameAlreadyExists": "Username already exist.",
  "RoleNotFound": "Role not found.",
  "FailedToSendEmail": "Unable to send an email to the user.",
  "AccessNotFound": "Access not found!!",
  "InvalidRequestParameters": "Invalid request Parameters.",
  "SearchParametersCanNotBeAllNull":
    "Search Parameters can not be all null.",
  "ErrorNewPasswordAndConfirmPasswordAreNotSame":
    "New password and confirm password are not same.",
  "ErrorInvalidOldPassword": "Invalid old password",
  "ErrorInvalidEmail": "Invalid email",
  "ErrorInvalidBranchName": "Invalid Branch Name",
  "ACCOUNT_LOCKED_ERROR_MESSAGE":
    "Account is locked please try after sometime.",
  "NO_ANY_DOWNPAYMENT_FOUND": "No downpayment found for the day.",
  "ErrorOptionSelection": "Please select the type of payment reversal.",
  "CHOOSE_TWO_DIFFERENT_MINIMARTS": "Choose two different minimarts for merging.",
  "BRANCH_ZONE_MAPPING_NOT_PRESENT": "Please fill branch zone mapping for selected minimart: $",
  "PaymentCannotBeMade": "Payment cannot be made from this tab due to an incomplete loan form. Use the loan payment tab.",
  "loanSpecificPayment": "For Loan ID * You can make payment upto #",
  "CannotMakePaymentForLoan": "Payment cannot be made for loan ID {0}, as it's application form data is pending",
  "InvalidInput": "Please enter a valid entry.",
  "InvalidZoneName":"Please enter valid zone name.",
  //Change Password
  "OldPassword": "Old Password",
  "NewPassword": "New Password",
  "ConfirmPassword": "Confirm Password",
  "PasswordChanged": "Password Changed",
  "YourPasswordHasBeen": "Your password has been",
  "ChangedSuccessfully": "changed successfully!",
  "LoginAgain": "Login Again",
  "Submit": "Submit",
  "Cancel": "Cancel",

  // User Management
  "UserManagement": "User Management",
  "AddUser": "Add User",
  "NoRecordsFound": "No Records Found",
  "BranchOfficer": "Branch Officer",
  "CollectionsOfficer": "Collections Officer",
  "LoanPortfolioManager": "Loan Portfolio Manager",
  "Search": "Search",
  "Refresh": "Refresh",
  "Filter": "Filter",
  "DeactivateUser": "Deactivate User",
  "ConfirmDeactivateUser":
    "Are you sure you want to deactivate the user?",
  "Yes": "Yes",
  "No": "No",
  "NoAssignee": "No Assignee",
  "ConfiguratorQuisqueya": "Configurator Quisqueya",

  //User roles
  "ADMIN": "Admin",
  "ANALYST": "Analyst",
  "CONFIGURATOR": "Configurator",
  "AUDITOR": "Auditor",
  "SALES_MODERATOR": "Sales Moderator",
  "MESSENGER_MODERATOR": "Messneger moderator",
  "OPERATIONS_OFFICER": "Operations officer",
  "OPERATIONS_MODERATOR": "Operations moderator",
  "GENERAL_MANAGER": "General manager",
  "SALES_PERSON": "Sales Person",
  "MESSENGER": "Messenger",
  "COLLECTIONS_OFFICER": "Collections officer",
  "ANALYST_MODERATOR": "Analyst moderator",
  "COLLECTIONS_MANAGER": "Collections manager",
  "COLLECTIONS_SUPERVISOR": "Collections supervisor",
  "ADMINISTRATIVE_MANAGER": "Administrative manager",
  "CUSTOMER_SERVICE": "Customer service",
  "OPERATIONS_MANAGER": "Operations manager",
  "SALES_MANAGER": "Sales manager",
  "TECHNOLOGY": "Technology",

  //Note Types
  "ANALYST_INPUT_REFERENCE": "Analyst Input Reference",
  "ANALYST_INPUT_RISK": "Analyst Input Risk",
  "ANALYST_INPUT_REGULATOR": "Analyst Input Regulator",
  "ANALYST_APPROVE": "Analyst Approve",
  "ANALYST_INCOMPLETE": "Analyst Incomplete",
  "ANALYST_REJECTION": "Analyst Rejection",
  "DOWNPAYMENT_COLLECTION": "Downpayment Collection",
  "DOWNPAYMENT_REVERSAL": "Downpayment Reversal",
  "OPERATION_OFFICER_REJECTION": "Operation Officer Rejection",
  "EVALUATION_NOTE": "Evaluation Note",
  "SELLER_LOAN": "Seller Note",
  "PAYMENT": "Payment",
  "RESCHEDULED_LOAN_DETAILS": "Rescheduled Loan Details",
  "NEGOTIATION_REQUEST": "Negotiation Request",
  "NEGOTIATION_RESPONSE": "Negotiation Response",
  "INCONSISTENT_PAYER": "Inconsistent Payer",
  "CI_MINIMART_APPROVE": "CI Minimart Approve",
  "DISMISSED_LOAN_REQUEST": "Dismissed Loan Request",
  "SIGN_UP_REQUEST": "Sign Up Request",
  "CLIENT_STATE_CHANGE": "Client State Change",
  "FOLLOW_UP": "Follow Up",
  // Version Table
  "Version": "Version",
  "State": "State",
  "LastModified": "Last Modified",
  "Active": "Active",
  "Inactive": "Inactive",
  "Drafted": "Drafted",
  "SelectVersion": "Select Version",

  // Loan Provision
  "LoanProvisions": "Loan Provisions",
  "ListOfProvisions": "List Of Provisions",

  // Loan Status
  "LoanStatus": "Loan Status",
  "LoanStatusConfig": "Loan Status Configurations",
  "Status": "Status",
  "ArrearsFrom": "Arrears in days (From)",
  "ArrearsTo": "Arrears in days (To)",
  "Notes": "Notes",
  "W_Off": "Compulsive",
  "Legal": "Legal",

  // Late Fees
  "LateFeeConfig": "Late Fee Configurations",
  "LateFees": "Late Fees",
  "LateFeesPercent": "Late Fees %",

  // Branch List
  "Name": "Name",
  "Address": "Address",
  "SelectBranchStatus": "Select Branch Status",
  "BranchManagement": "Branch Management",
  "AddBranch": "Add Branch",

  // Dashboard
  "Dashboard": "Dashboard",
  "SearchClientOrLoan": "Search Client/Loan",
  "Reports": "Reports",
  "LoanPortfolioManagement": "Loan Portfolio Management",
  "TotalLoanPortfolio": "Total Loan Portfolio",
  "TotalLoans": "Total Loans",
  "PastDue": "Past Due",
  "PastDues": "Past Dues",
  "DefaultRate": "Default Rate",
  "AvgLoanAmount": "Avg. Loan Amount",
  "AvgTerm": "Avg. Term",
  "KnowMore": "Know More",
  "Months": "Months",
  "Years": "Years",
  "DailyUpdate": "Daily Update",
  "PortfolioRepayments": "Portfolio Repayments",
  "Date": "Date",
  "Repayment": "Repayment",
  "RepaymentNo": "Repayment No.",
  "ComingSoon": "Coming Soon",
  "Amount": "Amount",
  "From": "From",
  "To": "To",
  "FilterByAmount": "Filter By Amount",
  "FilterByInterestRate": "Filter By Interest Rate",
  "FilterByTerm": "Filter By Term",
  "LoanDisbursed": "Loan Disbursed",
  "NewLoans": "New Loans",
  "Provision": "Provision",
  "DateOfDisbursement": "Date Of Disbursement",
  "View": "View",

  // Months
  "January": "Jan",
  "February": "Feb",
  "March": "Mar",
  "April": "Apr",
  "May": "May",
  "June": "Jun",
  "July": "July",
  "August": "Aug",
  "September": "Sep",
  "October": "Oct",
  "November": "Nov",
  "December": "Dec",

  //Branch Management
  "DeactivateBranch": "Deactivate Branch",
  "DoYouWantToDeactivateBranch": "Do you want to Deactivate Branch?",
  "BranchDetails": "Branch Details",
  "BranchName": "Branch Name",
  "BranchDeactivatedSuccessfully": "Branch deactivated Successfully",
  "BranchCreatedSuccessfully": "Branch Created Successfully",
  "BranchUpdatedSuccessfully": "Branch Updated Successfully",

  //Search Client/Loan
  "NameSurname": "Name/Surname",
  "LoanId": "Loan ID",
  "DateOfBirth": "Date of Birth",
  "DNINIE": "DNI/NIE",
  "FilterByBranch": "Filter by Branch",
  "WrittenOff": "Written Off",
  "AmountDisbursement": "Amount Disbursement",
  "Interest": "Interest",
  "Term": "Term",
  "Branch": "Branch",
  "DisbursementDate": "Disbursement Date",
  "CustomerId": "Customer ID",

  "ClientLoanStatus": "Client Loan Status",
  "ToPayOffLoan": "To Pay Off Loan",
  "UpToDate": "Up to Date",
  "Capital": "Capital",
  "Arrears": "Arrears",
  "OtherCharges": "Other Charges",
  "LoanStatusReport": "Loan Status Report",
  "RepaymentSchedule": "Repayment Schedule",

  "selectBranch": "Seleccione sucursal",

  //Collections
  "LoanPortfolioStatusReport": "Loan Portfolio Status Report",
  "LoanInPastDue": "Loan in past due:",
  "DaysInPastDue": "Days in past due",
  "ProvisionClassification": "Provision Classification",
  "ByCollectionsOfficer": "By Collections Officer",
  "Apply": "Apply",
  "FromDate": "From Date",
  "ToDate": "To Date",
  "Classification": "Classification",
  "DateRange": "Date Range",

  //Configurations Common
  "CreatedBy": "Created By",
  "Set": "Set",
  "Activate": "Activate",
  "SaveDraft": "Save Draft",
  "Reset": "Reset",
  "DeleteDraft": "Delete Draft",
  "Type": "Type",
  "DaysArrears_lower": "Days Arrears >=",
  "DaysArrears_upper": "Days Arrears <=",
  "PercentWithCollateral": "% with Collateral",
  "PercentWithoutCollateral": "% without Collateral",
  "BackToTheVersionList": "Are you sure you want to go back?",
  "SuccessActivate": "Configuration activated",
  "SaveAsDraft": "Save as Draft",
  "SaveAsDraftConfirmationMessage":
    "Are you sure you want to save this loan provision configuration as a draft ?",
  "ResetConfirmationMessage":
    "Are you sure you want to reset loan provision configuration changes ?",
  "ActivateConfirmationMessage":
    "Are you sure you want to activate this loan provision configuration ?",
  "DeleteDraftConfirmationMessage":
    "Are you sure you want to delete loan provision configuration draft ?",
  "SuccessReset": "Configuration data reset successfully",
  "SuccessGetDrafted": "Fetched drafted configuration",
  "SuccessGetActive": "Fetched active configuration",
  "SuccessSaveAsDraft": "Configuration saved as draft",
  "SuccessDeleteDraft": "Deleted drafted configuration",

  "ErrorConfigurationNotFound": "Configuration not found",
  "ErrorCannotUpdateConfig":
    "Not allowed to update because you are not the owner",
  "ErrorProvisionNotDrafted":
    "Provision Configuration status is not drafted",
  "ErrorConfiguration": "There was an error",
  "ErrorInvalidConfiguration": "Invalid configuration string",
  "ErrorVersionExists": "This version already exists",
  "ErrorDraftedConfigurationExists":
    "Cannot create new configuration, as there is already one drafted.",
  "ErrorConfigurationNotDrafted":
    "Configuration status is not drafted",
  "ErrorDraftedNotFound": "No drafted configuration found",
  "InvalidConfigurationData": "Invalid configuration data",
  "ErrorDraftedConfigNotFound": "No drafted configuration found",
  "ErrorActiveNotFound": "No active configuration found",
  "ErrorEmptyValue": "Input value should be non empty.",
  "DaysArrearsRangeInvalid": "Arrear's days range must not overlap.",
  "ErrorInvalidArrearDaysRange": "Invalid arrear days range",
  "ErrorDaysRangeShouldStartWithZero":
    "Range of arrears days must start with zero",
  "InvalidPercentage":
    "Invalid percentage value (must be between 0 and 100).",
  "ErrorDaysLowerMustBeLessThanDaysUpper":
    "Lower days must be less than upper days.",
  "ErrorDuplicateTypeValues":
    "Duplicate values not allowed for 'Type'",
  "ConfigurationFetchSuccessfully":
    "Configuration Fetched Successfully",
  "ErrorBranchNotFound": "Branch not found",
  "ErrorBranchNotDefined": "Branches not defined",
  "ErrorBranchAlreadyExists": "Branch with same name already exists",
  "invalidDays":"Invalid Days Entered.",
  "maxCapitalPercentage":"Maximum Percentage of Unpaid Capital (%)",
  "startCalculationFromDay":"Days of Delay",
  "fieldsCannotBeEmpty":"Fields cannot be empty.",
  "Value":"Value",
  "lateFeesPercent": "Late Fees Percent (%)",
  "GradeEWarning": "Information: Grade E is eliminated,It is for read only for the older configurations.",
  //Pagination
  "PerPage": " per page",

  //Product Catalog
  "Descripción": "Description",

  // View Loan Application
  "ViewLoanApplication": "View Loan Application",
  "LoanApplicationDetails": "Loan Application Details",
  "InvoiceNumber": "Invoice Number",
  "TotalInstallments": "Total Installments",
  "TypeOfLoan": "Type of Loan",
  "SelectCategory":"Select Category",
  "AmountPaid": "Amount Paid",
  "TotalAmount": "Total Amount",
  "BalanceAmount": "Balance Amount",
  "References": "References",
  "TypeOfReference": "Type of Reference",
  "NameOfReference": "Name of Reference",
  "TelephoneOfReference": "Telephone of Reference",
  "AddressOfReference": "Address of Reference",
  "EMICalculation": "EMI Calculation",
  "EMIDuration": "EMI Duration",
  "EMIAmount": "EMI Amount",
  "FinancialInformation": "Financial Information",
  "monthlySalesAmount": "Monthly Sales Amount",
  "accountsPending": "Accounts Pending (FIAO)",
  "inventory": "Inventory (Approx)",
  "MonthlyIncome": "Monthly Income",
  "BasicInformation": "Basic Information",
  "businessType": "Business Type",
  "businessEstablishedSinceYears": "Business Established",
  "CalmadoTelephone": "Calmado Telephone",
  "NameOfOwner": "Name of Owner",
  "IDOfOwner": "ID of Owner",
  "MobileNumberOfOwner": "Mobile Number of Owner",
  "ownerNumber":"Mobile Number of Owner",
  "ownerAddress": "Home Address of Owner",
  "numberOfVehicles": "Number of Vehicles",
  "adminDateOfBirth": "Admin Date of Birth",
  "ownerDateOfBirth": "Owner Date of Birth",
  "ApplicationHolder": "Application Holder",
  "Expenses": "Expenses",
  "numberOfEmployees": "No. of Employees",
  "salaryOfEmployees": "Salary Employee",
  "rent": "Rent",
  "electricity": "Electricity",
  "telephone": "Telephone",
  "CableInternet": "Cable/Internet",
  "GasOfDelivery": "Gas of Delivery",
  "DebtsToSuppliers": "Debts to Suppliers",
  "AdministrationInformation": "Administration Information",
  "NameOfAdministration": "Name of Administration",
  "adminId": "ID of Administration",
  "adminNumber": "Mobile No. of Administration",
  "SiteVisit": "Site Visit",
  "NoOfOwnedDeliveryMotors": "No. of Owned Delivery Motors",
  "NoOfExhibitionFridges": "No. of Exhibition Fridges",
  "NoOfBotellero": "No. of Botellero",
  "NoOfCars": "No. of Cars",
  "TypeOfLocal": "Type of Local",
  "NoOfTV": "No. of TV’s",
  "NoOfTelephone": "No. of Telephone",
  "NoOfFreezers": "No. of Freezers",
  "NoOfExhibitors": "No. of Exhibitors",
  "Evidence": "Evidence",
  "Invoice": "Invoice",
  "PhotoID": "Photo ID",
  "INSUFFICIENT_LOAN_APPLICATION_DATA": "Insufficient loan application data.",
  "Arrears_in_Days": "Arrears in Days",
  "Number_of_days_the_EMI_is_delayed": "Number of days the EMI is delayed",
  "conciliatedLoanDetailsFor": "Conciliation Loan Details for",
  "restructureDetailsFor":"Restructure Details for",
  "CreatedLoanDetails":"Details of loan created after restructuring ",
  "ClosedLoanDetails":"Details of loan closed after restructuring",
  "ClosedRestructuredLoan":"Closed Restructured Loan",
  "CreatedRestructuredLoan": "Created Restructured Loan",
  "LoanCreatedByRestructuring":"Loan generated after restructuring $ ",
  "LoanClosedByRestructuring":"This loan was restructured $ ",
  "viewMoreDetails": "View More Details",
  "closedByConciliation": "Closed by Conciliation",
  "closedByRestructuring": "Closed by Restructuring",
  "conciliationByApplicationID": "Conciliation by Application ID",
  "createdByRestructuring": "Created By Restructuring",
  "createdLoanID": "Created Loan ID",
  "closedLoanID": "Closed Loan ID",
  "ClosedApplicationID": "Closed Application ID",
  "CreatedApplicationID": "Created Application ID",
  "closed": "Closed",
  // CI Wire Transfer
  "CIWireTransferTitle": "CI Wire Transfer",
  "TotalPaidAmount": "Total Paid Amount",
  "TotalUnPaidAmount": "Total Unpaid Amount",
  "PaidInvoiceLabel": "Paid Invoice",
  "UnPaidInvoiceLabel": "Unpaid Invoice",
  "ReportFileName": "CI_Wire_Transfer_Report",

  // Daily Status
  "created": "Created :  ",
  "disbursed": "Disbursed :  ",
  "pending": "Pending :  ",
  "USER_DEACTIVATION_FAILED":
    "Some minimarts are still associated with the user. Please remove all the zones and try to deactivate on next working day.",

  "approvedAmount": "Approved Amount",
  "Interestrate": "Interest Rate",
  "approvedInterestRate":"Approved Interest Rate (%)",
  "blockedMinimartWarning": "This minimart is blocked",
  "askToBlockTheMinimart": "Do you want to block the minimart? ",
  "askToUnlockTheMinimart": "Do you want to Unblock the minimart? ",
  "BLOCK": "BLOCK",
  "BLOCKED": "BLOCKED",
  "UNBLOCK": "UNBLOCK",
  "minimartStatusUpdatedSuccessfully": "Minimart status updated successfully",

  "SelectBranchToApprove": "Select branch to approve",
  "supervisor": "Supervisor",
  "selectSalesModerator": "Select Sales Moderator",
  "selectFileStatus": "File Status",
  "viewNote": "See note",
  "noteId": "Notes",
  "SalesPersonNotes":"Salesperson Notes",
  "referenceNotesbyAnalyst": "Reference Notes by Analyst",
  "financialRiskAssessmentNotesbyAnalyst": "Financial Risk Assessment Notes by Analyst",
  "regulatorsNotebyAnalyst": "Regulators Notes by Analyst",
  "errorEmptyNote": "Please add a note ",
  "NoteForBlackListingCedula": "Note is mandatory.",
  "NoteDetails":"Note Details",
  "applicationId(S)": "ID of Application(s)",
  "charactersAllowed": "Only characters are allowed",

  "RENTED": "RENTED",
  "OWNED": "OWNED",
  "MOTORCYCLE": "Appliances and Equipments",
  "DELETE": "DELETE",
  "OWNER": "OWNER",
  "resetGradeText": "Are you sure you want to reset the grade?",
  "RANGE": "RANGE",
  "YES_NO": "Yes or No",
  "MULTI_VALUE": "MULTI VALUE",
  "imageUploadFailed": "Image loading failed, please try again.",
  "sizeIsTooLarge":
    "The file is too large. The maximum size allowed is 5 mb",
  "fileExtension":
    "Only files with the following extensions are allowed: png, jpg, jpeg",
  "fileExtensionErrorOfTranferReceipt":
    "Only files with the following extensions are allowed: png, jpg, jpeg, pdf",
  "invalidLateFeesRequest":
    "Invalid late fees take off request. Current late fees are less than the approved take off late fees. Discarding this request!",
  "INVALID_AMOUNT":
    "Pay an amount of 50% of the active cash top-up loan to get the new.",
  "ExistingSalesPersonUser": "Existing SalesPerson User",
  "ExistingMessengerUser": "Existing Messenger User",
  "NewSalesPersonUser": "New SalesPerson User",
  "NewMessengerUser": "New Messenger User",
  "None": "None",
  "ChangePosition": "Change Position",
  "salesPosition":
    "The salesperson Position $ will be vacant. Please confirm.",
  "messengerPosition":
    "The messenger Position $ will be vacant. Please confirm.",
  "salesPositionAssign":
    "The Salesperson $ will be deactivated. The New Salesperson $ will be assigned at position $. Please confirm.",
  "messengerPositionAssign":
    "The Salesperson $ will be deactivated. The New Salesperson $ will be assigned at position $. Please confirm.",
  "salesSwapPosition":
    "The position of $ will be exchanged with the workload of $. Please confirm.",
  "messengerSwapPosition": " will swap position with ",
  "pleaseSelectUser": "Please select user.",
  "assignmentDoneSuccessfully": "Assignment done successfully.",
  "assignmentFailed": "Assignment Failed.",
  "searchedZones": "List of searched zones.",
  "Terms": "Términos",
  "OtherTerms": "Otros Terminos",
  "POSITION_NOT_FOUND": "Position not found.",
  "POSITION_NAME_ALREADY_PRESENT":
    "Position name is already present in the system! Please enter different position name.",
  "messengerPositionText": "Messenger Position",
  "salesPersonPostion": "Sales Person Position",
  "BRANCH_ZONE_MAPPING_NOT_FOUND": "Branch zone mapping not found.",
  "ROUTE_NOT_FOUND": "Route not found.",
  "positionNameLengthError":
    "Position name should be 5 to 7 characters.",

  "NO_MESSENGER_FOUND_FOR_NEW_MESSENGER_POSITION":
    "No messenger found for new messenger position.",
  "NO_MESSENGER_FOUND_IN_THE_ZONE":
    "Ninguna mensajera encontrada en la zona",
  "positionChanged": "The position changed successfully.",
  "positionChangedAssignmentsNotDone": "Position changed successfully. Changes will reflect in few minutes.",
  "addPosition": "The position was added successfully.",
  "NO_WORKLOAD_FOR_POSITION":
    "The position $ of zone $ do not have extra workload.",
  "INVALID_CREDIT_LIMIT_AMOUNT":
    "The requested amount must be greater than existing amount",
  "ELIGIBILITY_CRITERIA": "ELIGIBILITY_CRITERIA",
  "editPosition": "Edit Position",
  "NO_MESSENGER": "No messenger assign for zone $ of position $",
  "HigherCreditLimitErroMessage":
    "Credit limits must be lower than next levels",
  "LowerCreditLimitErrorMessage":
    "Credit limits must be higher than previous levels",
  "salesVacantPositionAssign":
    "The New Salesperson $ will be assigned at position $. Please confirm.",
  "messengerVacantPositionAssign":
    "The New Messenger $ will be assigned at position $. Please confirm.",
  "USE_WORKLOAD_MANAGEMENT_ERROR":
    "Cannot deactivate messenger user from here, use workload management.",
  "MESSENGER_POSITION_NOT_FILLED_FOR_ROUTE":
    "Messenger's position not filled for the route.",

  "signupRequestDetails": "Signup request Details",
  "matchingMinimartDetails": "Matching Minimart Details",
  "EditAssociation": "Edit Association",
  "ExistingMinimart": "Please select existing minimart.",
  "MinimartExisting": "Existing  minimart selected.",
  "NOTE_NOT_FOUND": "Note not found.",
  "REQUEST_NOT_FOUND": "Request not found.",
  "MINIMART_NOT_FOUND": "minimart not found",
  "StopAssignment": "Stop Assignment",
  "StopAssignmentSuccessfully": "Stop Assignment Successfully.",
  "matchType": "Match Type",
  "CODE": "Code Matching",
  "NAME": "Name Matching",
  "OWNERID": "Owner Id Matching",
  "ADDRESS": "Address Matching",
  "minimartDetailsUpdate":
    "Minimart details were updated successfully.",
  "selectMinimartGrade": "Select Minimart Grade",
  "selectMinimartType": "Select Minimart Type",
  "selectMinimartLevel": "Select Minimart Level",
  "largetPercentage": "Larget Percentage",
  "SelectLoanType": "Type of Loan",
  "loanAmount": "Loan Amount",
  "pleaseSelectGrade": "Please select grade.",
  "pleaseSelectType": "Please select type.",
  "pleaseSelectGradeType": "Please select grade and type.",
  "remittanceError":
    "The remittance raise request has been failed due to the core server is not reachable! Please disburse this loan manually.",
  "ANALYSED_BY_SYSTEM": "Analysed By System",
  "InstallmentArrearsFrom": "Installments in arrears (Lower)",
  "InstallmentArrearsTo": "Installments in arrears (Upper)",
  "homeLocation": "Home Location",
  "socialMediaAccountDetails": "Social Media Accounts Details",
  "bankDetail": "Bank Detail",
  "CIZone": "CIZone",
  "Mother": "Mother",
  "Husband": "Husband",
  "invalidRange": "Invalid range of input",
  "conciliationApprove": "Conciliation Approve",
  "conciliationWarning":
    "Refer to the previous loan application id $ to see the details of the debt.",
  "conciliationAccess": "Conciliation Access",
  "conciliationDeatails": "Conciliation Deatails",
  "unpaidCapital": "Unpaid Capital ($)",
  "totalDebt": "Total Debt ($)",
  "atmAmount": "ATM Amount ($)",
  "editConciliationDeatails": "Edit Conciliation Deatails",
  "unpaidLateFees": "Unpaid Late Fees",
  "conciliationInterest": "Conciliation Interest",
  "conciliationLateFees": "Conciliation Late Fees",
  "conciliationHistory": "Conciliation History",
  "ssn": "This loan is created by special sales person $.",
  "loanPayOffText": "Do you want to pay off this loan?",
  "conciliationError":
    "The approved amount should be greater than the total unpaid debt of the old loan.",
  "tempPasswordSendToMobileNumber": "Temporary password will be send to",
  "loanFrom": "Loan Created from",
  "specialCharError":
    "Special characters are not allowed in the name.",
  "validUnpaidInterestError": "Enter valid unpaid interest amount.",
  "pdfDownload": "Please check your email for PDF/CSV download.",
  "addZone": "Add New Zone",
  "addedZone": "The zone was added successfully.",
  "Respectively": "respectively",
  "MINIMART_NOTES_REPORT": "Minimart_notes_report",
  "LOAN_NOTES_REPORT": "Loan_notes_report",

  //MTP PROMOTIONS
  "promotionStatus": "Promotion Status",
  "promotionType": "Promotion Type",
  "INTERNAL": "INTERNAL",
  "EXTERNAL": "EXTERNAL",
  "productType": "Product Type",
  "benefitType": "Benefit Type",
  "installment": "Installment",
  "Discount": "Discount",
  "min": "Minimum",
  "max": "Maximum",
  "rewardPointsRange": "Enter Valid reward points range.",
  "promotionCount": "Promotion Count",
  "promotionAmount": "Promotion Amount",
  "installmentIds": "Installment Ids",

  "INVALID_AMOUNT_ON_PROMOTION":
    "The requested amount is not valid for this loan due to promotion.",
  "APPROVED_BY_SYSTEM": "Aprpoved By System",
  "minLoanAmount": "Mininmum Loan Amount",
  "orderId": "Order Id",
  "orderIdError":
    "Please save the order id before disbursing the loan.",

  "MULTIPLE_ACTIVE_LOANS":
    "You have multiple active loans $. Please pay off active loans to be available for the new one.",
  "INVALID_LOAN_COMBINATION":
    "The requested loan $ does not have a valid combination with the active loans #. \n Valid combinations : ^",
  "cedulaWarning":
    "This is not a $ cedula corresponding to mini mart owner in past loans (#).",
  "cedulaMODWarning":
    "This cedula $ already exists for the minimarts #.",
  "cedulaHistory": "Cedula History",
  "downloadFrontPhoto": "Download Front Photo",
  "downloadBackPhoto": "Download Back Photo",
  "oldCedulaNumber": "Old Cedula Number",
  "newCedulaNumber": "New Cedula Number",

  //Rewards
  "RewardCatalog": "Reward Catalog",
  "AddReward": "Add Reward",
  "addRewardDetails": "Add Reward Details",
  "updateRewardDetails": "Update Reward Details",
  "expiryInMonths": "Expiry In Months",
  "expiryError": "Enter valid expiry months",
  "rewardAdded": "Reward added successfully.",
  "rewardUpdated": "Reward updated successfully",

  //Reward Approval
  "rewardApprovalFeatureTitle": "REWARDS APPROVAL PANEL",
  "rewardApprovalPageTitle": "Rewards Approval Panel",
  "rewardApprovalDetailsTitle":
    "Detalles de Aprobación de Recompensas",
  "rewardInfo": "Reward Info",
  "totalRewardPoints":
    "Reward points of minimart after approving this request will be : ",
  "PRODUCT_NOT_FOUND": "Product not found.",
  "FILE_UPLOAD_FAIL": "Failed to upload file.",
  "INSUFFICIENT_REWARD_POINTS":
    "You have insufficient reward points to order this product.",
  "rewardHistory": "Reward History",
  "rewardType": "Reward Type",
  "SPENT": "Spent",
  "EARNED": "EARNED",
  "LOAN_CLOSURE": "Loan Closure",
  "PRODUCT_PURCHASE": "Product Purchase",
  "rewardReason": "Reward Reason",
  "insuffientRewardPoints":
    "This minimart has insufficient reward points for approval. Please reject this request.",

  "creationDate": "Creation Date",
  "forgotPassword": "Forgot Password", //
  "MINIMART_BLOCKED": "Minimart is blocked.",

  "selectApprovalType": "Select Approval Type",
  "MANUAL_APPROVED": "Manual Approved",
  "AUTO_APPROVED": "Auto Approved",

  "areNeighbourReferencesSatisfactory":
    "Are neighbours reference satisfactory?", //
  "referralReward": "Referral Reward",
  "whatsAppNum": "WhatsApp number?",

  "mergeMinimart": "Merge Minimart",
  "DISABLED_MERGE_MINIMART_BUTTON": "Minimart can not be merged as messenger is not assigned",
  "merge": "Merge",
  "mergeMinimartWarning": "Minimart $ will be merge to minimart $$",
  "minimartMergedSuccessfully": "Minimart Merged Successfully",
  "mergeMinimartAlert": "Warning: The selected minmart will be removed from the system after merging.",
  "paymentReverse": "Payment Reverse",
  "paymentReverseWarning":
    "Are you sure you want to reverse all of today's payments?",
  "paymentReverseSuccessfully": "Payment Reversed Successfully.",
  "EditLoanDetails": "Edit loan details",
  "loanDetailsPdfDownloadNotAllowed": "Account statement download can be possible after disbursement of the loan.",


  //Messenger Assignment
  "assignMessenger": "Do you want to assign a messenger?",
  "messengerAssignedSuccess": "Messenger assigned successfully.",
  "messengerAssignment": "Assign a messenger",
  "DISAPPEARED": "Uncollectible",
  "PAYMENT_AGREEMENT": "PaymentAgreement",

  //Minimart Address
  "Sector": "Sector",
  "Street": "Street",
  "Reference": "Reference",

  //Add Invoice
  "AddInvoice": "Add Invoice",
  //Invoice Approval
  "invoiceApprovalPageTitle": "Invoice Approval",
  "invoiceApprovalFeatureTitle": "INVOICE APPROVAL",
  "addInvoiceSuccess": "Invoice added successfully.",
  "invoiceDetails": "Invoice Details",
  "invoiceAPPROVED": "Invoice approved successfully.",
  "invoiceREJECT": "Invoice rejected successfully.",

  //EMI Configuration
  "frequency": "Frequency",
  "WEEKLY_ONCE": "Weekly",
  "WEEKLY_TWICE": "2 times a week",
  "WEEKLY_THRICE": "3 times a week",
  "WEEKLY_SIX": "Daily",
  "MONTHLY_ONCE": "Monthly",
  "MONTHLY_TWICE": "twice a month",

  "markExceptional": "Mark Exceptional",
  "markNormal": "Mark Normal",
  "markExceptionalWarn":
    "Do you want to mark this minimart as exceptional",
  "markNormalWarn": "Do you want to mark this minimart as normal?",
  "thresholdLoanAmount": "Threshold loan amount",
  "requestedFrequency": "Requested Frequency",
  "approvedFrequency": "Approved Frequency",

  //Errors
  "PAYMENT_NOT_FOUND": "No payment found for a day.",
  "REVERT_PAYMENTS_NOT_POSSIBLE":
    "Can not revert the payments because some other action has been taken after the payment",

  "minimartOwnerVerification":
    "Minimart owner verification details failed :",
  "ALTERATION_DETECTION": "The document has been altered.",
  "FACEMATCH_FAILED": "The face does not match.",
  "ID_DOC_FACEMATCH_FAILED":
    "The face does not match with ID document.",
  "LOCATION_ACCURACY_FAILED":
    "The location is not accurate enough to proceed.",
  "IP_FAILED": "The IP validation has failed.",
  "PROXY_FAILED": "The VPN/Proxy validation has failed.",
  "GEO_RESTRICTION_FAILED": "The user is in the geo-restricted area.",
  "REMITTANCE_RAISED_ERROR":
    "Received an error from the remittance core server while raising the remittance automatically, Please try again! ",
  "MESSENGER_ALREADY_ASSIGNED":
    "Messenger is already assigned today.",
  "NOTHING_TO_COLLECT":
    "The collection amount is zero, cannot assign messenger to a minimart.",
  "MINIMART_SPLIT_ADD_ERR":
    "Please split the minimart address into city, sector, street, number & reference to approve minimart.",
  "THE_LOAN_STATE_CANNOT_BE_CHANGED":
    "The loan state cannot be changed.",
  "INVOICE_BALANCE_ERROR":
    "The invoice balance should be less than the invoice amount.",
  "INVOICE_NOT_FOUND": "Invoice not found.",
  "ROUTE_NOT_ASSIGNED_FOR_MINIMART":
    "Route not assigned to the minimart.",
  "FREQ_ERROR": "Please select frequency.",
  "DISAPPEARED_LOAN_CANNOT_PAYOFF":
    "We cannot process payoff request for a disappeared loan.",
  "PAYMENT_CANNOT_BE_REVERTED": "Payment cannot be reverted.",
  "NOTE_INVALID_CONTEXT":"Note Context is Invalid.",
  //Meta warnings
  "nameMismatchWarning":
    "The match between the name of the photo identification and the name of the owner of the Minimart failed.",
  "Document is considered as negligence":
    "Document is considered as negligence",
  "Document is considered as fraud attempt":
    "Document is considered as fraud attempt",
  "Document is considered tampered":
    "Document is considered tampered",
  "Cannot meet dependent input": "Cannot meet dependent input",
  "Document did not match any valid template":
    "Document did not match any valid template",
  "Cannot meet dependent node": "Cannot meet dependent node",
  "Service unavailable": "Service unavailable",
  "Step timeout": "Step timeout",
  "Document number is not defined": "Document number is not defined",
  "This user has been verified more than once":
    "This user has been verified more than once",
  "Birth Date is not defined": "Birth Date is not defined",
  "Full Name is not defined": "Full Name is not defined",
  "selfie_id download error": "selfie_id download error",
  "doc_photo_id download error": "doc_photo_id download error",
  "The face did not match the document":
    "The face did not match the document",
  "Request is rejected when call 'visionAgent.facematch' action on 'mol-govservices-xxx' node.":
    "Request is rejected when call 'visionAgent.facematch' action on 'mol-govservices-xxx' node.",
  "The date of birth could not be obtained":
    "The date of birth could not be obtained",
  "This user is currently underage":
    "This user is currently underage",
  "METAMAP_AUTHENTICATION_FAILED": "Metamap authentication failed",
  "FAILED_RO_GET_MEDIA_URL": "Metamap: Failed to retrieve media url",
  "INVALID_COLLECTION_CYCLE_CHANGE_REQUEST":
    "Cannot change collection days for Closed/Inactive loan",
  "INVALID_COLLECTION_CYCLE_CHANGE":
    "Inavalid change collection days cycle request",
  "PROVISION_SCHEME_ERROR":
    "The provision scheme has been updated with new rules. It is available for the loans disbursed after 18th February 2022.",

  //LEVEL Configuration
  "minCreditScore": "Minimum Credit Score",
  "minla": "Minimum loan amount",
  "maxla": "Maximum loan amount",
  "flma": "First loan maximum amount",
  "slma": "Second loan maximum amount",
  "minimumLoanAmountWarning":
    " Minimum loan amount should be less than maximum loan amount.",
  "maximumLoanAmountWarning":
    "Maximum loan amount should be less than or equal to Credit limit.", //
  "errorDuplicateLoanType": "Duplicate loan type",

  //Credo
  "credoCreditScore": "Credo Credit Score",
  "minCredoCreditScore": "Minimum Credo Credit Score", //
  "lowCredoCreditScoreWarning": "Credo credit score is low",

  //Credo error codes
  "CREDO_DATASET_INSIGHT_RESPONSE_PARSING_FAILED":
    "Dataset insight response parsing failed.",
  "CREDO_LOAN_APPLICATION_NOT_FOUND":
    "Loan application id not found in Credo repository.",

  "oldPasswordSameAsNew": "Old password is same as new password.",
  //Loan details screen warnings
  "EMIRoundOffWarning":
    "The unpaid capital might differ from the loan amount by some pesos because of the EMI round-off.", //

  "truckVisitDay": "Truck visit day",
  "lateFeesWarning":
    "Late fees will not be calculated for this loan because it's in a",
  "oldMinimartDetails": "Old minimart details", //

  "scalaRegistrationWarning":
    "By default existing minimart information will be saved. If you want to change any field on the basis of new request data, Please edit the information.", //

  "stopAtOperationsAmount":
    "Stop the loan at Operations if amount is greater than", //

  "The cedula id from application and meta do not match":
    "The cedula id from application and meta do not match",
  "sellerZones": "Seller zones",
  "messengerZones": "Messenger zones",
  "EXISTING_POSITION": "Existing position",
  "NEW_POSITION": "New position",
  "positionName": "Position Name",
  "BRANCH_CANT_BE_NULL": "Branch cannot be null",
  "ZONE_ALREADY_EXISTS": "Zone name already exists",
  "NO_MESSENGER_FOUND_FOR_GIVEN_POSITION": "Please select non vacant messenger position so that minimart cannot be orphan",
  "EXISTING_ZONE": "Existing zone",
  "NEW_ZONE": "New zone",
  "notWorking": "This feature is currently not working",
  "selectZones": "Select zones",
  "unBarMinimart": "Unbar Minimart",
  "minPaidPercent": "Loan Renewal Percentage",
  "renewalCriteria": "Renewal Criteria",
  "CASH_TOP_UP_LOAN_IS_ALREADY_ACTIVE": "The minimart already has an active cash top up loan. The requested loan cannot be approved. Please approve the loan with conciliation.",
  "INVALID_LOAN_APPROVAL_REQUEST": "The minimart already has 2 active loans. The requested loan cannot be approved. Please approve the loan with conciliation.",

  "AllMinimarts": "All minimarts",
  "scala_registered": "Scala registered",
  "non_scala_registred": "Non scala registered",
  "REPORT_FOR_DISBURSED_LOAN": "Report can only be created for disbursed loan.",
  "REPORT_NOT_AVAILABLE_FOR_OLD_LOAN": "Report is not available for old loan.",
  "AppDataIsNotFilled": "Note : This minimart has disbursed loan whose data is not filled.",
  "AppDataIsNotFilledForLoan": "Note : Application data is not filled for this loan.",
  "appliedDaysInDelays": "Applied Delay in Days",
  "appliedMaxPercentage": "Applied Max Percentage of Unpaid Capital (%)",

  //Blacklist client
  "CLIENT_PORTAL": "Client portal",
  "clientPortal": "ClientPortal",
  "clientId": "Client Id",
  "identificationNumber": "Identification number",
  "selectClientState": "Select client status",
  "noteType": "Note Type",
  "selectIdentificationType": "Select identification type",
  "identificationType": "Identification type",
  "customerName": "Customer name",
  "clientBlacklisted": "Blacklisted client",
  "CEDULA": "Cedula",
  "PASSPORT": "Passport",
  "clientStatusChange": "Changing the status of Client : ",
  "confimationWarningToBlacklist": "Are you sure you want to blacklist this client?",
  "confimationWarningToRemoveFromBlacklist": "Are you sure you want to remove this client from blacklist?",
  "activeLoansList": "Following are the active loan applications of this client:",
  "OWNER_OF_MINIMART_IS_BLACKLISTED": "Owner of minimart is blacklisted",
  "statusChangedSuccessfully": "Successfully changed the status.",
  "ALREADY_ASSIGNED_POSITION": "Already assigned position",
  "editOldMinimartDetails": "Edit old minimart details",
  "editCedula": "Edit cedula",
  "CLIENT_ALREADY_EXIST": "Client already exists.",
  "sameCedulaError": "Entered cedula id is same as old requested cedula id.",
  "CLIENT_NOT_FOUND": "Client for which Cedula needs to be updated not found in DB.",
  "INVALID_CEDULA_UPDATE_REQUEST": "Invalid cedula change is requested.",
  "CEDULA_DETAILS_MISMATCH": "Details mentioned in cedula change request are not as per system's records.",

  //Ci loan request report
  "CILoanRequestReport": "CI Loan Request Report",
  "minimartCode": "Minimart Code",
  "selectStatus": " Select Status",
  "REQUEST_CREATED": "Request Created",
  "PROCESSED_WITH_FAILURE": "Proceed with Failure",
  "CIInvoiceAmount": "Monto",
  "CIInvoiceBalanceAmount": "Balance",
  "CIInvoiceDate": "Fecha de Creación",
  "CIInvoiceExpiryDate": "Expiración",
  "CIInvoiceCreatedDate": "Fecha solicitada",
  "invalidData": "Por favor introduce un dato válido",
  "CI_LOAN_REQUEST_REPORT": "Informe_de_Solicitud_de_Préstamo_Ci",


  //Meta configuration
  "META_MAP_CONFIGURATION": "Meta map configuration",
  "META_ENABLED_STATUS": "META ENABLED STATUS",
  "isMetaEnabled": "Is meta map enabled?",
  "metaIsEnabled": "Meta map is enabled.",
  "metaIsDisabled": "Meta map is disabled.",
  "ConfigurationManagement_META_ENABLED_STATUS": "Meta map configuration",

  //Supplier configuration
  "BANK_DETAIL_CONFIGURATION": "Bank Detail Configuration",
  "bankDetailConfiguration": "/bankDetailConfiguration",
  "accountTypes": "Account Types",
  "accountType": "Account Type",
  "bankDetails": "Bank Details",
  "bankName": "Bank Name",
  "bankInfo": "Bank Information",
  "confimationWarningToDisableAccountType": "Do you want to disable the account type?",
  "confimationWarningToEnableAccountType": "Do you want to enable the account type?",
  "enableWarningOfAssociatedBanks": "Selected account type will be enabled for all the associated banks.",
  "disableWarningOfAssociatedBanks": "Selected account type will be disabled for all the associated banks.",
  "warningForBanklist": "The following banks have only one account type associated. Hence, these banks will be disabled, if this account type is disabled:",
  "activeAccount": "Account is active",
  "inActiveAccount": "Account is inactive",
  "activeBank": "Bank is active",
  "inactiveBank": "Bank is inactive",
  "minimumThreeCharacterLength": "Minimum character length should be 3.",
  "INVALID_REQUEST": "Invalid Request.",
  "REQUEST_ALREADY_EXISTS": "Request already exists.",
  "ACCOUNT_DETAILS_NOT_FOUND": "Account not found for this bank. Please add account type.",
  "transferReceipt": "Transfer Reciept",
  "FILE_UPLOAD_NOT_ALLOWED": "File upload not allowed.",
  "supplierInactiveWarning": "The selected supplier for this loan is inactive.",
  "bankInactiveWarning": "The selected bank details are inactive in the system.",
  "manageSupplier": "/manageSupplier",
  "addSupplier": "Add Supplier",
  "businessName": "Business Name",
  "contact": "Contact",
  "accountNumber": "Account Number",
  "SupplierCatalog": "Supplier Catalog",
  "updateSupplier": "Update supplier",
  "MANAGE_SUPPLIER": "MANAGE SUPPLIER",
  "supplierInfo": "Supplier Information",
  "sdt": "Selected disbursement type",
  "stat": "Selected account type",
  "Browse": "Browse",
  "DELIVERY": "Delivery",
  "TRANSFER": "Transfer",
  "PERSONAL_ACCOUNT": "PERSONAL ACCOUNT",
  "SUPPLIER_ACCOUNT": "SUPPLIER ACCOUNT",
  "TRANSFER_RECEIPT_NOT_FOUND": "Please upload transfer receipt to disburse loan.",
  "rnc": "RNC",
  "supplierAddedSuccessfully": "Supplier Added Successfully",
  "supplierUpdatedSuccessfully": "Supplier Updated Successfully",
  "bankNames": "Bank Names",
  "addAccountType": "Add Account Type",
  "addBank": "Add Bank",
  "editAccountType": "Edit Account Type",
  "selectBankStatus": "Select Bank Status",
  "selectSupplierStatus": "Select Supplier Status",
  "SUPPLIER_ALREADY_EXIST": "Supplier with the same RNC already exist.",
  "RNC_NOT_FOUND": "RNC not found",
  "RNC_SERVICE_NOT_ACCESSIBLE": "RNC service is not accessible",
  "businessNameNotValid": "Business name not valid",
  "contactNotValid": "Contact not valid",
  "phoneNumberNotValid": "Phone Number not valid",
  "bankNameNotValid": "Bank name not valid",
  "accountTypeNotValid": "Account Type not valid",
  "accountNumberNotValid": "Account Number must be in between 6 to 30 digits",
  "statusNotValid": "Status not valid",
  "ACCOUNT_NUMBER_PATTERN_MISMATCH": "Account number is not numeric.",
  "RNCVerification": "RNC Verification",
  "RNC_Unverified_Warning": "RNC for this supplier is unverified. On saving the information reverification will be attempted.",
  "Verified": "Verified",
  "UnVerified": "Unverified",
  "phoneNumber": "Telephone",
  "supplierLogo":"Supplier Logo",
  //Payment Frequencies Configuration
  "exceptional": "Exceptional",
  "nonExceptional": "Non Exceptional",
  "restructure": "Restructure ($) : ",
  "PAYMENT_FREQUENCY": "Payment frequency",
  "LeveleFreuenciesCantBeEdited": "Warning: This is an archived configuration and cannot be edited here. To edit level frequencies, see the payout frequency section.",
  //Loan Status and Grade Configuration
  "LOAN_STATUS_AND_GRADE": "Loan Staus and Grade Configuration",
  "Other": "Other",
  "repeatedDaysPastDue": "The past due days should not be repeated.",
  "repeatedTerms": "The terms should not be repeated.",
  "daysInAscendingOrder": "The grades are not in ascending order.",
  "zeroTermError": "The term cannot be 0.",
  //GeoLocation
  "GeoLocation": "Geo Location",
  "latitude": "latitude",
  "longitude": "longitude",
  "metaLatitude": "Meta latitude",
  "metaLongitude": "Meta longitude",
  "latitudeValidRange": "Latitude should be in the range of -90 to 90 degrees.",
  "longitudeValidRange": "Longitude should be in the range of -180 to 180 degrees.",

  //Deactivation
  "userAvailability": "User availability",
  "USER_CANT_DEACTIVATE_ITSELF": "You cannot deactivate yourself. Please request authorized user.",
  "USER_HAVE_SUBORDINATES": "This user have subordinates. Please distribute them in order to deactive the user.",
  "USER_HAVE_ONGOING_LOANS": "This user has work assigned for today. You can deactivate him after the work is completed.",
  "NO_ADJACENT_USER_FOUND": "Cannot deactivate the user as there is no adjacent user found in the system.",
  "USER_NOT_FOUND_TO_HANDLE_WORKLOAD": "No any user available to handle workload of original user.",
  "WorkloadDistributionModal": "Workload Distribution",
  "canDeactivateModerator": "This user does not have any subordinates. You can deactivate the user.",
  "canDeactivateUser": "This user does not have work assigned for today. You can deactivate the user.",
  "deactivated": "This user is deactivated",
  "Deactivated": "Deactivated",
  "userDeactivatedSuccessfully": "User Deactivated Successfully",

  //Unavailability
  "USER_CANT_UNAVAILABLE_ITSELF": "You cannot mark yourself unavailable. Please request authorized user.",
  "AVAILABLE_USER_HAVE_SUBORDINATES": "This user have subordinates. Please distribute them in order to mark the user unavailable.",
  "AVAILABLE_USER_HAVE_ONGOING_LOANS": "This user has work assigned for today. You can mark him unavailable after the work is completed.",
  "NO_ADJACENT_AVAILABLE_USER_FOUND": "Cannot mark the user unavailable as there is no adjacent user found in the system.",
  "ConfirmAvailableUser": "Do you want to mark this user available?",
  "ConfirmUnavailableUser": "Do you want to mark this user unavailable?",
  "Availability": "Availability",
  "canUnavailableModerator": "This user does not have any subordinates. You can mark the user unavailable.",
  "canUnavailableUser": "This user does not have work assigned for today. You can mark the user unavailable.",
  "canAvailableUser": "Do you really want to mark the user available?",
  "available": "This user is availablee",
  "unavailable": "This user is unavailable",
  "unavailableReason": "You cannot make this user unavailable because",
  "workloadCountDisplay": "Number of loans to be distributed :",
  "sameInput": "No new change to save",
  "LoansToConciliateWith": "Loans to conciliate with",
  "Available": "Available",
  "Unavailable": "Unavailable",
  "userIsUnavailableNow": "User is unavailable now.",
  "userIsAvailableNow": "User is Available now.",

  //Update loan
  "LOAN_UPDATE_NOT_ALLOWED": "Loan Update Not Alloed.",
  "ageMustBeInBetweenTwentyOneToSixtyFive": "Age must be in between 21 to 65.",
  "BASIC_INFO_CANNOT_BE_NULL": "Basic info of loan cannot be null.",
  "INVALID_BASIC_INFO_DATA": "Some fields of the basic info contains data not present in the configuration.",
  "INVALID_INCOME_DATA": "Some fields of the income contains data not present in the configuration.",
  "INVALID_SITE_VISIT_DATA": "Some fields of the site visit contains data not present in the configuration.",
  "INVALID_EXPENSES_DATA": "Some fields of the expense contains data not present in the configuration.",
  "formValuesNotPresentInConf": "Some fields of the loan application are not present in the configuration, please check.",

  //Conciliation
  "EMI_CALCULATION_NOT_FOUND": "EMI calculation not found.",
  "NEW_LOAN_AMOUNT_INSUFFICIENT_FOR_CONCILIATION": "The amount requested for the new loan is less than the repayment amount of the active loan.",
  "CASH_TOPUP_LOAN_IS_ACTIVE": "A cash top up loan is already active so any other loan can not be conciliated.",
  "RAISE_REMITTANCE_BEFORE_DISBURSEMENT": "Please raise remittance before disbursement.",
 "approvedLoanPresentError":"This business has approved loan(s) (#) with a debt of #",
  //Administrative expense configuration
  "ConfigurationManagement_ADMINISTRATIVE_EXPENSE_DATA": "ADMINISTRATIVE EXPENSE",
  "ADMINISTRATIVE_EXPENSE_DATA": "ADMINISTRATIVE EXPENSE",
  "administrativeExpense": "Administrative Expense ($)",
  "amountCharged": "Amount Charged ($)",
  "receiptIds": "Receipt Ids",

  //Change role
  "changeRole": "Change Role",
  "changeRoleWarning": "You are in the process of change role.",
  "workloadWarning": "This user has workload, You will have to distribute the workload before changing the role.",
  "notAllowedToChangeToThisRole": "You are not allowed to change to this role.",
  "USER_CANT_CHANGE_ROLE_ITSELF": "You cannot change role yourself. Please request authorized user.",
  "USER_HAVE_SUBORDINATES_TO_CHANGE_ROLE": "This user have subordinates. Please distribute them in order to change role of the user.",
  "USER_HAVE_ONGOING_LOANS_TO_CHANGE_ROLE": "This user has work assigned for today. You can change role of him after the work is completed.",
  "NO_ADJACENT_USER_FOUND_TO_CHANGE_ROLE": "Cannot change role of the user as there is no adjacent user found in the system.",
  "confirmChangeRole": "Do you want to change the role?",
  "sameRoleSelected": "You have selected the same role as the current role.",
  "userRoleChangedSuccessfully": "User role changed successfully.",

  //User roles
  "resolveComplaintNotAllowed": "You are not allowed to resolve complaint.",
  "resolveLoanRequestNotAllowed": "You are not allowed to resolve loan request.",

  //Reports admin expense
  "paidAdminExpense": "Paid Admin Expense",
  "amountCollectedInCash": "Amount Collected in Cash",
  "amountCollectedInDeposit": "Amount Collected in Deposit",
  "amountCollectedInTransfer": "Amount Collected in Transfer",
  "collectedAdminExpenseInCash": "Collected Admin Expense in Cash",

  //select collections days
  "requestedCollectionDays": "Requested Collection Days",
  "approvedCollectionDays": "Approved Collection Days",
  "st": "st",
  "nd": "nd",
  "rd": "rd",
  "th": "th",
  "mergeMinimartCollectionDaysWarning": "Information: These 2 minimarts have different collection cycles, you will need to change the collection cycle of the active loans # of the minimart $.",
  "currentCollectionCycle": "Current Collection Cycle",
  "notAllowedForThisLoanType": "It is not allowed for this type of loan.",
  "notAllowedForSelectedFrequency": "It is not allowed for selected frequency.",
  "termsUnavailable": "Terms are unavailable.",
// amortization table
  "dpd": "Days past Due",
  //Error codes
  "AMOUNT_APPROVED_CHANGE_NOT_ALLOWED": "Amount change not allowed.",
  "NO_DUE_INSTALLMENT_FOUND_FOR_CHANGIING_COLLECTION_CYCLE": "No due installment found for changing collection cycle.",
  "NEGOTIATION_UPDATE_NOT_ALLOWED": "Negotiation update not allowed.",
  "NEGOTIATION_UPDATE_BY_ANALYST": "Only the analyst can update the negotiation.",
  "NEGOTIATION_UPDATE_BY_OPERATION_USER": "Only the operation user can update the negotiation.",
  "INVALID_FREQUENCY": "Invalid frequency.",
  "INVALID_COLLECTION_DAYS": "Invalid collection days.",
  "FREQUENCY_SHOULD_NOT_BY_EMPTY": "Frequency should not be empty.",
  "MINIMART_CANNOT_BE_MARKED_NORMAL": "Minimart cannot be marked normal as it has in progress loans.",
  "INVALID_LOAN_AMOUNT_REQUEST": "Invalid loan amount is requested.",
  "INVALID_INSTALLMENT_DATE_FOR_CHANGE_COLLECTION_CYCLE": "Invalid installment date for changing collection cycle.",
  "LOAN_APPLICATION_FORM_NOT_FILLED": "The request cannot be processed as the disbursed loan's form for Loan ID $ has not been filled.",
  "disabledConfiguration": "Information: This Configuration cannot be edited here, Please refer loan status and grade configuration",
  "disableProvisionConfig": "Information: This Configuration is disabled because it has loan status D1, D2 and E, as of now we have removed this status.",
  "disabledConfig":"Information: This configuration is disabled and cannot be edited.",
  "INVALID_PROVISION_TYPE": "Provision type is invalid.",
  "TERMS_ARE_REPEATED": "Terms are repeated.",
  "DAYS_PASTDUE_ASCENDING_VERIFICATION_FAILED:": "Days past due ascending verification failed.",
  "OBSOLETE_PROVISION_TERM_CONFIGURATION": "The term configuration in provision is obsolete.",
  "OBSOLETE_CONFIGURATION":"The configuration is obsolete and no longer used.",

  //Payment collection
  "payForMinimart": "Pay for Minimart",
  "payForLoan": "Pay for Loan",
  "downpaymentCollection": "Downpayment Collection",
  "invoiceNumber": "Invoice Number",
  "todaysAmount": "Today's Amount",
  "pastDue": "Past Due",
  "clientStatus": "Client Status",
  "collectPayment": "Collect Payment",
  "payOff": "Pay Off",
  "paymentMode": "Payment Mode",
  "doYouWantNewLoan": "Do you want to apply for a new loan?",
  "DEPOSIT": "Deposit",
  "CASH": "Cash",
  "MOBILE": "Mobile",
  "WEB": "Web",
  "cashDeposited": "Collected by Deposit",
  "AdministrativeExpense": "Administrative Expense : ",
  "CollectionDetails": "Collection Details",
  "EMIAndInitialCash": "EMI and Initial cash : ",
  "CashDetails": "Cash Details : ",
  "cashInTransit": "Cash in Transit : ",
  "amountCollectedByTransfer": "Collected By Transfer: ",
  "amountCollectedBycash": "Collected by Cash : ",
  "Deposited": "Deposited",
  "transferReceipts": "Transfer Receipts",
  "paymentModes": "Payment Modes",
  "paymentSources": "Payment Sources",
  "changeReceipt": "Change File",
  "selectReceipt": "Upload File",
  "viewReceipt": "View File",
  "amountToPayOff": "Amount to pay off",
  "amountToPayShouldNotBeGreaterThanPayOff": "Amount to pay should not be greater than pay off amount.",
  "downpaymentTransferReceipt": "Downpayment Transfer Receipt",
  "paymentSource": "Payment Source",
  "paymentCollectedSuccessfully": "Payment collected successfully.",
  "downPaymentCollectedSuccessfully": "Downpayment collected successfully.",
  "INVALID_DOWNPAYMENT": "The down payment amount is less than 10% of the cost of the appliances and Equipments.",
  "MOTORCYCLE_NOT_FOUND": "Appliances and Equipments not found.",
  "LOAN_NOT_FOUND_FOR_COLLECTION": "This minimart does not have a loan for collection.",
  "COLLECTION_FAILED": "Collection failed, please try again with valid amount.",
  "DUE_LOAN_NOT_FOUND": "Due loan application not found.",
  "INSUFFICIENT_ADMINISTRATIVE_EXPENSE_AMOUNT": "Please pay the amount of $ to cover administrative expenses.",
  "FILE_NOT_FOUND": "File not found.",

  //Web payment collection reports
  "collectedBy": "Collected By",
  "webCollections": "Web Collections",
  "selectRouteDays": "Select Route Days",

  //Grade upgrade
  "UPGRADE": "UPGRADE",
  "DOWNGRADE": "DOWNGRADE",
  "changeGrade": "Change Grade",
  "MINIMART_WITH_GRADE_A_CANNOT_BE_UPGRADED": "Minimart having grade 'A' cannot be upgraded.",
  "MINIMART_WITH_GRADE_D_CANNOT_BE_DOWNGRADED": "Minimart having grade 'D' cannot be downgraded.",
  "gradeUpdateConfirmation": "Do you want to change the minimart’s grade?",
  "gradeResetConfirmation": "Do you want grade reset tonight?",
  "systemWillUpdateNote": "Note : System will update minimart grade every night.",
  "systemWillNotUpdateNote": "Note : System will not update minimart grade every night.",
  "gradeResetActive": "Grade reset active",
  "gradeResetInactive": "Grade reset inactive",
  // Deactivate Account
  "OWNER_OF_MINIMART_IS_DEACTIVATED": "This minimart has deactivated their account and registered again.",
  "DEACTIVATED": "Desactivado",
  "BL_DEACT": "Blacklisted and Deactivated",

  //Loan already closed 
  "isConciliatedLoanClosed": "The loan $ conciliated with this loan is closed.",

  //warning for Invalid date
  "warningForInvalidDate": "Please select valid date.",

  // Merged Minimarts
  "mergedMinimarts": "Merged Minimarts",
  "mergedHistory": "Merged History",
  "disbursedLoans": "Disbursed Loans",
  "rejectedLoans": "Rejected Loans",
  "inprocessLoans": "Inprocess Loans",
  "mergingApprovedOn": "Merging Approved on",
  "mergingApprovedBy": "Merging Approved by",
  "mergedMinimartHistory": "Merged minimart history",

  // Conciliation
  "NEW_LOAN_AMOUNT_NOT_SUFFICIENT": "New loan amount is not sufficient to conciliate the selected loan.",
  "ACTIVE_CASHTOPUP_LOAN_FOUND": "Cash topup loan is already active, which should be conciliated.",
  "MULTIPLE_LOANS_CANT_BE_CONCILIATED": "Only 2 loans can be conciliated.",
  "AMOUNT_IS_NOT_VALID": "Entered amount is not valid.",
  "INVALID_DAYS":"Invalid credo call days.",
  "createdByConciliation": "Created by Conciliation",
  "discountInterestAmount": "Discount Interest Amount",

  // CI Loans
  "LOAN_FORM_NOT_FILLED": "As minimart * has disbursed loan whose data is not filled yet, so you cannot approve loan # with conciliation. ",

  "RestructuringStatus": "Restructuring Status",
  "Restructured": "Involved in Restructuring",
  "NonRestructured": "Non Restructured",
  "CreatedAndClosedRestructuredLoan": "Created and Closed by Restructuring",
  "NoSellerAssignedWarning": "Seller is not assigned for minimart with CI code *.",
  "noteAddedSuccessfully": "Note Added Successfully.",
  "invalidNote": "Only letters, numbers, spaces, and special characters allowed.",
  "noteForAmortizations": "Notes for the Amortization Index : ",
  "notesForClient": "Notes for the client :",
  "addNewNote": "Add New Note",
  "existingNotes": "Existing Notes",
  "viewOrAddNote": "View or Add Note",
};
export default en;