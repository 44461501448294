import React, { Component } from "react";

import { Strings } from "../../../resources/i18n/i18n";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "../InputModal/InputModal.scss";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Table } from "react-bootstrap";
import Utility from "../../../utils/Utility";
import { Paper, styled, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import { colors } from "../../../resources/styles/themes";
import { CONFIGURATION_DATE_TIME_FORMAT } from "../../../constants/appConstants";
import moment from "moment";

class InformativeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loanNote: "",
            showErrorMessage: false,
            note: ""
        }
    }
    componentDidMount() {
        this.props.amortizationIndex && this.props.viewNotes()
    }
    validateNote = () =>{
        if((!Utility.isNoteValid(this.state.loanNote) || this.state.loanNote.trim().length===0)){
            this.setState({
                showErrorMessage: Strings("invalidNote")
            }) 
        }
        else{
            this.props.handleOkClick(this.state.loanNote)
        }
    }
    renderModalButtons = () => {
        return (
            <Col md={12}>
                <Button
                    className='buttonBackground primaryModalButton buttonMinWidth'
                    onClick={this.props.modalHeader === Strings("clientStatusChange").concat(this.props.clientId) || this.props.amortizationIndex
                        ?
                        () => this.validateNote()
                        :
                        () => this.props.handleOkClick()}
                >
                    {Strings("Set")}
                </Button>

                <Button
                    className={
                        " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
                    }
                    onClick={this.props.closeModal}
                >
                    {Strings("Cancel")}
                </Button>
            </Col>
        );
    };
    getRdtVal = (value) => {
        return value !== null
            ? Utility.getReportFormattedDateString(value)
            : Strings("NotAvailable");
    };
    renderModalCancelButton =   () => {
        return (
            <Col>
                <Button
                    className={
                        " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
                    }
                    onClick={this.props.closeModal}
                >
                    {Strings("Cancel")}
                </Button>
            </Col>
        )
    }
    renderConciliatedLoanDetails = (loanData) => {
        const totalUpc = loanData?.reduce((sum, item) => sum + (item.upc || 0), 0);
        const totalUpi = loanData?.reduce((sum, item) => sum + (item.upi || 0), 0);
        const totalUplf = loanData?.reduce((sum, item) => sum + (item.uplf || 0), 0);
        const showSummary = loanData?.length > 1;
        return (
            <div className="conciliationDetails pt-2 ps-3 pe-3">
                {loanData && <Row className="pt-2 pb-3">
                    <Col md={6}>
                        <span>{Strings("conciliationBy")}</span> : {this.props.conciliatedLoanDetails.cby}
                    </Col>
                    <Col md={6}>
                        <span>{Strings("conciliationDate")}</span> : {this.getRdtVal(this.props.conciliatedLoanDetails.cdt)}
                    </Col>
                </Row>}
                {loanData ?
                    (<Table className="conciliationDetailsTable" striped bordered hover responsive style={{ lineHeight: '2em' }}>
                        <thead>
                            <tr>
                                <th>{Strings("LoanId")}</th>
                                {loanData?.map((item, index) => (
                                    <th key={index}> {item.lid}</th>
                                ))}
                                {showSummary && <th>{Strings("Summary")}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="label-cell">{Strings("unpaidCapital")}</td>
                                {loanData?.map((item, index) => (
                                    <td key={index}>
                                        {item.upc ? Utility.getCurrencyRepresentationOfAmount(item.upc) : 0}
                                    </td>
                                ))}
                                {showSummary && <td>
                                    {Utility.getCurrencyRepresentationOfAmount(totalUpc)}
                                </td>}
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("unPaidInterest")}</td>
                                {loanData?.map((item, index) => (
                                    <td key={index}>
                                        {item.upi ? Utility.getCurrencyRepresentationOfAmount(item.upi) : 0}
                                    </td>
                                ))}
                                {showSummary && <td>
                                    {Utility.getCurrencyRepresentationOfAmount(totalUpi)}
                                </td>}
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("unpaidLateFees")}</td>
                                {loanData?.map((item, index) => (
                                    <td key={index}>
                                        {item.uplf ? Utility.getCurrencyRepresentationOfAmount(item.uplf) : 0}
                                    </td>
                                ))}
                                {showSummary && <td>
                                    {Utility.getCurrencyRepresentationOfAmount(totalUplf)}
                                </td>}
                            </tr>
                        </tbody>
                    </Table>) :
                    <div className='noRecordsFound'>
                        {Strings("NoRecordsFound")}
                    </div>}
            </div>
        );
    }
    renderRestructuredLoanDetails = (restructureData) => {
        return (
            <div className={`${restructureData.rlid ? "restructureDetails" : " "} pt-2 ps-3 pe-3`}>
                {restructureData &&
                    <Row className="pt-2 pb-3">
                        <Col md={12} className='px-0'>
                            <span className='titleText '>
                                {Strings("LoanRescheduledBy")}&nbsp;:&nbsp;
                            </span>
                            <span className='detailText address ps-1'>
                                {restructureData.rby !== null
                                    ? restructureData.rby
                                    : Strings("NotAvailable")}
                            </span>
                        </Col>
                        <Col md={12} className='px-0'>
                            <span className='titleText '>
                                {Strings("RescheduledLoanDate")}&nbsp;:&nbsp;
                            </span>
                            <span className='detailText address ps-1'>
                                {this.getRdtVal(restructureData.rdt)}
                            </span>
                        </Col>
                        <Col md={12} className='px-0'>
                            <span className='titleText'>
                                {Strings("repaymentPlanNote")}&nbsp;:&nbsp;
                            </span>
                            <span className='detailText address '>
                                {restructureData.rn !== null
                                    ? restructureData.rn
                                    : Strings("NotAvailable")}
                            </span>
                        </Col>
                    </Row>}
                {restructureData ? (
                    <Table className="restructureDetailsTable" striped bordered hover responsive style={{ lineHeight: '2em' }}>
                        {restructureData.rlid &&
                            <thead>
                                <tr>
                                    <th>{Strings("LoanId")}</th>
                                    <th>{restructureData.rlid}</th>
                                </tr>
                            </thead>}
                        <tbody>
                            <tr>
                                <td className="label-cell">{Strings("uc") + " ($)"}</td>
                                <td>
                                    {restructureData.ruc ? Utility.getCurrencyRepresentationOfAmount(restructureData.ruc) : 0}
                                </td>
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("ui") + " ($)"}</td>
                                <td>
                                    {restructureData.rui ? Utility.getCurrencyRepresentationOfAmount(restructureData.rui) : 0}
                                </td>
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("ulf") + " ($)"}</td>
                                <td>
                                    {restructureData.rulf ? Utility.getCurrencyRepresentationOfAmount(restructureData.rulf) : 0}
                                </td>
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("TotalUnPaidAmount") + " ($)"}</td>
                                <td>
                                    {restructureData.rua ? Utility.getCurrencyRepresentationOfAmount(restructureData.rua) : 0}
                                </td>
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("interestRate")}</td>
                                <td>
                                    {restructureData.rt ? Utility.getCurrencyRepresentationOfAmount(restructureData.rt) : 0}
                                </td>
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("approvedTerm")}</td>
                                <td>
                                    {restructureData.noi ? restructureData.noi : 0}
                                </td>
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("emi")}</td>
                                <td>
                                    {restructureData.emi ? Utility.getCurrencyRepresentationOfAmount(restructureData.emi) : 0}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                ) : (
                    <div className='noRecordsFound'>
                        {Strings("NoRecordsFound")}
                    </div>
                )}
            </div>
        );
    }
    handleLoanNote = (event) => {
        this.setState({
            loanNote: event.target.value,
            showErrorMessage: false
        });
    };
    showLoanInformation = () => {
        let loanInfolength;
        return (
            <div >
            <Row className="p-0 loanInformation" style={{ overflow: "auto", maxHeight: "13em" }}>
                {this.props.minimartInfo.map((minimart) => {
                    loanInfolength = minimart.loanInfo.length
                    return (
                        <React.Fragment key={minimart.minimartId}>
                            <Col md={12} key={minimart.minimartId} className="pe-0 ps-0 pt-2 ">
                                <span className='titleText'>
                                    {Strings("MatricesID")} &nbsp;:&nbsp;
                                </span>
                                <span className='detailText'>
                                    {minimart.minimartId}
                                </span>
                            </Col>
                            <Col md={12} className="pb-3 pe-0 ps-0">
                                <span className='titleText'>
                                    {Strings("applicationId(S)")} &nbsp;:&nbsp;
                                </span>
                                {minimart.loanInfo.map((loan, index) => (
                                    <span key={loan.applicationId} className='detailText'>
                                        {loan.applicationId + `  (${Strings(loan.status)})`}
                                        {index < loanInfolength - 1 && ', '}
                                    </span>
                                ))}
                            </Col>

                        </React.Fragment>
                    )
                })}
            </Row>
                <hr className="opacity-100 mb-1" />
            </div>)
    }
    showNotes = () => {
        return (
            <Row className='pt-2 pe-2 ps-2 pb-2'>
                <div className='loanNoteInputField '>
                    <textarea
                        placeholder={Strings("NoteForBlackListingCedula")}
                        className='topMargin form-control noteTextArea'
                        onChange={this.handleLoanNote}
                        value={this.state.loanNote}
                        cols={100}
                        rows={3}
                        maxLength={500}
                    />
                </div>
            </Row>
        );
    }
    renderTextArea = () => {
        return (
            <Row className='userInput pt-3'>
                <Col md={12}>
                    <span className="" style={{ color: colors.textColor20 }}>{Strings("addNewNote")}</span>
                </Col>
                <Col md={12} className="pt-3">
                    <div className=''>
                        <textarea
                            style={{ height: "6em" }}
                            className={"w-100"}
                            id='Notes'
                            type='text'
                            placeholder={Strings("Notes")}
                            maxLength={this.props.maxLength}
                            cols={45}
                            rows={5}
                            onChange={this.handleLoanNote}
                            value={this.state.loanNote}
                            disable={this.props.disabled || undefined}
                        />
                    </div>
                </Col>
                <Col md={12} className="text-center pt-4">
                    {!this.props.isAddNoteAccess && this.props?.rows?.length > 0 && <Button
                        className={
                            " buttonBackground primaryModalButton buttonMinWidth"
                        }
                        onClick={() => { this.props.handleOkClick(this.state.loanNote) }}
                    >
                        {Strings("Set")}
                    </Button>}
                </Col>
                
            </Row>
        );
    }
    addNote = () => {
        return (
            <>
                {!this.props.isAddNoteAccess && this.renderTextArea()}
                {this.props.rows.length > 0 && !this.props.isAddNoteAccess &&
                    <Row>
                        <Col md={12} className="pt-3">
                            <span style={{ color: colors.textColor20 }}>{Strings("existingNotes")}</span>
                        </Col>
                    </Row>
                }
                {this.renderCustomDataGrid(
                    this.props.columns,
                    this.props.rows,
                )}
            </>
        );
    }

    renderTableData = (row, key) => {
        switch (key) {
            case 'ca':
                return moment(row[key]).format(CONFIGURATION_DATE_TIME_FORMAT);
            case 'note':
                const formattedNote = row[key]
                    ? row[key].split('\n').map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                            {index < row[key].split('\n').length - 1 && <br />}
                        </React.Fragment>
                    ))
                    : Strings("NA");
                return <span>{formattedNote}</span>;
                
            default:
                return row[key] ? Strings(row[key]) : Strings("NA");
        }
    }
    

    renderCustomDataGrid = (columns, rows) => {
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: "rgb(196, 197, 195)",
                color: theme.palette.common.black,
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        }));


        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.action.hover,
            },
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }))

        return (
            <div className='d-flex justify-content-center mx-2 pt-2 ' style={{ maxHeight: "15em" }}>
                {rows.length > 0 ?
                    <TableContainer
                        component={Paper}
                        className=''
                    >
                        <Table aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    {columns.length > 0 &&
                                        columns.map((column) => (
                                            <StyledTableCell
                                                key={column.key}
                                                className={"header px-2"}
                                                title={column.name}
                                            >
                                                <div>{column.name}</div>
                                            </StyledTableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => (
                                    <StyledTableRow
                                        key={rowIndex}
                                        hover
                                        style={{ cursor: "pointer" }}

                                    >
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.key}
                                                className={"px-2"}
                                                component='th'
                                                scope='row'
                                                title={row[column.key]}
                                                style={{ maxWidth: column.maxWidth, minWidth: column.minWidth }}
                                            >
                                                <div
                                                    className={"text-start text-break "}
                                                    style={{ color: colors.textColor20, fontSize: 15, fontFamily: "Roboto-Regular" }}
                                                >
                                                    {this.renderTableData(row, column.key)}
                                                </div>
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> : this.props.isAddNoteAccess ?
                        (<div className='noRecordsFound noBranchRecords'>
                            {Strings("NoRecordsFound")}
                        </div>) : null}
            </div>
        );
    };
    render() {
        let showLoanInfo = this.props?.minimartInfo?.length > 0
        let isClientInfoScreen = this.props.modalHeader === Strings("clientStatusChange").concat(this.props.clientId)
        let amortizationNotes = this.props.modalHeader === Strings("noteForAmortizations").concat(this.props?.amortizationIndex)
        let conciliatedLoanDetailsInfoScreen = this.props.modalHeader === Strings("conciliatedLoanDetailsFor") ? true : false
        let restructureLoandetailsInfoScreen = (this.props.modalHeader === Strings("restructureDetailsFor")
            || this.props.modalHeader === Strings("CreatedLoanDetails") || this.props.modalHeader === Strings("ClosedLoanDetails")) ? true : false
        let errorMessageClass =""
        if(isClientInfoScreen){
            errorMessageClass= "errorMessage pb-2 ps-2 pt-2 ps-4"
        }
        else{
            errorMessageClass= "errorMessage pb-2 ps-2 pt-2"
        }
        let showOnlyCancelButton = conciliatedLoanDetailsInfoScreen || restructureLoandetailsInfoScreen || this.props.isAddNoteAccess ||(amortizationNotes && this.props?.rows?.length>0);
        return (
            <div>
                {/* general modal */}
                <Modal
                    // Change visibility of modal based on props.
                    open={this.props.openModal}
                    onClose={this.props.closeModal}
                    closeOnEsc={false}
                    closeOnOverlayClick={false}
                    classNames={{
                        modal: (conciliatedLoanDetailsInfoScreen || restructureLoandetailsInfoScreen || amortizationNotes || this.props.amortizationIndex) ? "conciliationDetailsModal" : "customModal"
                    }}
                    showCloseIcon={false}
                    center
                >
                    <Row className='inputModal '>
                        <Col md={12} className='headerModal'>
                            {this.props.modalHeader}
                        </Col>
                        <Col md={12} className='modalContent pt-2'>
                            {(isClientInfoScreen || amortizationNotes) && this.state.showErrorMessage && <Row className={errorMessageClass}>
                            {Strings(this.state.showErrorMessage)}</Row>}
                            {showLoanInfo &&
                                <Row className='warningText pb-3 pt-3'>
                                    {Strings("activeLoansList")}
                                </Row>}
                            {showLoanInfo && this.showLoanInformation()}
                            {this.props.data?.split(/\n/).map((line) => {
                                return <div key={line}>{line}</div>
                            })}
                            {isClientInfoScreen && <Row className="message blackListQuestion pb-2 mt-0 pt-2 ps-4">{this.props.commonMinimartInfo}</Row>}
                            {isClientInfoScreen ? this.showNotes() : null}
                            {conciliatedLoanDetailsInfoScreen && this.renderConciliatedLoanDetails(this.props.conciliatedLoanDetails?.cldd)}
                            {restructureLoandetailsInfoScreen && this.renderRestructuredLoanDetails(this.props.restructureDetails)}
                            {(amortizationNotes || this.props.amortizationIndex) && this.addNote()}
                            {showOnlyCancelButton
                                ? <Row className={"modalCancelButton"}>
                                    {this.renderModalCancelButton()}
                                </Row>
                                : <Row className={`modalButton ${isClientInfoScreen && "blackListModalbutton"}`}>
                                    {this.renderModalButtons()}
                                </Row>}

                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}


export default InformativeModal;
