import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import { Table, TableContainer, TableHead, TableRow, TableBody, tableCellClasses, styled, TableCell, Paper } from "@mui/material";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import { CONFIGURATION_DATE_TIME_FORMAT, DATE_TYPE, DEFAULT_DATE_FORMAT, DEFAULT_DURATION, DEFAULT_VALUES, DOWNLOAD_FILE_TYPE, SEARCH_FILTER_FIELDS, SEARCH_FILTER_TYPE } from "../../constants/appConstants";
import moment from "moment";
import { DEFAULT_USER_SELECTION } from "../UserList/UserListConstants";
import { DEFAULT_PAGINATION_ACTIVE_PAGE, DEFAULT_PAGINATION_RECORDS_COUNT } from "../CustomUIComponents/Pagination/PaginationConstants";
import { NOTES_COLUMNS, NOTES_REPORTS_DOWNLAOD } from "./Constants";
import { colors } from "../../resources/styles/themes";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";
import CSVdownload from "../../resources/images/ic_download_csv.svg";

class NoteDetails extends React.Component {
    constructor(props) {
        super(props);
        this._columns = NOTES_COLUMNS;
        this.state = {
            NoteFilters: {
                fromDate: props?.searchFilter?.fromDate,
                toDate: props?.searchFilter?.toDate,
                filterRole: props?.searchFilter?.rl,
                noteType: props?.searchFilter?.ty,

            },
            refId: props.refId,
            activePage: props.searchFilter.offset,
            recordsPerPage: props.searchFilter.limit,
            userRoles: props.userRoles,
            showLoader: false,
            rows: props.rows ? props.rows : [],
        };
    }
    componentDidMount() {
        this.handleOnClickSearchFilter(false);
    }

    handleOnClickSearchFilter = (isDefaultOffset = false) => {
        isDefaultOffset &&
            this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
        this.props.handlNoteSearchFilter({
            notesSearchCriteria: {
                refId: this.props.refId,
                noteTypeContext: this.props.noteTypeContext,
                noteType: this.state.NoteFilters.noteType !== "noteType" && this.state.NoteFilters.noteType !== "" ? this.state.NoteFilters.noteType : undefined,
                userRole: this.state.NoteFilters.filterRole !== "" ? this.state.NoteFilters.filterRole : undefined,
                fromDate: this.state.NoteFilters.fromDate,
                toDate: this.state.NoteFilters.toDate
            },
            limit: this.state.recordsPerPage,
            offset: isDefaultOffset
                ? DEFAULT_PAGINATION_ACTIVE_PAGE
                : this.state.activePage,
        })


    }
    handleOnClickResetFilter = () => {
        this.setState(
            {
                NoteFilters: {
                    noteType: "",
                    filterRole: "",
                    fromDate: DEFAULT_DURATION.fromDate,
                    toDate: DEFAULT_DURATION.toDate,

                },
                recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
                activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
            },
            () => this.handleOnClickSearchFilter(true)
        );

    }

    downloadFile = (type) => {
        const searchFilterObject = {
            notesSearchCriteria: {
                refId: this.props.refId,
                noteTypeContext: this.props.noteTypeContext,
                fromDate: this.state.NoteFilters.fromDate,
                toDate: this.state.NoteFilters.toDate
            },
            limit: this.state.recordsPerPage,
            offset: this.state.activePage,
        };

        this.props.downloadNotes(
            NOTES_REPORTS_DOWNLAOD.downloadNotesUrl + type,
            searchFilterObject,
            type
        )
    };

    handleSelectionChange = (event) => {
        let value =
            !DEFAULT_VALUES.includes(event.target.value)
                ? event.target.value
                : "";
        let name = event.target.name;
        this.setState(prevState => ({
            NoteFilters: {
                ...prevState.NoteFilters,
                [name]: value
            }
        }));
    }

    renderSearchFilter = () => {
        let userRoles = this.props.userRoles && this.props.userRoles.map((singleUser) => {
            return {
                label: Strings(singleUser.role),
                value: singleUser.role,
            };
        })
        userRoles.unshift({
            label: Strings(DEFAULT_USER_SELECTION),
            value: DEFAULT_USER_SELECTION,
        });
        let noteTypesDropdownValues = this.props.noteTypes && this.props.noteTypes.map((noteType) => {
            return {
                label: Strings(noteType),
                value: noteType
            }
        })

        let NoteFilters = [
            {
                type: SEARCH_FILTER_TYPE.DATE,
                fromDate: this.state.NoteFilters.fromDate,
                handleCalenderChange: this.handleDateChange,
                toDate: this.state.NoteFilters.toDate,
                fieldname: SEARCH_FILTER_FIELDS.DATE,
                column: 4,
                paddingTop: true,
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleSelectionChange,
                Value: this.state.NoteFilters.noteType,
                fieldname: SEARCH_FILTER_FIELDS.NOTE_TYPE,
                filterDropdownList: noteTypesDropdownValues,
                name: "noteType",
                paddingTop: true,
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleSelectionChange,
                Value: this.state.NoteFilters.filterRole,
                fieldname: SEARCH_FILTER_FIELDS.ROLE,
                filterDropdownList: userRoles,
                name: "filterRole",
                paddingTop: true,
            },
        ];
        return (
            <SearchFilter
                filters={NoteFilters}
                handleOnClickSearchFilter={
                    this.handleOnClickSearchFilter
                }
                handleOnClickResetFilter={this.handleOnClickResetFilter}
                paddingRight={0}
                paddingTop={2}
                removeLeftPadding={true}
            />
        );
    };
    renderCustomDataGrid = (columns, rows, callBack) => {
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.divider,
                color: theme.palette.common.black,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14
            }
        }))

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.action.hover,
            },
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }))

        return (
            <div className='d-flex justify-content-center mx-3 mb-3'>
                <TableContainer
                    component={Paper}
                    className='d-flex justify-content-center'
                >
                    <Table aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                {columns.length > 0 &&
                                    columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            className={"header px-2"}
                                            title={column.name}
                                        >
                                            <div>{column.name}</div>
                                        </StyledTableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rowIndex) => (
                                <StyledTableRow
                                    key={rowIndex}
                                    hover
                                    style={{ cursor: "pointer" }}
                                    onClick={() => callBack(row)}
                                >
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            className={"px-2"}
                                            component='th'
                                            scope='row'
                                            title={row[column.key]}
                                            style={{ maxWidth: column.maxWidth, minWidth: column.minWidth }}
                                        >
                                            <div
                                                className={"text-start text-break"}
                                                style={{ color: colors.textColor20, fontSize: 17, fontFamily: "Roboto-Regular" }}
                                            >
                                                {this.renderTableData(row, column.key)}
                                            </div>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };
    handleDateChange = (key) => (dateMoment) => {
        let DateValue = "";
        switch (key) {
            case DATE_TYPE.FROM_DATE:
                if (dateMoment.isAfter(this.state.NoteFilters.toDate)) {
                    DateValue = this.state.NoteFilters.fromDate;
                } else {
                    dateMoment.set({ hour: 0, minute: 0, second: 0 });
                    DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
                }
                break;
            case DATE_TYPE.TO_DATE:
                if (dateMoment.isBefore(this.state.NoteFilters.fromDate)) {
                    DateValue =
                        moment(dateMoment).diff(
                            this.state.NoteFilters.fromDate,
                            "days"
                        ) === 0
                            ? this.state.NoteFilters.fromDate
                            : this.state.NoteFilters.toDate;
                } else {
                    DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
                }
                break;
            default:
        }
        this.setState({
            NoteFilters: { ...this.state.NoteFilters, [key]: DateValue },
        });
    };
    onPageChange = ({ page, pageLength }) => {
        this.setState(
            {
                activePage: page,
                recordsPerPage: pageLength,
            },
            () => this.handleOnClickSearchFilter(false)
        );
    };

    renderTableData = (row, key) => {
        switch (key) {
            case 'ca':
                return moment(row[key]).format(CONFIGURATION_DATE_TIME_FORMAT);
            default:
                return row[key] ? Strings(row[key]) : Strings("NA");
        }
    }
    render() {
        return (
            <>
                <Row className='heading'>
                    {Strings("NoteDetails")}
                </Row>
                <div className='minimartList container-fluid ListContainer'>
                    <div className="mx-3">
                        {this.renderSearchFilter()}
                    </div>
                    <Row className='pt-2 alignItemCenter'>
                        <Col
                            md={12}
                            className='alignDownloadButtons'
                        >
                            {" "}
                            <div className="pull-right">
                                <Button
                                    className='downloadButtons me-3'
                                    onClick={() => {
                                        this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
                                    }}>
                                    <img src={PDFdownload} alt='' />
                                </Button>
                                <Button
                                    className='downloadButtons me-1'
                                    onClick={() => {
                                        this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
                                    }}>
                                    <img src={CSVdownload} alt='' />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className='userListTable mt-4'>
                        {this.props.rows.length > 0 ?
                            (
                                <div>
                                    {
                                        this.renderCustomDataGrid(
                                            this._columns,
                                            this.props.rows,
                                        )
                                    }
                                    < Col md={12} className='pt-3'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            recordsPerPage={this.state.recordsPerPage}
                                            numberOfPages={this.props.numberOfPages}
                                            onPageChange={this.onPageChange}
                                            dropup={true}
                                        />

                                    </Col>
                                </div>
                            ) : (
                                <div className='noRecordsFound'>
                                    {Strings("NoRecordsFound")}
                                </div>
                            )
                        }
                    </div>
                </div >
            </>
        );
    }
}
export default NoteDetails;